<ng-container *ngIf="!(isClosing$ | async)">
  <h1 mat-dialog-title>{{data.player.username}},</h1>
  <div mat-dialog-content>
    <p>
      {{matchResult}}<br />
      Scorul meciului este {{data.score.selfWonGames}} - {{data.score.opponentWonGames}}!<br />
      Vrei sa incepi un alt meci?
    </p>
  </div>
  <div mat-dialog-actions>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Da</button>
    <button mat-button [mat-dialog-close]="false">Nu</button>
  </div>
</ng-container>