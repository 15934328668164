import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subscription } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { NotMatchControls } from 'src/app/utils/form.validators';
import { PrivateGameRequest } from 'src/core/models/game/private-game-request.model';
import { LoaderService } from 'src/core/services/loader.service';
import { MatchService } from 'src/core/services/match.service';
import { UserService } from 'src/core/services/user.service';

@Component({
  selector: 'app-game-players',
  templateUrl: './game-players.component.html',
  styleUrls: ['./game-players.component.scss']
})
export class GamePlayersComponent implements OnInit, OnDestroy {

  public isClosing$: Observable<boolean>;
  public you: string;

  protected formGroup: FormGroup;

  private subscriptions: Subscription;

  constructor(
    private userService: UserService,
    private matchService: MatchService,
    private toastrService: ToastrService,
    private loaderService: LoaderService,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<GamePlayersComponent>,
  ) {
    this.isClosing$ = this.dialogRef.beforeClosed().pipe(mapTo(true));
    this.you = this.userService.currentUser?.username;
  }

  ngOnInit(): void {
    this.subscriptions = new Subscription();
    this.formGroup = this.formBuilder.group({
      player2Username: ['', Validators.required],
      player3Username: ['', Validators.required],
      player4Username: ['', Validators.required],
    }, {
      validator: NotMatchControls('player2Username','player3Username', 'player4Username')
    });
  }

  sendInvite() {
    this.loaderService.loaderState = true;
    this.subscriptions.add(this.matchService.createPrivateGame(new PrivateGameRequest(
      this.formGroup.controls.player2Username.value,
      this.formGroup.controls.player3Username.value,
      this.formGroup.controls.player4Username.value
    )).subscribe(
      (gameId) => {
        this.loaderService.loaderState = false;
        this.dialogRef.close(true);
        this.toastrService.success("Invitatia a fost trimisa cu succes!");
        this.toastrService.info(`Jocul privat a fost creat <b><a href="/joc?gameId=${gameId}">aici</a></b>!`, "Intra in joc!", { timeOut: 60000, enableHtml: true, extendedTimeOut: 60000 });
      },
      () => {
        this.loaderService.loaderState = false;
      }
    ));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
