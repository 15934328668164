<div class="bg container-fluid">
  <div class="d-flex h-100 flex-column">
    <main class="row flex-grow-1 justify-content-center align-items-center">
      <div class="col-12 d-flex flex-column justify-content-center align-items-center">
        <h1 class="title" routerLink="/home">filcău</h1>
        <h1 class="not-found-title">404</h1>
        <h1 class="not-found-title">PAGINA NU ESTE DISPONIBILĂ</h1>
      </div>
    </main>
    <footer></footer>
  </div>
</div>