import { NgModule } from '@angular/core';
import { LoginComponent } from "./pages/login/login.component";
import { RegisterComponent } from "./pages/register/register.component";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component";
import { ConfirmAccountComponent } from './pages/confirm-account/confirm-account.component';
import { ConfirmPasswordComponent } from './pages/confirm-password/confirm-password.component';
import { AccountConfirmingComponent } from './pages/account-confirming/account-confirming.component';
import { ConfirmationGuard } from 'src/core/guards/confirmation.guard';
import { RouterModule, Routes } from '@angular/router';
import { ResendConfirmationComponent } from './pages/resend-confirmation/resend-confirmation.component';
import { NotAuthGuard } from 'src/core/guards/not-auth.guard';

const routes: Routes = [
  { path: "login", component: LoginComponent, canActivate: [NotAuthGuard] },
  { path: "register", component: RegisterComponent, canActivate: [NotAuthGuard] },
  { path: "forgot-password", component: ForgotPasswordComponent, canActivate: [NotAuthGuard] },
  { path: "reset-password", component: ResetPasswordComponent, canActivate: [NotAuthGuard] },
  { path: "confirm-account", component: ConfirmAccountComponent, canActivate: [ConfirmationGuard], data: { prevUrl: "register" } },
  { path: "confirm-password", component: ConfirmPasswordComponent, canActivate: [ConfirmationGuard], data: { prevUrl: "forgot-password" } },
  { path: "account-confirming", component: AccountConfirmingComponent },
  { path: "resend-confirmation", component: ResendConfirmationComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule { }
