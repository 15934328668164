import { PAGES, PAGE_BACKGROUNDS } from "src/app/common/constants";
import { LogService } from "../services/log.service";

export abstract class FilcauLayout {

    public componentBackgroundColor: string;
    public navBackgroundColor: string;
    public activePage: PAGES;

    public constructor(protected readonly logService: LogService) { }

    public onRouterOutletActivate(event: any) {
        this.activePage = event.pageName ?? undefined;
        if (!this.activePage) {
            this.logService.error('Failed to obtain the active page', event);
            return;
        }
        const component = PAGE_BACKGROUNDS.find(page => page.componentName === this.activePage);
        this.componentBackgroundColor = component.componentBackgroundColor;
        this.navBackgroundColor = component.navbarBackgroundColor;
    }

    public getRouterOutletState(outlet) {
        return outlet.isActivated ? outlet.activatedRoute : '';
    }
}