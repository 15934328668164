import { Component } from '@angular/core';
import { Card } from '../../../core/models/game/card.model';
import { GameService } from '../../../core/services/game.service';
import { TromfCombination } from '../../../core/models/game/game.model';
import { popupDialogAnimation, SeatComponent } from '../seat/seat.component';
import { Position } from 'src/core/models/generic.model';
import { AnimationEvent } from "@angular/animations";

@Component({
  selector: 'app-player-seat',
  templateUrl: './player-seat.component.html',
  styleUrls: ['./player-seat.component.scss'],
  animations: [popupDialogAnimation()]
})
export class PlayerSeatComponent extends SeatComponent {
  public isCurrentPlayer: boolean = true;
  public buttonText: string = '';
  public visibilityState: string = 'hidden';
  private points: Card[];
  private tromfCombination: TromfCombination = TromfCombination.Pas;

  constructor(public gameService: GameService) {
    super(gameService);
    this.position = Position.South;
  }

  ngOnInit() {
    super.ngOnInit();
    this.subscriptions.add(this.gameService.legareaTromfuluiPossible.subscribe(data => {
      this.handleLegareaTromfuluiPossible(data.tromfPossible, data.tromfCombinations);
    }));
  }

  public leagaTromf(): void {
    this.visibilityState = 'hidden';
    this.gameService.legareaTromfuluiChosen();
  }

  private handleLegareaTromfuluiPossible(tromfPossible: boolean, tromfCombinations: TromfCombination): void {
    this.tromfCombination = tromfCombinations;
    this.visibilityState = tromfPossible ? 'visible' : 'hidden';
    if (tromfPossible) {
      this.buttonText = this.getButtonText();
    }
  }

  private getButtonText(): string {
    if (this.tromfCombination & TromfCombination.Pas) {
      return 'Pas';
    }
    if (this.tromfCombination & TromfCombination.Doi) {
      return 'Doi';
    }
    if (this.tromfCombination & TromfCombination.Inchis) {
      return 'Inchis';
    }
    if (this.tromfCombination & TromfCombination.Buna) {
      return 'E buna';
    }
    return 'Leaga tromf';
  }

  public pointsWon(cardsWon: Card[]): void {
    this.points = this.points.concat(cardsWon);
  }

  public getScore(): number {
    let score: number = 0;
    this.points.forEach(point => {
      score += point.points;
    });
    return score;
  }

  public dialogAnimationStart(event: AnimationEvent) {
    super.dialogAnimationStart(event);
  }

  public dialogAnimationDone(event: AnimationEvent) {
    super.dialogAnimationDone(event);
  }

  public get waitingDisplay(): boolean {
    return super.waitingDisplay;
  }

  ngOnDestroy(): void {
    super.ngOnDestroy();
  }
}
