import { Injectable } from '@angular/core';
import { Card, CardColor, CardName, CardSet } from '../models/game/card.model';
import { TromfCombination, TromfCombinationsUtils } from '../models/game/game.model';
import { AppPlayer } from '../models/app-player.model';
import { Position } from '../../core/models/generic.model';
import { GameScore, ScoreType } from '../models/game/score.model';
import { LogServiceHelper } from './log.service';
import { Team } from '../models/team.model';

@Injectable({
  providedIn: 'root'
})
export class GameUtilsService {

  private constructor() { }

  public static legareaTromfuluiNameDictionary: Map<TromfCombination, string> = new Map([
    [TromfCombination.Pas, 'Pas'],
    [TromfCombination.TriArseu, 'Tri\' arseu\''],
    [TromfCombination.TriCalu, 'Tri\' calu\''],
    [TromfCombination.TriCraita, 'Tri\' craita'],
    [TromfCombination.TriTuzu, 'Tri\' tuzu\''],
    [TromfCombination.PatruArseu, 'Patru\' arseu\''],
    [TromfCombination.PatruCalu, 'Patru\' calu\''],
    [TromfCombination.PatruTuzu, 'Patru\' tuzu\''],
    [TromfCombination.PatruCraita, 'Patru\' craita\''],
    [TromfCombination.Inchis, 'Inchis'],
    [TromfCombination.Doi, 'Doi'],
    [TromfCombination.Buna, 'E buna']
  ]);

  public static scoreTypeNameDictionary: Map<ScoreType, string> = new Map([
    [ScoreType.PaRanduSatului, 'Pa randu\' satului'],
    [ScoreType.Huda, 'Huda'],
    [ScoreType.HudaLegata, 'Huda legata'],
    [ScoreType.Sluga, 'Sluga'],
  ]);

  public static getSmallestCard(cards: Card[]): Card {
    if (!cards || cards.length === 0) {
      return undefined;
    }
    const nonHiddenCards: Card[] = cards.filter(card => card.name !== CardName.Hidden);
    if (nonHiddenCards.length === 0) {
      return undefined;
    }
    let minCard: Card = nonHiddenCards[0];
    for (let i = 1; i < nonHiddenCards.length; i++) {
      if (nonHiddenCards[i].value < minCard.value) {
        minCard = nonHiddenCards[i];
      }
    }
    return minCard;
  }

  public static getSmallestCardByAbsoluteValue(cards: Card[]): Card {
    if (!cards || cards.length === 0) {
      return undefined;
    }
    const nonHiddenCards: Card[] = cards.filter(card => card.name !== CardName.Hidden);
    if (nonHiddenCards.length === 0) {
      return undefined;
    }
    let minCard: Card = nonHiddenCards[0];
    for (let i = 1; i < nonHiddenCards.length; i++) {
      if (nonHiddenCards[i].absoluteValue() < minCard.absoluteValue()) {
        minCard = nonHiddenCards[i];
      }
    }
    return minCard;
  }

  public static getHighestTromfCombination(tromfCombinations: TromfCombination): TromfCombination {
    if (tromfCombinations === TromfCombination.Pas) {
      return TromfCombination.Pas;
    }
    let rightShifts = 0;
    while (tromfCombinations !== 0x00000001) {
      tromfCombinations >>= 1;
      rightShifts++;
    }
    return (0x00000001 << rightShifts) as TromfCombination;
  }

  public static isSingleTromfCombination(tromfCombination: TromfCombination): boolean {
    if ((tromfCombination & TromfCombination.EmptyCombinations) !== TromfCombination.Pas) {
      return true;
    }
    let tromfCombinations: number = 0;
    while (tromfCombination !== TromfCombination.Pas) {
      if (tromfCombination & 0x00000001) {
        tromfCombinations++;
      }
      tromfCombination >>= 1;
    }
    return tromfCombinations === 1;
  }

  public static isTromfCombinationOfMinimumCount(tromfCombination: TromfCombination, cardsInTromf: Card[]): boolean {
    return cardsInTromf.length === this.tromfCombinationMinimumCards(tromfCombination);
  }

  // tromfCombination provided must be of singular type
  public static tromfCombinationMinimumCards(tromfCombination: TromfCombination): number {
    switch (tromfCombination) {
      case TromfCombination.Doi:
      case TromfCombination.Pas:
      case TromfCombination.Inchis:
        return 0;
      case TromfCombination.TriArseu:
      case TromfCombination.TriCraita:
      case TromfCombination.TriCalu:
      case TromfCombination.TriTuzu:
        return 3;
      case TromfCombination.PatruArseu:
      case TromfCombination.PatruCalu:
      case TromfCombination.PatruCraita:
      case TromfCombination.PatruTuzu:
        return 4;
      default:
        return -1;
    }
  }

  public static getHiddenCardArray(count: number): Card[] {
    const cards: Card[] = [];
    for (let i = 0; i < count; i++) {
      cards.push(new Card(CardColor.Hidden, CardName.Hidden));
    }
    return cards;
  }

  public static isPlayerRightToDealer(selfPlayer: AppPlayer, dealerPos: number): boolean {
    return selfPlayer.tablePosition - 1 === ((dealerPos + 2) % 4);
  }

  public static getPlayerPositionRightToDealer(dealerPos: number): number {
    return ((dealerPos + 2) % 4) + 1;
  }

  public static getAllTromfCombination(cards: Card[]): { tromfCombination: TromfCombination, cardsInTromf: Card[] } {
    if (cards.length !== 5) {
      return { tromfCombination: TromfCombination.Pas, cardsInTromf: [] };
    }
    const allFilcai: Card[] = cards.filter(card => card.name === CardName.Filcau);
    const otherCards: Card[] = cards.filter(card => card.name !== CardName.Filcau);
    const outputCards: CardSet = new CardSet();
    if (allFilcai.length === 0) {
      return { tromfCombination: TromfCombination.Pas, cardsInTromf: [] };
    }
    if (this.isCombinationInFive(otherCards)) {
      return { tromfCombination: TromfCombination.Inchis, cardsInTromf: otherCards };
    }
    if (allFilcai.length === 1 && this.isCombinationInTwo(otherCards)) {
      return { tromfCombination: TromfCombination.Doi, cardsInTromf: [] };
    }

    // inainte de switch trebuie testat si cazul tuz foarba
    let tromfComb: TromfCombination = TromfCombination.Pas;
    const filcaiComb = GameUtilsService.getSubsets(allFilcai);
    filcaiComb.forEach(filcai => {
      switch (filcai.length) {
        case 1: {
          // check combinations in 4 except TriTuzu
          let { resTromfCombination, resCardsInTromf = [] } = this.isTriOrPatruCombination(otherCards, 4, 1);

          if (!TromfCombinationsUtils.isEmpty(resTromfCombination)) {
            if (this.isTuzFoarbaCombination(otherCards, resCardsInTromf)) {
              return { tromfCombination: TromfCombination.Inchis, cardsInTromf: [] };
            }
            tromfComb |= resTromfCombination;
            outputCards.add(...filcai);
            outputCards.add(...resCardsInTromf);
          }
          // check combinations in 3 except PatruTuzu
          ({ resTromfCombination, resCardsInTromf } = this.isTriOrPatruCombination(otherCards, 3, 1));
          if (!TromfCombinationsUtils.isEmpty(resTromfCombination)) {
            tromfComb |= resTromfCombination;
            outputCards.add(...filcai);
            outputCards.add(...resCardsInTromf);
          }
          break;
        }
        case 2:
          // here we have to check for the following combination
          // patru combinations with 2 filcai:
          let { resTromfCombination, resCardsInTromf = [] } = this.isTriOrPatruCombination(otherCards, 4, 2);
          if (!TromfCombinationsUtils.isEmpty(resTromfCombination)) {
            if (this.isTuzFoarbaCombination(otherCards, resCardsInTromf)) {
              return { tromfCombination: TromfCombination.Inchis, cardsInTromf: [] };
            }
            tromfComb |= resTromfCombination;
            outputCards.add(...filcai);
            outputCards.add(...resCardsInTromf);
          }
          // check combinations in 3 except PatruTuzu
          ({ resTromfCombination, resCardsInTromf } = this.isTriOrPatruCombination(otherCards, 3, 2));
          if (!TromfCombinationsUtils.isEmpty(resTromfCombination)) {
            tromfComb |= resTromfCombination;
            outputCards.add(...filcai);
            outputCards.add(...resCardsInTromf);
          }

          // here we must also check for tri or patru combinations
          break;
        case 3: {
          // check for patru tuzu and regular patru combinations
          // tri combinations here are not allowed
          const tuzCards: Card[] = otherCards.filter(card => card.name == CardName.Tuz);
          const tuzCount: number = tuzCards.length;
          if (tuzCount === 1) {// if we only have 1 Tuz card we are automatically in Patru Tuzu combination
            tromfComb |= TromfCombination.PatruTuzu;
            outputCards.add(...tuzCards);
            outputCards.add(...filcai);
          } else if (tuzCount === 0) {// otherwise we will attemp to get a standard 'patru' if there are no 'Tuz' cards
            // we get here the lowest card of the 2 remaing and form the combination
            const smallestCard: Card = this.getSmallestCardByAbsoluteValue(otherCards);
            const amountOfSmallestCards: number = otherCards.filter(card => card.name === smallestCard.name).length;
            if (amountOfSmallestCards === 2) {
              // with 3 filcai we can form 2 equal 'patru' combinations so we push all the cards
              outputCards.add(...otherCards);
            } else {
              outputCards.add(smallestCard);
            }
            outputCards.add(...filcai);
            switch (smallestCard.name) {
              case (CardName.Arseu):
                tromfComb |= TromfCombination.PatruArseu;
                break;
              case (CardName.Cal):
                tromfComb |= TromfCombination.PatruCalu;
                break;
              case (CardName.Craita):
                tromfComb |= TromfCombination.PatruCraita;
                break;
              default:
                LogServiceHelper.error(`Illegal 'patru' combination with 3 'filcai' and 0 'tuz' but the other cards are not of value 'arseu', 'cal' or 'craita'`);
                debugger;
                break;
            }
          } else {
            // here we automatically have 2 Tuz cards so we fall in the Tuz Foarba combination
            tromfComb |= TromfCombination.Inchis;
          }
          break;
        }
      }
    });
    return { tromfCombination: TromfCombinationsUtils.stripIfMixedCombination(tromfComb), cardsInTromf: outputCards.toArray() };
  }

  private static isTuzFoarbaCombination(otherCards: Card[], tromfCards: Card[]): boolean {
    let otherCardInTromf: boolean;
    const cardsNotInTromf: Card[] = [];
    for (let i: number = 0; i < otherCards.length; i++) {
      otherCardInTromf = false;
      for (let j: number = 0; j < tromfCards.length; j++) {
        if (otherCards[i].equals(tromfCards[j])) {
          otherCardInTromf = true;
        }
      }
      if (!otherCardInTromf) {
        cardsNotInTromf.push(otherCards[i]);
      }
    }
    return cardsNotInTromf.filter(card => card.name === CardName.Tuz).length === cardsNotInTromf.length;
  }

  private static isTriOrPatruTuzCombination(otherCards: Card[]): { tromfCombination: TromfCombination, cardsInTromf: Card[] } {
    let tromfComb = TromfCombination.Pas;
    const tuzCards: Card[] = otherCards.filter(card => card.name === CardName.Tuz);
    let cardsInTromf: Card[] = [];
    if (tuzCards.length >= 1) {
      tromfComb |= otherCards.length === 3 ? TromfCombination.TriTuzu : TromfCombination.PatruTuzu;
      cardsInTromf = [...tuzCards];
    }
    return { tromfCombination: tromfComb, cardsInTromf };
  }

  private static isTriOrPatruCombination(otherCardsArray: Card[], combLength: number, filcauCount: number): { resTromfCombination: TromfCombination, resCardsInTromf: Card[] } {
    const resCardsInTromf: Card[] = [];
    let resTromfCombination: TromfCombination = TromfCombination.Empty;
    const allOtherCards: Card[][] = this.getCardCombinationOfCount(otherCardsArray.filter(card => !card.isHidden), combLength - filcauCount);
    allOtherCards.forEach(otherCards => {
      let smallestCard: Card;
      let cardsOfSameColor: boolean;
      cardsOfSameColor = true;
      for (let j = 1; j < otherCards.length; j++) {
        if (otherCards[0].color !== otherCards[j].color) {
          cardsOfSameColor = false;
          break;
        }
      }
      if (cardsOfSameColor) {
        smallestCard = GameUtilsService.getSmallestCard(otherCards);
        switch (smallestCard.name) {
          case (CardName.Arseu):
            resTromfCombination |= combLength === 3 ? TromfCombination.TriArseu : TromfCombination.PatruArseu;
            break;
          case (CardName.Cal):
            resTromfCombination |= combLength === 3 ? TromfCombination.TriCalu : TromfCombination.PatruCalu;
            break;
          case (CardName.Craita):
            resTromfCombination |= combLength === 3 ? TromfCombination.TriCraita : TromfCombination.PatruCraita;
            break;
        }
        resCardsInTromf.push(...otherCards);
      }
    });
    if (resTromfCombination === TromfCombination.Empty) {
      resTromfCombination = TromfCombination.Pas;
    }
    return { resTromfCombination, resCardsInTromf };
  }

  private static getCardCombinationOfCount(cards: Card[], cardCount: number): Card[][] {
    const cardCombinations: Card[][] = [];
    if (cards.length >= cardCount) {
      const currentCards: Card[] = [];
      for (let i = 0; i < cardCount; i++) {
        currentCards.push(undefined);
      }
      this.getCardCombinationRecursive(cardCombinations, currentCards, 0, -1, cardCount, cards);
    }
    return cardCombinations;
  }

  private static getCardCombinationRecursive(cardsCollector: Card[][], currentCards: Card[], currentPosition: number, prevIndex: number, targetCount: number, cards: Card[]): void {
    if (currentPosition === targetCount) {
      let tmp: Card[] = [];
      tmp.push(...currentCards);
      cardsCollector.push(tmp);
      return;
    }
    for (let i = prevIndex + 1; i < cards.length; i++) {
      currentCards[currentPosition] = cards[i];
      this.getCardCombinationRecursive(cardsCollector, currentCards, currentPosition + 1, i, targetCount, cards);
    }
  }

  private static isCombinationInFive(otherCards: Card[]): boolean {
    if (otherCards.length === 0 || otherCards.length === 1) {
      return false;
    }
    const color: CardColor = otherCards[0].color;
    for (let i = 1; i < otherCards.length; i++) {
      if (otherCards[i].color !== color) {
        return false;
      }
    }
    return true;
  }

  private static isCombinationInTwo(cards: Card[]): boolean {
    for (let i = 0; i < cards.length - 1; i++) {
      for (let j = i + 1; j < cards.length; j++) {
        if (cards[i].color === cards[j].color) {
          return false;
        }
      }
    }
    return true;
  }

  public static computeServerPositionOffset(selfPlayer: AppPlayer): number {
    let playerOffset = ((selfPlayer.tablePosition - 1) - (Position.South - 1)) % 4;
    if (playerOffset < 0) {
      playerOffset += 4;
    }
    return playerOffset;
  }

  public static multipleSameLevelCombsInTromf(tromfCombination: TromfCombination): boolean {
    const triCombinations: TromfCombination[] = [TromfCombination.TriArseu, TromfCombination.TriCalu, TromfCombination.TriCraita];
    const patruCombinations: TromfCombination[] = [TromfCombination.PatruArseu, TromfCombination.PatruCalu, TromfCombination.PatruCraita];
    let triCount: number = 0;
    let patruCount: number = 0;
    for (let i = 0; i < triCombinations.length; i++) {
      if (tromfCombination & triCombinations[i]) {
        triCount++;
      }
      if (tromfCombination & patruCombinations[i]) {
        patruCount++;
      }
    }
    return (triCount > 1) || (patruCount > 1);
  }

  public static getTromfCombinationColor(cards: Card[]): CardColor {
    const nonFilcauCards = cards.filter(card => card.name !== CardName.Filcau);
    if (nonFilcauCards.length === 0 && cards.length >= 3) {
      LogServiceHelper.error(`Trying to calculate tromf color from a set of cards with no non-filcau cards! Failure!`);
      LogServiceHelper.error(`Providing default color: Rosu`);
    }
    return nonFilcauCards.length > 0 ? nonFilcauCards[0].color : CardColor.Rosu;
  }

  public static getSubsets(set: any[]): any[][] {
    const result: any[][] = [];
    GameUtilsService.subsets(set, 0, 0, result, []);
    return result;
  }

  public static subsets(set: any[], index: number, curPos: number, result: any[][], curComb: any[]): void {
    let tempComb = [];
    for (let i = curPos; i < set.length; i++) {
      tempComb = [];
      tempComb.push(...curComb);
      tempComb[index] = set[i];
      if (tempComb.length > 0) {
        result.push(tempComb);
      }
      GameUtilsService.subsets(set, index + 1, i + 1, result, tempComb);
    }
  }

  public static getRoundAllowedCards(cards: Card[], roundNumber: number, isRoundDealer: boolean, tromf: CardColor, roundColor: CardColor, roundDealerCard: Card): Card[] {
    const allowedCards: Card[] = [];
    switch (roundNumber) {
      case (1):
        if (isRoundDealer) {
          allowedCards.push(...cards.filter(card => card.name === CardName.Filcau));
          if (allowedCards.length === 0) {
            allowedCards.push(...cards);
          }
        } else {
          const tromfCards: Card[] = this.getAllTromfCards(cards, tromf);
          allowedCards.push(...(tromfCards.length > 0 ? tromfCards : cards));
        }
        break;
      case (2):
      case (3):
      case (4):
      case (5):
        if (isRoundDealer) {
          const foarbaCards: Card[] = this.getAllFoarbaCards(cards, tromf);
          allowedCards.push(...(foarbaCards.length > 0 ? foarbaCards : this.getAllTromfCards(cards, tromf)));
          if (allowedCards.length === 0) {// in this case the player can deal any card, since he doesn't have anything useful
            allowedCards.push(...cards);
          }
        } else {
          if (tromf === roundColor || roundDealerCard.name === CardName.Filcau) {
            const laCuloareCards: Card[] = this.getAllLaCuloareCards(cards, roundColor);
            if (laCuloareCards.length > 0) {
              allowedCards.push(...laCuloareCards);
            } else {
              const tromfCards: Card[] = this.getAllTromfCards(cards, tromf);
              allowedCards.push(...(tromfCards.length > 0 ? tromfCards : cards));
            }
          } else {
            const laCuloareCards: Card[] = this.getAllLaCuloareCardsNoTromf(cards, roundColor, tromf);
            if (laCuloareCards.length > 0) {
              allowedCards.push(...laCuloareCards);
            } else {
              const tromfCards: Card[] = this.getAllTromfCards(cards, tromf);
              allowedCards.push(...(tromfCards.length > 0 ? tromfCards : cards));
            }
          }
        }
        break;
      default:
        LogServiceHelper.error('Number of allowable game rounds (5) was exceeded!');
        LogServiceHelper.error(`Current round number is ${roundNumber}`);
        break;
    }
    return allowedCards;
  }

  public static getFrisAllowedCards(cards: Card[], isRoundDealer: boolean): Card[] {
    if (cards.length !== 3) {
      LogServiceHelper.error(`Number of allowable fris game cards (3) was exceeded! ${cards.length} in the cards array`);
    }
    let allowedCards: Card[] = [];
    if (isRoundDealer) {
      allowedCards = cards.filter(card => card.name === CardName.Filcau);
    } else {
      allowedCards = cards;
    }
    return allowedCards;
  }

  public static getAllFoarbaCards(cards: Card[], tromf: CardColor): Card[] {
    const foarbaCards: Card[] = [];
    foarbaCards.push(...cards.filter(card => {
      return (card.name !== CardName.Filcau && card.color !== tromf);
    }));
    return foarbaCards;
  }

  public static getAllTromfCards(cards: Card[], tromf: CardColor): Card[] {
    const tromfCards: Card[] = [];
    tromfCards.push(...cards.filter(card => {
      return (card.name === CardName.Filcau || card.color === tromf);
    }));
    return tromfCards;
  }

  public static getAllLaCuloareCards(cards: Card[], roundColor: CardColor): Card[] {
    const laCuloareCards: Card[] = [];
    laCuloareCards.push(...cards.filter(card => {
      return card.color === roundColor;
    }));
    return laCuloareCards;
  }

  public static getAllLaCuloareCardsNoTromf(cards: Card[], roundColor: CardColor, tromf: CardColor): Card[] {
    const laCuloareCards: Card[] = [];
    laCuloareCards.push(...cards.filter(card => {
      return card.color === roundColor && card.name !== CardName.Filcau && card.color !== tromf;
    }));
    return laCuloareCards;
  }

  // cards must be in round dealer dealing order
  public static getRoundWinner(playedCards: { tablePosition: number, card: Card }[], tromf: CardColor): Position {
    let maxIndex = 0;
    const startColor: CardColor = playedCards[maxIndex].card.color;
    for (let i = 1; i < playedCards.length; i++) {
      if (playedCards[i].card.absoluteValue(tromf) >= playedCards[maxIndex].card.absoluteValue(tromf) &&
        (playedCards[i].card.isTromf(tromf) || playedCards[i].card.isColor(startColor))) {
        maxIndex = i;
      }
    }
    return playedCards[maxIndex].tablePosition;
  }

  public static advanceRoundPosition(roundPosition: number): number {
    roundPosition = roundPosition - 1;
    roundPosition = (roundPosition + 1) % 4;
    return roundPosition + 1;
  }

  public static getTeamPartnerPosition(currentTablePos: number): number {
    return ((currentTablePos + 1) % 4) + 1;
  }

  public static getPreviousTablePosition(currentTablePos: number): number {
    return ((currentTablePos + 2) % 4) + 1;
  }

  public static isBunaCombinationPossible(currentTablePos: number, players: AppPlayer[]): boolean {
    let playerPos: number = currentTablePos; this.getTeamPartnerPosition(currentTablePos);
    let player: AppPlayer;

    for (let i = 0; i < 3; i++) {
      playerPos = this.getPreviousTablePosition(playerPos);
      player = players.filter(player => player.tablePosition === playerPos)[0];
      if (!TromfCombinationsUtils.isEmpty(player.tromfCombination)) {
        return true;
      }
    }
    return false;
  }

  public static getGameScore(player: AppPlayer, players: AppPlayer[], selfTeamStarted: boolean): { gameScore: GameScore, selfScore: number } {
    let selfTeamScore: number = 0;
    const selfTeamWonCards: Card[] = [];
    let opponentTeamScore: number = 0;
    const opponentTeamWonCards: Card[] = [];
    const selfScore: number = this.getPlayerScore(player.wonCards);
    let score: number = 0;
    for (let i = 0; i < players.length; i++) {
      score = this.getPlayerScore(players[i].wonCards);
      if (players[i].tablePosition % 2 === player.tablePosition % 2) {
        selfTeamScore += score;
        selfTeamWonCards.push(...players[i].wonCards);
      } else {
        opponentTeamScore += score;
        opponentTeamWonCards.push(...players[i].wonCards);
      }
    }

    const selfScoreType: ScoreType = this.getPlayerScoreType(selfTeamScore, selfTeamWonCards.length, selfTeamStarted, opponentTeamScore);
    const opponentScoreType: ScoreType = this.getPlayerScoreType(opponentTeamScore, opponentTeamWonCards.length, !selfTeamStarted, selfTeamScore);

    const gameScore: GameScore = new GameScore();
    gameScore.setScore(player, selfTeamScore, selfScoreType, true);
    gameScore.setScore(players.filter(arrPlayer => arrPlayer.teamNumber !== player.teamNumber)[0], opponentTeamScore, opponentScoreType, false);

    return { gameScore, selfScore };
  }

  public static getPlayerScore(wonCards: Card[]): number {
    return wonCards.reduce<number>((score, card) => score += card.points, 0);
  }

  public static getTeamWonCards(player: AppPlayer, players: AppPlayer[]): { selfWonCards: Card[], opponentWonCards: Card[] } {
    const selfWonCards: Card[] = [];
    const opponentWonCards: Card[] = [];
    for (let i = 0; i < players.length; i++) {
      if (players[i].tablePosition % 2 === player.tablePosition % 2) {
        selfWonCards.push(...players[i].wonCards);
      } else {
        opponentWonCards.push(...players[i].wonCards);
      }
    }
    return { selfWonCards: selfWonCards, opponentWonCards: opponentWonCards };
  }

  public static getGameScoreType(selfTeamWonCards: Card[], opponentTeamWonCards: Card[], selfTeamStarted: boolean): { selfScore: number, selfScoreType: ScoreType, opponentScore: number, opponentScoreType: ScoreType } {
    const selfScore: number = this.getPlayerScore(selfTeamWonCards);
    const opponentScore: number = this.getPlayerScore(opponentTeamWonCards);
    const selfScoreType: ScoreType = this.getPlayerScoreType(selfScore, selfTeamWonCards.length, selfTeamStarted, opponentScore);
    const opponentScoreType: ScoreType = this.getPlayerScoreType(opponentScore, opponentTeamWonCards.length, !selfTeamStarted, selfScore);
    return { selfScore, selfScoreType, opponentScore, opponentScoreType };
  }

  public static getPlayerScoreType(score: number, wonCards: number, started: boolean, opponentScore: number): ScoreType {
    let scoreType: ScoreType = ScoreType.Invalid;
    if (wonCards === 0) {
      scoreType |= started ? ScoreType.HudaLegata : ScoreType.Huda;
    }
    if (score <= 2) {
      scoreType |= ScoreType.Sluga;
    }
    if (score < 6 && opponentScore < 6) {
      scoreType |= ScoreType.PaRanduSatului;
    }
    if (score === 3) {
      scoreType |= ScoreType.NormalLoss;
    }
    if (score === opponentScore) {
      scoreType |= ScoreType.Tie;
    }
    if (score > 4) {
      scoreType |= ScoreType.Winner;
    }
    return scoreType;
  }

  public static getTeam(id: 1 | 2, players: AppPlayer[]): Team {
    const team: Team = new Team();
    if (id == 1) {
      team.player1 = players.filter(p => p.tablePosition == 1)[0];
      team.player2 = players.filter(p => p.tablePosition == 3)[0];
    } else {
      team.player1 = players.filter(p => p.tablePosition == 2)[0];
      team.player2 = players.filter(p => p.tablePosition == 4)[0];
    }
    return team;
  }

  public static getPlayerTeamIndex(player: AppPlayer): 1 | 2 {
    const tbPos = player.tablePosition;
    if (tbPos === 1 || tbPos === 3) {
      return 1;
    }
    return 2;
  }
}
