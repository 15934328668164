<div class="container p-20">

    <div class="card shadow-sm p-3 bg-body rounded">
        <div class="card-body">
            <div class="table-responsive">
                <table mat-table [dataSource]="dataSource" class="table-striped table-hover">

                    <ng-container matColumnDef="status">
                        <th mat-header-cell *matHeaderCellDef> Status </th>
                        <td mat-cell *matCellDef="let userDetails">
                            <i *ngIf="(userDetails.id | userStatus:onlineUsers);else offline"
                                class="fas fa-circle online"></i>
                            <ng-template #offline>
                                <i class="fas fa-circle offline"></i>
                            </ng-template>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="username">
                        <th mat-header-cell *matHeaderCellDef> Username </th>
                        <td mat-cell *matCellDef="let userDetails"> {{userDetails.userName}} </td>
                    </ng-container>

                    <ng-container matColumnDef="surname">
                        <th mat-header-cell *matHeaderCellDef> Nume </th>
                        <td mat-cell *matCellDef="let userDetails"> {{userDetails.surname}} </td>
                    </ng-container>

                    <ng-container matColumnDef="name">
                        <th mat-header-cell *matHeaderCellDef> Prenume </th>
                        <td mat-cell *matCellDef="let userDetails"> {{userDetails.name}} </td>
                    </ng-container>

                    <ng-container matColumnDef="email">
                        <th mat-header-cell *matHeaderCellDef> Email </th>
                        <td mat-cell *matCellDef="let userDetails"> {{userDetails.email}} </td>
                    </ng-container>

                    <ng-container matColumnDef="phoneNumber">
                        <th mat-header-cell *matHeaderCellDef> Telefon </th>
                        <td mat-cell *matCellDef="let userDetails"> {{userDetails.phoneNumber}} </td>
                    </ng-container>

                    <ng-container matColumnDef="registrationTime">
                        <th mat-header-cell *matHeaderCellDef> Inregistrare </th>
                        <td mat-cell *matCellDef="let userDetails"> {{userDetails.registrationTime | date:'M/d/yy, h:mm
                            a'}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="lastLoginTime">
                        <th mat-header-cell *matHeaderCellDef> Ultima logare </th>
                        <td mat-cell *matCellDef="let userDetails"> {{userDetails.lastLoginTime | date:'M/d/yy, h:mm
                            a'}}
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="emailConfirmed">
                        <th mat-header-cell *matHeaderCellDef> Confirmat </th>
                        <td mat-cell *matCellDef="let userDetails">
                            <div class="primary-light-green" *ngIf="userDetails.emailConfirmed; else notConfirmed">
                                <i class="fas fa-check fl-primary-success"></i>
                            </div>
                            <ng-template #notConfirmed>
                                <i class="fas fa-close fl-primary-error"></i>
                            </ng-template>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="lockoutEnabled">
                        <th mat-header-cell *matHeaderCellDef> Blocat </th>
                        <td mat-cell *matCellDef="let userDetails">
                            <div *ngIf="isUserLocked(userDetails)">
                                <i class="fas fa-lock"></i>
                            </div>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="isAdmin">
                        <th mat-header-cell *matHeaderCellDef> Admin </th>
                        <td mat-cell *matCellDef="let userDetails">
                            <div class="primary-light-green" *ngIf="userDetails.isAdmin; else notAdmin">
                                <i class="fas fa-check fl-primary-success"></i>
                            </div>
                            <ng-template #notAdmin>
                                <i class="fas fa-close fl-primary-error"></i>
                            </ng-template>
                        </td>
                    </ng-container>

                    <ng-container matColumnDef="actions">
                        <th mat-header-cell *matHeaderCellDef> </th>

                        <td mat-cell *matCellDef="let userDetails">
                            <button mat-icon-button (click)="onActions(userDetails)">
                                <i class="fas fa-ellipsis-v"></i>
                            </button>
                        </td>
                    </ng-container>

                    <tr mat-header-row *matHeaderRowDef="columns"></tr>
                    <tr mat-row *matRowDef="let row; columns: columns;"></tr>
                </table>

                <mat-paginator #paginator showFirstLastButtons [length]="length" [pageIndex]="pageIndex"
                    [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions" (page)="onPageChange($event)">
                </mat-paginator>
            </div>
        </div>
    </div>
</div>