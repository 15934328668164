import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-draggable-link',
  templateUrl: './draggable-link.component.html',
  styleUrls: ['./draggable-link.component.scss']
})
export class DraggableLinkComponent implements OnInit {

  dragging = false;
  dragPosition = { x: 0, y: 80 };

  constructor(
    private readonly router: Router
  ) { }

  ngOnInit(): void {
    let y = -80;

    if (Math.abs(y) > window.innerHeight) {
      y = -window.innerHeight + 40;
    }

    this.dragPosition.y = y;
  }

  dragStart() {
    this.dragging = true;
  }

  dragEnd() {
    setTimeout(() => {
      this.dragging = false;
    }, 200);
  }


  buttonClicked() {
    if (!this.dragging) {
      this.router.navigate(['/']);
    }
  }

  getPosition(el: any) {
    let x = 0;
    let y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
      x += el.offsetLeft - el.scrollLeft;
      y += el.offsetTop - el.scrollTop;
      el = el.offsetParent;
    }
    return { top: y, left: x };
  }
}