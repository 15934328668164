import { Subject, Subscription, timer, Observable } from 'rxjs';

export class TimerWrapper {
    public timerStarted: Subject<number> = new Subject<number>();
    public timerStopped: Subject<number> = new Subject<number>();
    public timerReset: Subject<void> = new Subject<void>();
    public timedOut: Subject<{ startTime: number, endTime: number }> = new Subject<{ startTime: number, endTime: number }>();

    public static defaultTimeout: number = 15000;
    private timeout: number = 15000;//miliseconds
    private recurrent: boolean = false;
    private subscriptions: Subscription;
    private timerSource: Observable<number> = undefined;
    private startTime: number;

    public constructor() {
        this.subscriptions = new Subscription();
    }

    public start(timeout?: number): void {
        if (!this.timerSource) {
            throw "Illegal start of timer while the timer is running";
        }
        this.timerSource = timer(timeout ? timeout : this.timeout, this.recurrent ? (timeout ? timeout : this.timeout) : 0);
        this.startTime = new Date().getTime();
        this.subscriptions.add(this.timerSource.subscribe(() => {
            this.timedOut.next({ startTime: this.startTime, endTime: (new Date()).getTime() });
            this.startTime = 0;
            this.clearTimerSubscriptions();
        }));
        this.timerStarted.next(this.startTime);
    }

    public stop(): void {
        this.timerStopped.next(new Date().getTime());
        this.startTime = 0;
        this.clearTimerSubscriptions();
    }

    //equivalent with restart
    public reset(timeout?: number): void {
        this.stop();
        this.start(timeout);
        this.timerReset.next();
    }

    public set timerTimeout(timeout: number) {
        if (!this.timerSource) {
            throw "Illegal change of timeout value while the timer is running";
        }
        this.timeout = timeout;
    }

    public get timerTimeout(): number {
        return this.timeout;
    }

    private clearTimerSubscriptions(): void {
        this.subscriptions.unsubscribe();
        this.timerSource = undefined;
    }

    public dispose(): void {
        this.clearTimerSubscriptions();
    }
}
