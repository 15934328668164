<div class="container">
  <nav class="navbar navbar-expand-sm p-2" [ngClass]="backgroundColor">
    <button class="navbar-toggler fa fa-bars" type="button" data-bs-toggle="collapse"
      data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
      aria-label="Toggle navigation">
    </button>

    <div class="d-flex row login-container-inner" *ngIf="displayLogin">
      <a *ngIf="!user" class="nav-link col" routerLink="/login">Login</a>
      <div *ngIf="user" class="d-flex col-auto">
        <app-userprofile [textColor]="backgroundColor === 'nav-bg-white' ? 'nav-link-dark' : 'nav-link-light'">
        </app-userprofile>
      </div>
    </div>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav mr-auto">
        <li *ngFor="let menu of (menuItems | permission);">
          <a class="nav-link" [routerLink]="menu.route">{{menu.label}}</a>
        </li>
        <li *ngFor="let link of (linkItems | permission);">
          <a class="nav-link" [href]="link.route">{{link.label}}</a>
        </li>
      </ul>
    </div>

    <div class="d-flex row login-container-outer" *ngIf="displayLogin">
      <a *ngIf="!user" class="nav-link col" routerLink="/login">Login</a>
      <div *ngIf="user" class="d-flex col-auto">
        <app-userprofile [textColor]="backgroundColor === 'nav-bg-white' ? 'nav-link-dark' : 'nav-link-light'">
        </app-userprofile>
      </div>
    </div>
  </nav>
</div>