import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoaderService } from 'src/core/services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit, OnDestroy {

  public state: boolean = false;

  private subscriptions: Subscription;

  constructor(private loaderService: LoaderService) {
    this.subscriptions = new Subscription();
    this.subscriptions.add(this.loaderService.loaderObservable.subscribe((state) => this.loaderStateUpdated(state)));
  }

  private loaderStateUpdated(newState: boolean): void {
    this.state = newState;
  }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
