<div class="container p-20">
  <div class="card shadow-sm p-3 mb-5 bg-body rounded">

    <h5 class="card-title">Despre filcău</h5>

    <div class="card-body">

      <div class="m-2">
        <p>Este un joc de cărți care s-a nascut în Transilvania, din necesitatea de a socializa și de a umple timpul de
          așteptare din gări, autogări, în așteptarea trenului sau a "ratei", care ducea muncitorii la lucru, jucându-se
          inclusiv în tren sau în "rată" (autobuz de transport),
          în picioare, practic oriunde puteau sta patru oameni unul langă altul.</p>
        <p>Acest joc, pe lângă faptul că era deosebit de distractiv, prin prisma vocabularului specific folosit, avea
          avantajul că se putea opri oricând unul dintre jucatori trebuia să coboare. Scorul nu
          era important, iar la finalul jocului, jucătorii își dădeau mâna fericiți. Jocul a fost practicat în
          general de bărbați, cu cărți de joc ungurești soioase, chiar și în birturi, sau în fața lor, la "una mică"
          sau la o "cinzeacă" servită "la botu' calului", dar a fost observat și în căruțe când se pleca la lucru,
          sau în stații când se aștepta "rata".</p>
        <p>Pentru un privitor de rând, expresiile și interjecțiile din timpul
          jocului, îl fac să nu ințeleagă nimic la prima vedere, dar pitorescul fețelor și al atitudinii jucătorilor,
          atrag ca un magnet, mai ales că acest joc se joacă cu veselie și bună dispoziție și chiar când se
          termină, jucătorii se mai tachinează în același ton. Jocul a fost descoperit în zona comunei Iara
          județul Cluj, dar se practica și în zona Mediaș, Copșa Mică, Huedin, Aleșd, Alba-Iulia, Zlatna și în
          general oriunde erau navete.</p>
        <p>Vocabularul a fost întocmit pentru a respecta cât mai adecvat
          expresiile, definițiile, situațiile de joc și nu în utlimul rând accentul, în vederea jucării jocului la fel
          ca în acele vremuri. Regulamentul de joc este și el divers, în funcție de zonele în care se juca, dar
          în mare parte este identic, diferențele fiind la modul de stabilire a clasamentului, la modul de jucare a
          "frișului",
          la modul de a "tromfăli", etc. Acest regulament prezentat, este cel mai echilibrat dintre toate,
          dă șanse egale tuturor celor patru jucători din cele două echipe în orice situație și a fost stabilit după
          culegerea lui pe baza experienței jucătorilor vechi. Desigur că acest regulament poate fi modificat
          după dorințele jucătorilor, esențial este ca filonul filcăului să fie păstrat și transmis.</p>
      </div>

      <div class="m-2">
        <h5 class="expanding-header" (click)="toggleTab(0)">Cărți de joc <i class="fa"
            [ngClass]="openedTabs[0] ? 'fa-angle-up' : 'fa-angle-down'"></i></h5>
        <div *ngIf="openedTabs[0]" class="tab-content">
          <p>Un set de cărți de joc "ungurești" (de la care se scot carțile VII, VIII și IX), în total 20 de cărți.</p>
          <p>Cărțile sunt de patru culori după cum urmează: ghindă, verde, roșu și dâbă.</p>
          <div class="flex-wrap" fxLayout="row">
            <div *ngFor="let card of cardSymbols; let i = index" fxFlex class="example-card" matRipple
              (click)="openAlbumImage(cardSymbols, i)">
              <img class="card-icon card-symbol" mat-card-image [src]="card.thumb">
              <div class="card-subtitle" [innerHTML]="card.caption"></div>
            </div>
          </div>
          <p>Pentru fiecare culoare cărțile sunt următoarele: 'filcău' (dama sau 3), 'tuz' (as sau 11), 'crăiță' (X sau
            10), 'cal' (popa sau 4) și 'arșeu' (valet sau 2).
          </p>
          <div class="flex-wrap" fxLayout="row">
            <div *ngFor="let card of cardNames; let i = index" fxFlex class="example-card" matRipple
              (click)="openAlbumImage(cardNames, i)">
              <img class="card-icon" mat-card-image [src]="card.thumb">
              <div class="card-subtitle" [innerHTML]="card.caption"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="m-2">
        <h5 class="expanding-header" (click)="toggleTab(1)">Glosar <i class="fa"
            [ngClass]="openedTabs[1] ? 'fa-angle-up' : 'fa-angle-down'"></i></h5>
        <div *ngIf="openedTabs[1]" class="tab-content">
          <p>Pentru a ușura înțelegerea jocului precum și noțiunile legat de modul de joacă, mai jos este prezentat un
            glosar cu termenii specifici jocului:</p>
          <div class="flex-wrap" fxLayoutGap="20px" fxLayout="row">
            <div fxFlex>
              <ul class="list-group list-group-flush">
                <li class="list-group-item"><span class="highlight">filcău:</span> carte din setul de joc echivalentă cu
                  damă
                  (3)</li>
                <li class="list-group-item"><span class="highlight">tuz:</span> carte din setul de joc echivalentă cu as
                  (11)
                </li>
                <li class="list-group-item"><span class="highlight">crăiță (zecică):</span> carte din setul de joc
                  echivalentă cu X (10)</li>
                <li class="list-group-item"><span class="highlight">cal (crai):</span> carte din setul de joc
                  echivalentă
                  cu
                  popă (4)</li>
                <li class="list-group-item"><span class="highlight">arșeu (doieș):</span> carte din setul de joc
                  echivalentă cu valet (2)</li>
                <li class="list-group-item"><span class="highlight">punct:</span> carte de "tuz" sau "crăiță",
                  indiferent de culoare</li>
                <li class="list-group-item"><span class="highlight">foarbă:</span> carte care nu este de culoarea
                  tromfului și nici nu este filcău care este considerat în orice condiție tromf</li>
                <li class="list-group-item"><span class="highlight">tromf:</span> atu, carte care prin culoarea ei este
                  mai mare decât orice altă carte care nu este de culoarea
                  ei, mai puțin filcău, care este mai mare decât orice tromf</li>
                <li class="list-group-item"><span class="highlight">ordinea descrescătoare a tromfului:</span> "tuz",
                  "crăiță", "cal", "arșeu"</li>
                <li class="list-group-item"><span class="highlight">ordinea descrescătoare a filcăilor:</span> "filcău'
                  de
                  ghindă" (ghindașu'), "filcău' de verde" (verdieșu'), "filcău' de roșu" (roșieticu'), "filcău' de dâbă"
                  (dâbașu')</li>
                <li class="list-group-item"><span class="highlight">mână:</span> patru cărți jucate</li>
                <li class="list-group-item"><span class="highlight">joc:</span> toate cărțile jucate (20)</li>
                <li class="list-group-item"><span class="highlight">meci:</span> mai multe jocuri jucate, terminate,
                  după care o echipă ajunge la scorul 3</li>
                <li class="list-group-item"><span class="highlight">ghindă:</span> culoare de ghindă, (echivalent
                  treflă)
                </li>
                <li class="list-group-item"><span class="highlight">verde:</span> culoare de verde, (echivalent pică -
                  inimă neagră)</li>
                <li class="list-group-item"><span class="highlight">roșu:</span> culoare de roșu, (echivalent inimă
                  roșie)
                </li>
                <li class="list-group-item"><span class="highlight">dâbă:</span> culoare de dâbă (bâtă, dubă),
                  (echivalent
                  caro)</li>
                <li class="list-group-item"><span class="highlight">pă rându' satului:</span> situație în care nicio
                  echipă nu a dus, în jocul anterior, mai mult sau egal cu 6 puncte</li>
                <li class="list-group-item"><span class="highlight">slugă:</span> situație în care o echipă a dus, în
                  jocul anterior, cel mult 2 puncte, sau care a luat "hudă" sau "hudă legată"</li>
                <li class="list-group-item"><span class="highlight">hudă:</span> situație în care o echipă nu a dus, în
                  jocul anterior, nicio carte</li>
                <li class="list-group-item"><span class="highlight">hudă legată:</span> situație în care o echipă nu a
                  dus, în jocul anterior, vreo carte, jocul desfășurat fiind pe
                  "legătura" ei</li>
                <li class="list-group-item"><span class="highlight">legătură:</span> situație în care un jucător
                  (echipă), realizează o combinație de filcău sau filcăi, plus cărți
                  de aceeași culoare, care să permită realizarea unei combinații de un număr de minim trei și maxim
                  patru tromfi</li>
              </ul>
            </div>
            <div fxFlex>
              <ul class="list-group list-group-flush">
                <li class="list-group-item"><span class="highlight">friș:</span> joc de început care se joacă cu
                  numai trei cărți, pentru stabilirea tromfului, fără a mai putea executa "legătură"</li>
                <li class="list-group-item"><span class="highlight">pentru alta:</span> situație în care se face "friș"
                  iar jucătorul care trebuie să dea, are în mână trei cărți toate de tip punct ("tuz" sau "crăiță")</li>
                <li class="list-group-item"><span class="highlight">închis:</span> situație în care "legătura" din mână
                  este de tip "patru" iar "foarba" este "tuz", sau în care "legătura" din mână este de tip "cinci" adică toate cărțile sunt tromfi</li>
                <li class="list-group-item"><span class="highlight">pas:</span> situație în care jucătorul nu poate face
                  "legătură", deși are filcău, sau dacă jucătorul nu are filcău</li>
                <li class="list-group-item"><span class="highlight">dă' să le fac de șase:</span> situație în care o
                  echipă este "slugă" și urmează să facă cărțile</li>
                <li class="list-group-item"><span class="highlight">doi:</span> fără posibilitade de "legătură" adică
                  jucătorul are un filcău și patru cărti de culori diferite</li>
                <li class="list-group-item"><span class="highlight">tri' arșeu':</span> "legătură" cu filcău și încă
                  două cărți de aceeași culoare, dintre care cea mai mică este
                  "arșeu", sau "legătură" cu doi filcăi și un "arșeu"</li>
                <li class="list-group-item"><span class="highlight">tri' calu':</span> "legătură" cu filcău și încă două
                  cărți de aceeași culoare, dintre care cea mai mică este
                  "cal", sau "legătură" cu doi filcăi și un "cal"</li>
                <li class="list-group-item"><span class="highlight">tri' crăița:</span> "legătură" cu filcău și încă
                  două cărți de aceeași culoare, dintre care cea mai mică este
                  "crăiță", sau "legătură" cu doi filcăi și o "crăiță"</li>
                <li class="list-group-item"><span class="highlight">tri' tuzu':</span> "legătură" cu doi filcăi și un
                  "tuz"</li>
                <li class="list-group-item"><span class="highlight">patru' arșeu':</span> "legătură" cu filcău și încă
                  trei cărți de aceeași culoare, dintre care cea mai mică este
                  "arșeu", sau "legătură" cu doi filcăi și încă două cărți de aceeași culoare, dintre care cea mai mică
                  este "arșeu", sau "legătură" cu trei filcăi și un "arșeu"</li>
                <li class="list-group-item"><span class="highlight">patru' calu':</span> "legătură" cu filcău și încă
                  trei cărți de aceeași culoare, dintre care cea mai mică este
                  "cal", sau "legătură" cu doi filcăi și încă două cărți de aceeași culoare, dintre care cea mai mică
                  este "cal", sau "legătură" cu trei filcăi și un "cal"</li>
                <li class="list-group-item"><span class="highlight">patru' crăița:</span> "legătură" cu trei filcăi și o
                  "crăiță"</li>
                <li class="list-group-item"><span class="highlight">patru' tuzu':</span> "legătură" cu trei filcăi și un
                  "tuz"</li>
                <li class="list-group-item"><span class="highlight">ciolit:</span> situație în care un jucător a trișat,
                  sau a înșelat, sau a încălcat cu sau fără știință regulile jocului (nepermis în jocul online)</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="m-2">
        <h5 class="expanding-header" (click)="toggleTab(2)">Dealer <i class="fa"
            [ngClass]="openedTabs[2] ? 'fa-angle-up' : 'fa-angle-down'"></i></h5>
        <div *ngIf="openedTabs[2]" class="tab-content">
          <p>Dealerul este cel care "face cărțile" și deci trebuie să amestece cărțile și să le împartă. Primul dealer
            este ales aleator.</p>
          <p>Dealerul, după ce a amestecat cărțile, dă jucătorului din dreapta lui cărțile pentru a le tăia sau bate.
            Dacă pachetul a fost bătut, dealerul imparte câte cinci cărți odată la fiecare jucător, în ordinea acelor
            de ceasornic, începând cu cel din stânga lui și terminând cu el. În acest caz, nu se mai poate face
            "friș".</p>
          <p>Dacă pachetul a fost tăiat, dealerul împarte în ordinea acelor de ceasornic, începând cu cel din
            stânga lui, doar câte trei cărți odată la fiecare jucător, lăsând șansa jucătorilor să facă "friș".
            Dacă se face "friș", dealerul va juca și el jocul de "friș", iar după ce se termină, va împărți și
            celalalte două cărți odată, rămase la fiecare, în acceași ordine, începând cu primul jucător care a
            primit cele 3 cărți.</p>
        </div>
      </div>
      <div class="m-2">
        <h5 class="expanding-header" (click)="toggleTab(3)">Jocul de friș <i class="fa"
            [ngClass]="openedTabs[3] ? 'fa-angle-up' : 'fa-angle-down'"></i></h5>
        <div *ngIf="openedTabs[3]" class="tab-content">

          <p>Jocul de "friș" se joacă pentru stabilirea tromfului cu numai trei cărți. După jucarea "frișului",
            se stabilește tromful, iar jocul în continuare se va desfășura fără posibilitatea de a mai face
            "legătură". Jucătorii, după ce au primit cele trei cărți de la dealer după tăierea pachetului, în
            ordinea în care au primit cărțile, pot declara "friș", cu următoarea condiție: toate cele trei cărți din
            mână să fie o combinație de o culoare posibilă de tromf (care în cazul jucării "frișului" pe
            mâna lui, va fi declarat culoare de tromf pentru tot jocul) și să aibă cel puțin una din cărțile din
            mână, filcău.</p>
          <p>Exemplul 1: Un filcău (de orice culoare), plus două cărți (ex: tuz și arșeu) de aceeași culoare oricare
            ar fi ea - în acest caz, culoarea posibilă a tromfului va fi culoarea celor două carți tuz și arșeu.</p>
          <p>Exemplul 2. Doi filcăi (de orice culoare), plus o carte (ex: cal) de orice culoare - în acest caz,
            culoarea posibilă a tromfului va fi culoarea calului.</p>
          <p>Exemplul 3. Trei filcăi - în acest caz, culoarea posibilă a tromfului va fi orice culoare la alegerea
            jucătorului.</p>
          <p>Jocul de "friș" nu este obligatoriu de jucat chiar dacă jucătorul are o combinație care îi permite să
            declare "friș", dar dacă a ales să nu joace "friș", nu mai poate avea eventuale pretenții la "ce iești
            mic?".</p>
          <p>Dacă sunt cel puțin doi jucători care după ce au primit cele trei cărți și au constatat că au în mână o
            combinație care le poate permite să joace "friș" și declară "friș", în această situație, se
            întreabă "ce iești mic?" și toți jucătorii calificați după cărțile din mână și după declarația lor de
            "friș" sunt obligați să spună valoarea (dar nu și culoarea), celei mai mici cărți din combinația celor
            trei din mână (ex: arșeu din exemplul nr.1, cal din exemplul nr.2 și filcău din exemplul nr.3). În
            această situație, jucătorul care are cartea ce mai mare din punct de vedere valoric va începe jocul de
            "friș" (în exemplu,
            jucătorul cu filcău, aceasta fiind cea mai mare carte.</p>
          <p>Jocul de "friș" se desfășoară astfel: cel care a câștigat dreptul de a juca jocul, dă jos filcăul. În
            ordine, ceilalți jucători dau câte o carte. Mâna o duce jucătorul care dă cea mai mare carte. După
            aceea se declară culoarea tromfului.</p>
          <p>Exemplul 1: dacă jucătorul care începe "friș"-ul dă filcău și ceilalți jucători dau orice carte în afară
            de filcău, jucătorul respectiv va duce mâna și va declara culoarea tromfului (conform combinației
            celor trei cărți din mână), după care dealerul va împărți și celalalte două cărti. Jocul apoi continuă
            cu tromful stabilit, iar cel care va începe jocul, va fi cel care a dus "frișul", care va începe să dea o
            carte obligatoriu de "foarbă".</p>
          <p>Exemplul 2: dacă jucătorul care începe "frișul" da filcău și vreun jucător dă un filcău mai mare,
            jucătorul care a dat cel mai mare filcău va duce mâna, iar jucătorul care a început "frișul" va
            declara culoarea tromfului (conform combinației celor trei cărți din mână), după care dealerul va
            împărți și celalalte două cărti. Jocul apoi continuă cu tromful stabilit, iar cel care va începe jocul, va
            fi cel care a dus "frișul", care va începe să dea o carte obligatoriu de "foarbă".</p>
        </div>
      </div>

      <div class="m-2">
        <h5 class="expanding-header" (click)="toggleTab(4)">Legarea tromfului <i class="fa"
            [ngClass]="openedTabs[4] ? 'fa-angle-up' : 'fa-angle-down'"></i></h5>
        <div *ngIf="openedTabs[4]" class="tab-content">
          <p>Dacă s-a jucat "friș", tromful (culoarea lui), pentru tot jocul este cel stabilit de cel care a
            început jocul de "friș".</p>
          <p>Dacă nu s-a jucat "friș" (din cauză ca din cele trei cărți ale jucătorilor nu a rezultat nicio
            combinație pentru inițierea jocului de "friș", sau dacă a existat vreuna, jucătorul a ales să nu joace
            "friș"), atunci după împărțirea tuturor celor cinci cărți, primul jucător care a primit cărți de la dealer,
            va putea să "lege", apoi în ordine toți jucătorii pot "lega". Cel care va câștiga "legătura" este cel
            care va începe jocul. "Legătura" se stabilește prin combinarea cărților din mână astfel încât să
            rezulte un numar cât mai mare de tromfi. "Legătura" nu se poate face numai dacă jucătorul are în
            mână cel puțin un filcău. Dacă jucătorul nu are filcău nu poate "lega" și atunci declară "pas".</p>
          <p>Combinațiile posibile pentru "legături" sunt:</p>
          <ol class="list-group list-group-numbered">
            <li class="list-group-item d-flex justify-content-between align-items-start">
              <div class="ms-2 me-auto">
                <div class="fw-bold">Tri' arșeu'</div> o combinație cu un filcău de orice culoare plus două cărți de
                aceeași culoare (ex:
                "tuz" + "arșeu"), din care în combinație cu filcăul, rezultă trei tromfi = "legătura tri" (filcăul,
                "tuz'u" și "arșeu" care toți sunt considerați de aceeași culoare - chiar dacă filcăul poate avea
                culoare diferită față de celalalte cărți), plus se "leagă" cu cea mai mică carte din combinație -
                "arșeu"
              </div>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-start">
              <div class="ms-2 me-auto">
                <div class="fw-bold">Tri' calu'</div> o combinație cu un filcău de orice culoare plus două cărți de
                aceeași culoare (ex: "tuz"
                + "cal"), din care în combinație cu filcăul, rezultă trei tromfi = "legătura tri" (filcăul, "tuz'u" și
                "calu" care toți sunt considerați de aceeași culoare - chiar dacă filcăul poate avea culoare diferită
                față de celalalte cărți), plus se "leagă" cu cea mai mică carte din combinație - "calu"
              </div>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-start">
              <div class="ms-2 me-auto">
                <div class="fw-bold">Tri' crăița'</div> o combinație cu un filcău de orice culoare plus două cărți de
                aceeași culoare (ex:
                "tuz" + "crăiță"), din care în combinație cu filcăul, rezultă trei tromfi = "legătura tri" (filcăul,
                "tuz'u" și "crăița" care toți sunt considerați de aceeași culoare - chiar dacă filcăul poate avea
                culoare diferită față de celalalte cărți), plus se "leagă" cu cea mai mică carte din combinație:
                "crăița"
              </div>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-start">
              <div class="ms-2 me-auto">
                <div class="fw-bold">Tri' tuzu'</div> o combinație cu doi filcăi de orice culoare plus o carte de "tuz",
                din care în combinație
                cu filcăii, rezultă trei tromfi = "legătura tri" (filcăii și "tuz'u" care toți sunt considerați de
                aceeași
                culoare - chiar dacă filcăii pot avea culoare diferită față de "tuz"), plus se "leagă" cu cea mai
                mică carte din combinație: "tuz"
              </div>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-start">
              <div class="ms-2 me-auto">
                <div class="fw-bold">Patru' arșeu'</div> o combinație cu un filcău de orice culoare plus trei cărți de
                aceeași culoare (ex:
                "tuz" + "cal" + "arșeu"), din care în combinație cu filcăul, rezultă patru tromfi = "legătura patru"
                (filcăul, "tuz'u", "calu" și "arșeu" care toți sunt considerați de aceeași culoare - chiar dacă filcăul
                poate avea culoare diferită față de celalalte cărți), plus se "leagă" cu cea mai mică carte din
                combinație: "arșeu"
              </div>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-start">
              <div class="ms-2 me-auto">
                <div class="fw-bold">Patru' calu'</div> o combinație cu un filcău de orice culoare plus trei cărți de
                aceeași culoare (ex:
                "tuz" + "crăiță" + "cal"), din care în combinație cu filcăul, rezultă patru tromfi = "legătura patru"
                (filcăul, "tuz'u", "crăița" și "calu" care toți sunt considerați de aceeași culoare - chiar dacă filcăul
                poate avea culoare diferită față de celalalte cărți), plus se "leagă" cu cea mai mică carte din
                combinație: "calu"
              </div>
            </li>
            <li class="list-group-item d-flex justify-content-between align-items-start">
              <div class="ms-2 me-auto">
                <div class="fw-bold">Patru' tuzu'</div> o combinație cu trei filcăi de orice culoare plus o carte de
                "tuz", din care în
                combinație cu filcăii, rezultă trei tromfi = "legătura patru" (filcăii și "tuz'u" care toți sunt
                considerați de aceeași culoare - chiar dacă filcăii pot avea culori diferite față de "tuz"), plus se
                "leagă" cu cea mai mică carte din combinație: "tuz"
              </div>
            </li>
          </ol>
          <p>În legăturile "tri" sau "patru" combinațiile pot fi făcute și cu mai mulți filcăi (doi sau trei), în
            funcție de posibilitatea de a "lega" cât mai sus și deci de a câștiga începerea jocului.</p>
          <p>Legătura în "doi" (un filcău și restul cărtilor de patru culori diferite), nu este admisă în acest caz,
            jucătorul va declara dacă este cazul "doi".</p>
          <p>Legătura în patru cu "tuz foarbă" (filcău sau filcăi în total cu patru tromfi de o culoare + "tuz" de
            alta culoare) este interzisă în acest caz, jucătorul va declara dacă este cazul "închis".</p>
          <p>Legătura în cinci (filcău sau filcăi + toate celelate cărți de aceeași culoare) este interzisă în acest
            caz, jucătorul va declara dacă este cazul "închis".</p>
          <p>La legarea tromfului este permisă întrebarea între coechipieri "ai filcău?" Răspunsul poate fi numai
            "da" sau "nu", orice alta întrebare și/sau variantă de răspuns se consideră "ciolit" și se sancționează
            cu o "hudă".</p>
          <p>Dacă una dintre echipe, nu are niciun filcău, în această situație se va reface jocul, cu același dealer.
          </p>
        </div>
      </div>

      <div class="m-2">
        <h5 class="expanding-header" (click)="toggleTab(5)">Jocul<i class="fa"
            [ngClass]="openedTabs[5] ? 'fa-angle-up' : 'fa-angle-down'"></i></h5>
        <div *ngIf="openedTabs[5]" class="tab-content">
          <p>Jocul se joacă în patru jucători împărțiți în două echipe, așezate în cruce, alese aleator sau prin
            agreere individuală, cu câte 5 cărți la fiecare jucător.
            Cărțile sunt de patru culori: ghindă, verde, roșu și dâbă. Pentru fiecare culoare, cărțile, sunt:
            "arșeu", "filcău", "cal", "crăiță" și "tuz". Vezi exemplul pentru ghindă:</p>
          <div class="flex-wrap" fxLayout="row">
            <div *ngFor="let card of cardPositions; let i = index" fxFlex class="example-card" matRipple
              (click)="openAlbumImage(cardPositions, i)">
              <img class="card-icon" mat-card-image [src]="card.thumb">
              <div class="card-subtitle" [innerHTML]="card.caption"></div>
            </div>
          </div>
          <p>Cărțile sunt de tromf (o culoare aleasă și stabilită pentru un joc) și normale. Ordinea descrescătoare
            după valoarea cărților (indiferent de culoarea tromfului) este:
            "filcău' de ghindă", "filcău' de verde", "filcău' de roșu", "filcău' de dâbă", (care în orice situație
            sunt și tromfi, indiferent de ce culoare este ales tromful), "tuz" de tromf, "crăiță" de tromf, "cal" de
            tromf, "arșeu" de tromf, apoi celalalte cărți, care nu sunt de culoarea tromfului, în ordinea, "tuz",
            "crăiță", "cal", "arșeu".</p>
          <p>"Tuz'u" și "crăița", valorează câte un punct, celalalte cărți, indiferent de valoarea lor și/sau de
            faptul că sunt sau nu de culoarea tromfului, nu valorează nimic.
            Scopul echipelor este să ducă cât mai multe puncte (adică cărți de "tuz" și de "crăiță"). Se pot duce
            maxim 8 puncte (4 de "tuz" și 4 de "crăiță").</p>
          <p>Echipa (prin jucătorul) care a câștigat "legătura" va începe jocul. Jucătorul va da jos un filcău din
            "legătură" și va anunța culoarea tromfului. Ceilalți jucători vor da în ordine tromf sau filcău.
            Jucătorul care va duce mâna, va da în continuare cu "foarbă", obigatoriu. Ceilalți jucători vor da în
            ordine "la culoare". Cartea ce mai mare duce mâna. Dacă vreun jucător nu are "la culoare", va da
            obligatoriu tromf (filcău sau culoare de tromf). Mâna o va duce cel mai mare tromf sau filcău. Dacă
            vreun jucător nu are "la culoare" și nu are nici tromf, poate să dea orice carte. Jucatorul care duce
            mana va da la inceputul turei urmatoare "foarba", iar în cazul în care nu mai are "foarbe" va putea juca
            tromfii.</p>
          <p>După ce se joacă toate cărțile, se numără din mâinile duse, punctele ("crăiță" și "tuz") și se continuă
            jucarea următorului joc , în funcție de scorul jocului.
            Jocul poate continua în formula "pă rându' satului" când nicio echipă nu a reușit să bage "slugă" pe
            cealaltă echipă (fiecare echipă a dus minim trei puncte), sau în formula "avem slugă", când o echipă
            este bagată "slugă" și prin același jucător "face cărțile de șase", până când iese din acestă stare.</p>
          <p>Ieșirea din "slugărit" se poate face prin trecerea la continuarea jocului în formula "pă rându' satului"
            caz în care echipa trebuie să duca într-un joc minim șase puncte, dar și cealaltă echipă să ducă cel
            puțin o mâna, sau prin câștigarea jocului cu "hudă", caz în care cealaltă echipă intră automat de
            "slugă".</p>
        </div>

      </div>

      <div class="m-2">
        <h5 class="expanding-header" (click)="toggleTab(6)">Clasificare și clasament<i class="fa"
            [ngClass]="openedTabs[6] ? 'fa-angle-up' : 'fa-angle-down'"></i></h5>
        <div *ngIf="openedTabs[6]" class="tab-content">
          <p>Dacă o echipă duce la sfârșitul unui joc, 3, 4 sau 5 pucte (și deci cealaltă echipă duce, 5, 4, respectiv 3
            puncte),
            rezultă că jocul s-a terminat indecis și se declară
            "pă rându' satului", iar dealerul următor este stabilit cel care în ordinea acelor de ceasornic este
            următorul față de cel care a "făcut cărțile" și va da cărțile pentru un nou joc.</p>
          <p>Dacă o echipă duce la sfârșitul unui joc 6, 7 sau 8 puncte, cu condiția ca și cealaltă echipă să
            ducă cărți care sunt fără valoare (și deci cealaltă echipa duce 2, 1,
            respectiv 0 puncte), rezultă că jocul s-a terminat, iar echipa care a dus cel mult 2 puncte, este
            declarată "slugă" și dealerul pentru jocul următor, va fi din această echipă. Acest dealer, este
            următorul în ordinea acelor de ceasornic, dacă dealerul jocului anterior a fost din echipa câștigatoare, iar
            dacă nu și dealerul jocului anterior a fost din echipa care tocmai a pierdut,
            se declară "sare" iar dealerul va fi, celălalt jucător din echipa pierzătoare. Acest dealer odată stabilit,
            va "face cărțile" până când echipa lui va ieși din stadiul de "slugă". Acest lucru se va întâmpla dacă
            echipa lui va câștiga un joc</p>
          <p>Dacă o echipă duce la sfârșitul unui joc, toate cărțile (inclusiv și toate cele 8 puncte), iar cealaltă
            echipă, nu duce nicio carte, rezultă că jocul s-a terminat și se declară "hudă", iar echipa câștigătoare
            înscrie 1 punct pe tabela de scor. În acestă situație, echipa pierzătoare, este declarată automat
            "slugă", iar dealerul va fi din această echipă, stabilit după regula de mai sus.</p>
          <p>Dacă o echipă duce la sfârșitul unui joc, pe "legătura" celeilalte echipe, toate cărțile (inclusiv cele 8
            puncte),
            iar cealaltă echipă nu duce nicio carte, rezultă că jocul s-a terminat și se
            declară "hudă legată", iar echipa câștigătoare înscrie 2 puncte pe tabela de scor. În acestă situație,
            echipa pierzătoare, este declarată automat "slugă", iar dealerul va fi din această echipă, stabilit după
            regula de mai sus.</p>
        </div>
      </div>

      <div class="m-2">
        <h5 class="expanding-header" (click)="toggleTab(7)">Reguli specifice<i class="fa"
            [ngClass]="openedTabs[7] ? 'fa-angle-up' : 'fa-angle-down'"></i></h5>
        <div *ngIf="openedTabs[7]" class="tab-content">
          <p>Jocul de "friș" nu este obligatoriu, pentru jucătorul care deține combinația posibilă din cele trei
            cărți.</p>
          <p>
            În cazul în care un jucător face "friș" iar un alt jucător care trebuie să dea când îi vine rândul o carte
            și are în mână trei cărți toate de tip punct ("tuz" sau "crăiță"), poate da una dintre cărți și declară
            "pentru alta". După terminarea jocului de "friș", dacă culoarea cărții tip punct ("tuz" sau "crăiță"),
            dată, are culoare tromfului declarat, atunci, jucătorul în cauză care a dat cartea, poate cere înlocuirea
            ei cu o altă carte de tip punct din mână. Acest lucru trebuie făcut înaintea împarțirii de către dealer a
            restului de două cărți.</p>
          <p>În cazul în care de exemplu, jocul se desfășoară normal și nicio echipă nu este băgată "slugă", iar
            una dintre echipe a acumulat trei puncte, dar consideră pe baza cărților din mână dar mai ales pe
            baza cărților jucate, că nu mai are șanse să acumuleze alte puncte, poate cere oprirea jocului prin
            declararea "mere' pă rându' satului", caz în care, dacă și echipa adversă este de acord, jocul se
            oprește și se începe un nou joc, în secvența "pă rându' satului", iar dealerul este următorul la rând.
            În cazul în care de exemplu, jocul se desfășoară cu o echipă "slugă", iar la un moment dat, această
            echipă a acumulat minim o mână (pentru a evita "huda"), dar consideră pe baza cărților din mână
            dar mai ales pe baza cărților jucate, că nu mai are șanse să acumuleze minim șase puncte pentru a
            ieși din "slugărit", poate cere oprirea jocului prin declararea "dă' să le fac de șase", caz în care, dacă
            și echipa adversă este de acord, jocul se oprește și se începe un nou joc, în secvența cu echipa
            "slugă", iar dealerul este tot cel care a fost și în jocul tocmai oprit.</p>
          <p>Dacă se suspectează că se declară "pentru alta", abuziv sau incorect, echipa dovedită că a declarat
            "pentru alta" incorect este sancționată cu "hudă".</p>
          <p>Între jucătorii aceleiași echipe sunt interzise orice semne de comunicare care pot conduce la
            indicații de tipul ce să se dea jos, ce culoare, cât de mare, tromf sau nu, etc. Echipa surpinsă cu
            semne făcute între coechipieri, se consideră ca a "ciolit" și se sancționează cu o "hudă". Două
            "hude" luate de o echipă pe considerente de "ciolit" este declarată automat pierzătoare a meciului și
            nu mai are voie să joace în aceeași formulă de jucători.</p>
          <p>Jocul de filcău se poate termina oricănd la dorința echipelor. Echipa care a acumulat cele mai multe
            puncte (1 punct = o "hudă", 2 puncte = "hudă legată"), câștigă meciul. Meciul se termină oricum,
            când o echipă acumulează 3 puncte și deci este declarată câștigătoare.</p>
        </div>

      </div>
    </div>
  </div>
</div>