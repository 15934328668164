import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core';
import { MatBottomSheetRef, MAT_BOTTOM_SHEET_DATA } from '@angular/material/bottom-sheet';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AdminResult } from 'src/app/common/admin-actions.enum';
import { ConfirmDialogComponent } from 'src/app/game/dialogs/confirm-dialog/confirm-dialog.component';
import { AdminService } from 'src/core/services/admin.service';

@Component({
  selector: 'app-admin-actions',
  templateUrl: './admin-actions.component.html',
  styleUrls: ['./admin-actions.component.scss']
})
export class AdminActionsComponent implements OnInit {

  @Output() reloadData = new EventEmitter<undefined>()

  constructor(
    @Inject(MAT_BOTTOM_SHEET_DATA) public readonly data: { locked: boolean, confirmed: boolean },
    private readonly bottomSheetRef: MatBottomSheetRef<AdminActionsComponent>,
  ) { }

  ngOnInit(): void {
  }

  block(event): void {
    this.bottomSheetRef.dismiss(AdminResult.Block);
  }

  delete(event): void {
    this.bottomSheetRef.dismiss(AdminResult.Delete);
  }

  confirm(event): void {
    if (!this.data.confirmed) {
      this.bottomSheetRef.dismiss(AdminResult.Confirm);
    }
  }
}
