import { AppPlayer } from '../app-player.model';

export class GameScore {
  private selfScoreValue: TeamScore = new TeamScore();
  private opponentScoreValue: TeamScore = new TeamScore();

  public setScore(player: AppPlayer, score: number, scoreType: ScoreType, isSelf: boolean): void {
    if (isSelf) {
      this.selfScoreValue.setTeam(player, true);
      this.selfScoreValue.addScore(score, scoreType);
    } else {
      this.opponentScoreValue.setTeam(player, false);
      this.opponentScoreValue.addScore(score, scoreType);
    }
  }

  public get team1Score(): number {
    return this.selfScoreValue.team === TeamNumber.Team1 ? this.selfScoreValue.score : this.opponentScoreValue.score;
  }

  public get team2Score(): number {
    return this.selfScoreValue.team === TeamNumber.Team2 ? this.selfScoreValue.score : this.opponentScoreValue.score;
  }

  public get selfScore(): number {
    return this.selfScoreValue.score;
  }

  public get opponentScore(): number {
    return this.opponentScoreValue.score;
  }

  public get selfScoreType(): ScoreType {
    return this.selfScoreValue.scoreType;
  }

  public get opponentScoreType(): ScoreType {
    return this.opponentScoreValue.scoreType;
  }
}

export class MatchScore {
  public selfWonGames: number;
  public opponentWonGames: number;
}

export class TeamScore {
  private isSelfValue: boolean;
  private scoreValue: number = 0;
  private scoreTypeValue: ScoreType;
  private teamValue: TeamNumber;
  private teamIdValue: string;

  public setTeam(player: AppPlayer, isSelf: boolean): void {
    this.isSelfValue = isSelf;
    this.teamValue = player.tablePosition % 2 === 1 ? TeamNumber.Team1 : TeamNumber.Team2;
    this.teamIdValue = player.teamId;
  }

  public addScore(score: number, scoreType: ScoreType): void {
    this.scoreValue += score;
    this.scoreTypeValue = scoreType;
  }

  public get score(): number {
    return this.scoreValue;
  }

  public get isSelf(): boolean {
    return this.isSelfValue;
  }

  public get team(): TeamNumber {
    return this.teamValue;
  }

  public get teamId(): string {
    return this.teamIdValue;
  }

  public get scoreType(): ScoreType {
    return this.scoreTypeValue;
  }
}

export enum TeamNumber {
  Team1 = 1,
  Team2 = 2
}

export enum ScoreType {
  Invalid = 0,
  PaRanduSatului = 1,
  Sluga = 2,
  Huda = 4,
  HudaLegata = 8,
  NormalLoss = 16,
  Winner = 32,
  Tie = 64,
  Abandon = 128,

  Losses = Abandon | NormalLoss,
  Wins = Winner,
  Ties = Tie
}
