import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { PAGES } from 'src/app/common/constants';
import { AuthService } from 'src/core/services/auth.service';

@Component({
  selector: 'app-resend-confirmation',
  templateUrl: './resend-confirmation.component.html',
  styleUrls: ['./resend-confirmation.component.scss']
})
export class ResendConfirmationComponent implements OnInit, OnDestroy {
  public pageName: PAGES = PAGES.ResendConfirmationPage;

  errorDescription: string;
  resendConfirmationMail: FormGroup
  isSubmitted: boolean;

  private subscriptions: Subscription;

  constructor(private authService: AuthService, private toastrService: ToastrService, private router: Router) { }

  ngOnInit() {
    this.subscriptions = new Subscription();
    this.resendConfirmationMail = new FormGroup({
      email: new FormControl("", Validators.email),
    });
  }

  onResendConfirmation() {
    if (this.resendConfirmationMail.valid) {
      this.isSubmitted = true;

      const email = this.resendConfirmationMail.controls.email.value;

      this.subscriptions.add(this.authService.resendConfirmationEmail(email).subscribe(
        () => {
          this.isSubmitted = false;
          this.toastrService.success("Verfica-ti emailul pentru a confirma contul!");
          this.router.navigate(['/login']);
        },
        () => {
          this.isSubmitted = false;
        }
      ));
    } else {
      this.resendConfirmationMail.markAllAsTouched();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
