import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'userStatus',
    pure: false
})
export class UserStatusPipe implements PipeTransform {
    transform(userId: string, status: Array<string>): boolean {
        return status.includes(userId);
    }

}