import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PAGES } from 'src/app/common/constants';

@Component({
  selector: 'app-confirm-account',
  templateUrl: './confirm-account.component.html',
  styleUrls: ['./confirm-account.component.scss']
})
export class ConfirmAccountComponent implements OnInit, OnDestroy {
  public pageName: PAGES = PAGES.AccountConfirmationPage;

  readonly text = "Emailul de confirmare a fost trimis! Te rugăm verifică-ți emailul pentru confirmarea contului.";

  private timeout: NodeJS.Timeout;

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.timeout = setTimeout(() => {
      this.router.navigate(['/profile']);
    }, 5000)
  }

  ngOnDestroy(): void {
    clearTimeout(this.timeout);
  }
}
