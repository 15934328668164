import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { PAGES } from 'src/app/common/constants';
import { AuthService } from 'src/core/services/auth.service';

@Component({
  selector: 'app-account-confirming',
  templateUrl: './account-confirming.component.html',
  styleUrls: ['./account-confirming.component.scss']
})
export class AccountConfirmingComponent implements OnInit, OnDestroy {
  public pageName: PAGES = PAGES.AccountConfirmingPage;

  private utoken: string = '';
  private ctoken: string = '';
  private subscriptions: Subscription = new Subscription();

  private readonly utokenName: string = 'utoken';
  private readonly ctokenName: string = 'ctoken';

  confirmationSuccessful: boolean = undefined;
  confirmationText: string = '';

  constructor(
    private readonly toastr: ToastrService,
    private readonly route: ActivatedRoute,
    private readonly spinner: NgxSpinnerService,
    private readonly authService: AuthService,
  ) { }

  ngOnInit(): void {
    this.utoken = this.route.snapshot.queryParamMap.get(this.utokenName);
    this.ctoken = this.route.snapshot.queryParamMap.get(this.ctokenName);
    if (!this.utoken || !this.ctoken) {
      this.toastr.error('Link-ul folosit nu este corect!');
      return;
    }
    this.spinner.show();
    this.subscriptions.add(this.authService.confirmAccount(this.utoken, this.ctoken).subscribe(
      () => {
        this.confirmationSuccessful = true;
        this.confirmationText = 'Contul a fost activat'
        this.spinner.hide();
      },
      () => {
        this.confirmationSuccessful = false;
        this.confirmationText = 'A aparut o eroare'
        this.spinner.hide();
      }
    ));
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}