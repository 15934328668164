import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { AppPlayer } from 'src/core/models/app-player.model';
import { MatchScore } from 'src/core/models/game/score.model';
import { Team } from 'src/core/models/team.model';

export interface MatchEndDialogData {
  score: MatchScore;
  player: AppPlayer;
  team: Team;
  teamIndex: 1 | 2;
}

@Component({
  selector: 'app-match-ended-dialog',
  templateUrl: './match-ended-dialog.component.html',
  styleUrls: ['./match-ended-dialog.component.scss']
})
export class MatchEndedDialogComponent {

  public isClosing$: Observable<boolean>;

  constructor(public dialogRef: MatDialogRef<MatchEndedDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: MatchEndDialogData) {
    this.isClosing$ = this.dialogRef.beforeClosed().pipe(mapTo(true));
    this.dialogRef.disableClose = true;
    this.dialogRef.updateSize('60vmin', '250px');
  }

  public get matchResult(): string {
    if (this.data.score.selfWonGames < this.data.score.opponentWonGames) {
      return `Echipa ${this.data.teamIndex} (${this.data.team.player1.username} + ${this.data.team.player2.username}) a pierdut meciul!`;
    } else if (this.data.score.selfWonGames > this.data.score.opponentWonGames) {
      return `Echipa ${this.data.teamIndex} (${this.data.team.player1.username} + ${this.data.team.player2.username}) a castigat meciul!`;
    } else {
      return `Echipa ${this.data.teamIndex} (${this.data.team.player1.username} + ${this.data.team.player2.username}) a remizat!`;
    }
  }

}
