export class User {
  public id: string;
  public username: string;
  public name: string;
  public surname: string;
  public email: string;
  public avatarUrl: string

  constructor(id?: string, username?: string, name?: string, surname?: string, email?: string, avatarUrl?: string) {
    this.id = id;
    this.username = username;
    this.name = name;
    this.surname = surname;
    this.email = email;
    this.avatarUrl = avatarUrl;
  }
}
