<form class="bg-white auth-form" [formGroup]="loginForm" (submit)="onLogin()" novalidate>
  <h1 class="login-title">LOGIN</h1>

  <div class="form-group login-controls ">
    <input class="form-control login-input" type="text" placeholder="Nume de utilizator"  formControlName="username" required />
    <div *ngIf="loginForm.get('username').invalid && (loginForm.get('username').dirty || loginForm.get('username').touched)" >
      <div *ngIf="loginForm.get('username').errors.required" class="error">Username-ul este obligatoriu</div>
    </div>
  </div >

  <div class="form-group login-controls">
    <input class="form-control login-input" type="password" placeholder="Parola"  formControlName="password" required/>
    <div *ngIf="loginForm.get('password').invalid && (loginForm.get('password').dirty || loginForm.get('password').touched)">
      <div *ngIf="loginForm.get('password').errors.required" class="error">Parola este obligatorie</div>
    </div>
  </div >

  <div class="login-controls" *ngIf="errorDescription" >
    <p class="error" >{{ errorDescription }}</p>
  </div >

  <button class="login-button" type="submit" [disabled]="isSubmitted">
    <span *ngIf="!isSubmitted">
      Autentificare
    </span>
    <span *ngIf="isSubmitted">
      <i class="fas fa-spinner fa-pulse"></i>
    </span>
  </button>
  <p class="login-text">Nu ești înregistrat? <a class="login-anchor" routerLink="/register">Crează-ți un cont</a></p>
  <p class="login-text">Ai uitat parola? <a class="login-anchor" routerLink="/forgot-password">Recupereaz-o aici</a></p>
  <p class="login-text">Retrimite emailul de confirmare? <a class="login-anchor" routerLink="/resend-confirmation">Retrimite aici</a></p>
</form>