import { Injectable } from "@angular/core";
import {
    HttpClient,
} from "@angular/common/http";
import { User } from '../models/user.model';
import { BehaviorSubject, Observable } from 'rxjs';
import { isStringNullOrEmpty } from '../../app/utils/helper.functions';
import { LogService } from './log.service';
import { BaseService } from "./base.service";
import { LocalStorageIndex } from "src/app/common/constants";
import { Roles } from "src/app/common/permissions.enum";

@Injectable({
    providedIn: 'root'
})
export class UserService extends BaseService {

    protected _currentUser: BehaviorSubject<User>;
    private initialized: boolean;

    constructor(http: HttpClient, logService: LogService) {
        super(http, logService);

        const storedUser = localStorage.getItem(LocalStorageIndex.CURRENT_USER);
        
        this._currentUser = !isStringNullOrEmpty(storedUser)
            ? new BehaviorSubject<User>(JSON.parse(storedUser))
            : new BehaviorSubject<User>(null);
    }


    public set currentUser(updateUser: User) {
        this.setLocalStorage(LocalStorageIndex.CURRENT_USER, JSON.stringify(updateUser));
        this._currentUser.next(updateUser);
    }

    public get currentUser(): User {
        return this._currentUser.value;
    }

    public get currentUser$(): Observable<User> {
        return this._currentUser;
    }

    public get userRoles(): Roles[] {
        return this.decodedToken?.role;
    }

    public get isUserInitialized(): boolean {
        return this.initialized;
    }

    public markUserInitialized(): void {
        this.initialized = true;
    }
}
