import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { BaseService } from '../core/services/base.service';
import { AuthService } from '../core/services/auth.service';
import { ProfileService } from '../core/services/profile.service';
import { AuthenticationModule } from './authentication/authentication.module';
import { MainGameComponent } from './game/main-game/main-game.component';
import { GameModule } from './game/game.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SelectablePreloadingStrategy } from './common/selectable-preloading-strategy';
import { ErrorHandlerModule } from 'src/core/errors/error-handler.module';
import { LoaderService } from '../core/services/loader.service';
import { MainModule } from './main/main.module';
import { MatchService } from '../core/services/match.service';
import { UserService } from 'src/core/services/user.service';
import { RouteExtService } from 'src/core/services/route-ext.service';
import { AdminService } from 'src/core/services/admin.service';


const FilcauModules = [
  AuthenticationModule,
  SharedModule,
  GameModule,
  MainModule,
  ErrorHandlerModule,
]

const FilcauPages = [
  MainGameComponent,
]

const FilcauService = [
  BaseService,
  AuthService,
  ProfileService,
  LoaderService,
  MatchService,
  UserService,
  AdminService,
  SelectablePreloadingStrategy
]
@NgModule({
  declarations: [
    AppComponent,
    ...FilcauPages
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    ...FilcauModules,
  ],
  providers: [
    ...FilcauService
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(routeExtService: RouteExtService) {

  }
}