export enum BateTaie {
    Bate,
    Taie
}

export enum TromfCombination {
    Empty = 0,
    Pas = 1,
    Inchis = 2,
    Doi = 4,
    Buna = 8,
    EmptyCombinations = Pas | Inchis | Doi | Buna,
    TriArseu = 16,
    TriCalu = 32,
    TriCraita = 64,
    TriTuzu = 128,
    PatruArseu = 256,
    PatruCalu = 512,
    PatruTuzu = 1024,
    PatruCraita = 2048
}

export class TromfCombinationsUtils {

    public static isEmpty(tromfComb: TromfCombination): boolean {
        if (tromfComb === undefined || tromfComb === null) {
            return true;
        }
        return !!(tromfComb & TromfCombination.EmptyCombinations) || (tromfComb === TromfCombination.Empty);
    }

    // mixed meaning both empty and non empty combinations
    public static isMixedCombination(tromfComb: TromfCombination): boolean {
        return this.isEmpty(tromfComb) && !!(tromfComb & (~TromfCombination.EmptyCombinations));
    }

    public static stripEmptyCombination(tromfComb: TromfCombination): TromfCombination {
        return tromfComb & (~TromfCombination.EmptyCombinations);
    }

    public static stripIfMixedCombination(tromfComb: TromfCombination): TromfCombination {
        return TromfCombinationsUtils.isMixedCombination(tromfComb) ? TromfCombinationsUtils.stripEmptyCombination(tromfComb) : tromfComb;
    }
}
