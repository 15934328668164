import { Component, OnInit } from "@angular/core";
import { ResetPasswordModel } from "../../../../core/models/reset-password.model";
import { AuthService } from "src/core/services/auth.service"
import { MustMatch } from "src/app/utils/form.validators";
import { ToastrService } from "ngx-toastr";
import { ActivatedRoute } from "@angular/router";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { PAGES } from "src/app/common/constants";

@Component({
  selector: "app-reset-password",
  templateUrl: "./reset-password.component.html",
  styleUrls: ["./reset-password.component.scss",]
})
export class ResetPasswordComponent implements OnInit {
  public pageName: PAGES = PAGES.ResetPasswordPage;

  resetPasswordModel: ResetPasswordModel;
  resetPasswordForm: FormGroup;
  isSubmitted: boolean;
  containsErrors: boolean;
  disabled: boolean;
  showConfirmation: boolean;

  constructor(
    private readonly authService: AuthService,
    private readonly route: ActivatedRoute,
    private readonly formBuilder: FormBuilder,
    private readonly toastr: ToastrService,
  ) { }

  ngOnInit() {
    this.resetPasswordModel = new ResetPasswordModel();
    this.resetPasswordModel.rtoken = this.route.snapshot.queryParamMap.get('rtoken');
    this.resetPasswordModel.utoken = this.route.snapshot.queryParamMap.get('utoken');

    this.resetPasswordForm = this.formBuilder.group({
      password: new FormControl(this.resetPasswordModel.password, Validators.required),
      confirmPassword: new FormControl(this.resetPasswordModel.password, Validators.required),
    },
      {
        validator: MustMatch('password', 'confirmPassword')
      }
    );
    if (!this.resetPasswordModel.rtoken || !this.resetPasswordModel.utoken) {
      this.toastr.error('Link-ul folosit nu este corect!');
      return;
    }
  }

  onResetPassword() {
    if (this.resetPasswordForm.valid || !(!this.resetPasswordModel.rtoken || !this.resetPasswordModel.utoken)) {

      this.resetPasswordModel.password = this.resetPasswordForm.controls.password.value;
      this.resetPasswordModel.confirmPassword = this.resetPasswordForm.controls.confirmPassword.value;

      this.authService.resetPassword(this.resetPasswordModel)
        .subscribe(
          () => {
            this.showConfirmation = true;
            this.isSubmitted = false;
          },
          () => {
            this.isSubmitted = false;
          }
        );
      this.isSubmitted = true;
    } else {
      if (!this.resetPasswordModel.rtoken || !this.resetPasswordModel.utoken) {
        this.toastr.error('Link-ul folosit nu este corect!');
      } else {
        this.resetPasswordForm.markAllAsTouched();
      }
    }
  }
}
