import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/core/services/auth.service';
import { LoginModel } from '../../../../core/models/login.model';
import { ANONYMOUS_USER_ID, PAGES } from 'src/app/common/constants';
import { ActivatedRoute, Router } from '@angular/router';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public pageName: PAGES = PAGES.LoginPage;

  loginModel: LoginModel;
  errorDescription: string;
  loginForm: FormGroup;
  isSubmitted: boolean;

  constructor(
    private authService: AuthService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.loginModel = new LoginModel();
  }

  ngOnInit() {
    this.loginForm = new FormGroup({
      username: new FormControl(this.loginModel.username, Validators.required),
      password: new FormControl(this.loginModel.password, Validators.required)
    })
  }

  onLogin() {
    if (this.loginForm.valid) {

      this.loginModel.username = this.loginForm.controls.username.value;
      this.loginModel.password = this.loginForm.controls.password.value;

      this.isSubmitted = true;

      this.authService.login(this.loginModel).subscribe(
        res => {
          this.router.navigate(["/profile"]);
          this.isSubmitted = false;
          localStorage.removeItem(ANONYMOUS_USER_ID);
        },
        err => {
          this.isSubmitted = false;
        }
      );
    } else {
      this.loginForm.markAllAsTouched();
    }
  }
}
