import { Component, OnInit, OnDestroy } from '@angular/core';
import { GameService } from '../../../core/services/game.service';
import { Position } from '../../../core/models/generic.model';
import { trigger, state, transition, animate, style } from '@angular/animations';
import { Subscription } from 'rxjs';
import { DialogModel } from 'src/core/models/game/dialog.model';

@Component({
  selector: 'app-seat',
  templateUrl: './seat.component.html'
})
export class SeatComponent implements OnInit, OnDestroy {

  public dialogState: string = 'hide';
  public dialogTimeout: number = 5000;
  public dialogText: string = '';
  public tablePosition: number = -1;
  public arrayPosition: number = -1;

  protected position: Position;
  protected showing: boolean = false;
  protected waiting: boolean = false;
  protected subscriptions: Subscription;

  private tempDialogText: string = '';
  private timeout: NodeJS.Timeout;

  constructor(public gameService: GameService) {
  }

  ngOnInit(): void {
    if (this.tablePosition === -1) {
      this.tablePosition = this.gameService.getServerPositionFromLocalPosition(this.position);
      this.arrayPosition = this.gameService.getArrayPositionFromLocalPosition(this.position);
    }
    this.subscriptions = new Subscription();
    this.subscriptions.add(this.gameService.dialogReceived.subscribe((data: DialogModel) => {
      if (data.position === this.tablePosition) {
        this.dialogReceived(data.message, data.withTimeout);
      } else if (data.position === (-1 * this.tablePosition) || isNaN(data.position)) {
        if (data.withTimeout && !this.timeout) {
          this.timeout = setTimeout(() => {
            this.dialogTimedOut();
          }, this.dialogTimeout);
        } else {
          this.dialogTimedOut();
        }
      }
    }));
    this.subscriptions.add(this.gameService.waitingPlayerInput.subscribe(data => {
      this.waitingForPlayerInput(data.tablePosition, data.isWaiting);
    }));
  }

  protected dialogAnimationStart(event) {
    if (event.fromState === 'hide' && event.toState === 'display') {
      this.showing = true;
    }
  }

  protected waitingForPlayerInput(tablePosition: number, isWaiting: boolean): void {
    if (tablePosition === this.tablePosition || tablePosition === -1) {
      this.waiting = isWaiting;
    }
  }

  protected get waitingDisplay(): boolean {
    return this.waiting;
  }

  protected dialogAnimationDone(event) {
    if (event.toState === 'hide' && event.fromState === 'display') {
      this.showing = false;
      if (this.dialogText !== this.tempDialogText) {
        this.dialogText = this.tempDialogText;
        this.dialogState = 'display';
        clearTimeout(this.timeout);
        this.timeout = setTimeout(function () {
          this.dialogTimedOut();
        }.bind(this), this.dialogTimeout);
      }
    }
  }

  public dialogReceived(message: string, withTimeout: boolean) {
    this.tempDialogText = message;
    if (!this.showing) {
      this.dialogText = message;
      this.dialogState = 'display';
      clearTimeout(this.timeout);
      this.timeout = undefined;
      if (withTimeout) {
        this.timeout = setTimeout(function () {
          this.dialogTimedOut();
        }.bind(this), this.dialogTimeout);
      }
    } else {
      this.dialogState = 'hide';
    }
  }

  public dialogTimedOut(): void {
    this.dialogState = 'hide';
  }

  public static popupDialog() {
    return trigger('popupDialogAnimation', [
      state('display', style({
        opacity: '1'
      })),
      state('hide', style({
        opacity: '0'
      })),
      transition('hide => display', [
        animate('0.25s')
      ]),
      transition('display => hide', [
        animate('0.5s')
      ]),
    ]);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}

export function popupDialogAnimation() {
  return SeatComponent.popupDialog();
}
