<app-status-header>
</app-status-header>
<div class="game-area">
  <div class="loading" *ngIf="isWaiting || !gameStarted">Loading&#8230;
    <div class="loader"></div>
  </div>
  <app-game-timer *ngIf="timerDisplayed" [timer]="gameService.timer">
  </app-game-timer>
  <main>
    <app-card-table>
    </app-card-table>
    <app-opponent-seat-west *ngIf="gameService.getAllPlayers()[1] != undefined">
    </app-opponent-seat-west>
    <app-opponent-seat-north *ngIf="gameService.getAllPlayers()[2] != undefined">
    </app-opponent-seat-north>
    <app-opponent-seat-east *ngIf="gameService.getAllPlayers()[3] != undefined">
    </app-opponent-seat-east>
    <app-player-seat *ngIf="gameService.getAllPlayers()[0] != undefined"></app-player-seat>
  </main>
  <footer>
  </footer>
</div>
<app-draggable-link></app-draggable-link>