<ng-container *ngIf="!(isClosing$ | async)">
  <h1 mat-dialog-title>{{data.title}}</h1>
  <div mat-dialog-content>
    <p>
      {{data.description}}
    </p>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="this.dialogRef.close()" cdkFocusInitial>{{data.okString}}</button>
  </div>
</ng-container>