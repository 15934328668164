import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { NotFoundPageComponent } from './main/pages/not-found-page/not-found-page.component';
import { SelectablePreloadingStrategy } from './common/selectable-preloading-strategy';
import { MainLayoutComponent } from "./main/layout/main-layout/main-layout.component";
import { AuthenticationLayoutComponent } from "./authentication/layout/authentication-layout/authentication-layout.component";


const routes: Routes = [
  { path: "", component: MainLayoutComponent, loadChildren: () => import('./main/main.module').then(m => m.MainModule) },
  { path: "", component: AuthenticationLayoutComponent, loadChildren: () => import('./authentication/authentication.module').then(m => m.AuthenticationModule) },
  { path: "joc", loadChildren: () => import('./game/game.module').then(m => m.GameModule), data: { preload: true } },
  { path: "**", component: NotFoundPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: SelectablePreloadingStrategy })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
