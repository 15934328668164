<img
  [src]="user?.avatarUrl ? user.avatarUrl : 'assets/images/img_avatar.png'"
  alt="Avatar" class="avatar" width="50" height="50" />

<div class="dropdown" style="display: inline-block">
  <a [ngClass]="['nav-link', 'dropdown-toggle', textColor]" href="#" id="navbarDropdown" role="button"
    data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
    {{ user.username }}
  </a>
  <div class="dropdown-menu dropdown-menu-right" aria-labelledby="navbarDropdown">
    <a *ngIf="menuItems.length" class="dropdown-item" [routerLink]="menuItems[0].route">{{menuItems[0].label}}</a>
    <div *ngIf="menuItems.length" class="dropdown-divider"></div>
    <a class="dropdown-item" (click)="onLogout()">Log out</a>
  </div>
</div>