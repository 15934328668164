import { AfterViewInit, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { UserDetails } from 'src/core/models/user-details.model';
import { AdminService } from 'src/core/services/admin.service';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { AdminActionsComponent } from '../../components/admin-actions/admin-actions.component';
import { of } from 'rxjs';
import { exhaustMap } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/game/dialogs/confirm-dialog/confirm-dialog.component';
import { AdminResult } from 'src/app/common/admin-actions.enum';
import { PAGES } from 'src/app/common/constants';
import { OnlineService } from 'src/core/services/online.service';

@Component({
  selector: 'app-admin-page',
  templateUrl: './admin-page.component.html',
  styleUrls: ['./admin-page.component.scss']
})
export class AdminPageComponent implements AfterViewInit {
  public pageName: PAGES = PAGES.AdminPage;

  dataSource: MatTableDataSource<UserDetails> = new MatTableDataSource([]);
  columns: Array<String> = [
    "status",
    "username",
    "surname",
    "name",
    "email",
    "phoneNumber",
    "registrationTime",
    "lastLoginTime",
    "emailConfirmed",
    "lockoutEnabled",
    "isAdmin",
    "actions",
  ]
  length: number = 0;
  pageSize: number = 20;
  pageIndex: number = 0;
  pageSizeOptions = [5, 10, 20, 50]
  onlineUsers = [];

  constructor(
    private readonly dialog: MatDialog,
    private adminService: AdminService,
    private buttonSheet: MatBottomSheet,
    private onlineService: OnlineService,
  ) { }


  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator;
  }

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;

  ngOnInit(): void {
    this.loadData();
  }

  onPageChange(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;

    this.loadData();
  }

  onActions(user: UserDetails) {
    this.buttonSheet.open(AdminActionsComponent, { data: { locked: this.isUserLocked(user), confirmed: user.emailConfirmed } })
      .afterDismissed()
      .subscribe((res: AdminResult) => {
        switch (res) {
          case AdminResult.Block:
            this.blockUser(user)
            break;
          case AdminResult.Confirm:
            this.confirmUser(user.id)
            break;
          case AdminResult.Delete:
            this.deleteUser(user.id)
            break;
          default:
            break;
        }
      })
  }

  protected isUserLocked(user: UserDetails): boolean {
    if (!user.lockoutEnd) {
      return false;
    }
    const nowD = new Date();
    const now = Date.UTC(
      nowD.getUTCFullYear(),
      nowD.getUTCMonth(),
      nowD.getUTCDate(),
      nowD.getUTCHours(),
      nowD.getUTCMinutes(),
      nowD.getUTCSeconds(),
      nowD.getUTCMilliseconds()
    );
    const lockoutD = new Date(user.lockoutEnd);
    const lockout = Date.UTC(
      lockoutD.getUTCFullYear(),
      lockoutD.getUTCMonth(),
      lockoutD.getUTCDate(),
      lockoutD.getUTCHours(),
      lockoutD.getUTCMinutes(),
      lockoutD.getUTCSeconds(),
      lockoutD.getUTCMilliseconds()
    );

    return now - lockout < 0;
  }

  private blockUser(userDetails: UserDetails): void {
    const locked: boolean = this.isUserLocked(userDetails);
    this.openDialog(
      locked ? "Deblocheaza" : "Blocheaza",
      `Sunteti sigur ca doriti sa ${locked ? "deblocati" : "blocati"} utilizatorul?`,
      "Da",
      "Nu"
    )
      .afterClosed()
      .pipe(
        exhaustMap((confirmation) =>
          confirmation ?
            locked ?
              this.adminService.unblockUser(userDetails.id) :
              this.adminService.blockUser(userDetails.id) :
            of(undefined)
        )
      )
      .subscribe(() => this.loadData());
  }

  private deleteUser(userId: string): void {
    this.openDialog(
      "Sterge",
      `Sunteti sigur ca doriti sa stergeti utilizatorul?`,
      "Da",
      "Nu"
    )
      .afterClosed()
      .pipe(
        exhaustMap((confirmation) =>
          confirmation ?
            this.adminService.deleteUser(userId) :
            of(undefined)
        )
      )
      .subscribe(() => this.loadData());
  }

  private confirmUser(userId: string): void {
    this.openDialog(
      "Confirma",
      `Sunteti sigur ca doriti sa confirmati utilizatorul?`,
      "Da",
      "Nu"
    )
      .afterClosed()
      .pipe(
        exhaustMap((confirmation) =>
          confirmation ?
            this.adminService.confirmUser(userId) :
            of(undefined)
        )
      )
      .subscribe(() => this.loadData());
  }

  private loadData(): void {
    this.adminService
      .getUsers(this.pageIndex * this.pageSize, this.pageSize)
      .pipe(exhaustMap((response) => {
        const pagination = JSON.parse(response.headers.get('X-Pagination'));

        this.dataSource.data = response.body;

        //The paginator does not update correctly without setTimeout
        setTimeout(() => {
          this.paginator.pageIndex = this.pageIndex;
          this.paginator.length = pagination.TotalRecords;
        });

        return this.adminService.getOnlineUsers();
      }))
      .subscribe(response => {
        this.onlineUsers = response.body;
      });
  }

  private openDialog = (title: string, desc: string, yes: string, no: string): MatDialogRef<unknown> => this.dialog.open(ConfirmDialogComponent, {
    data: {
      title: title,
      description: desc,
      yesString: yes,
      noString: no
    }
  });
}
