import { Component, OnInit } from '@angular/core';
import { PAGES } from 'src/app/common/constants';

@Component({
  selector: 'app-confirm-passaword',
  templateUrl: './confirm-password.component.html',
  styleUrls: ['./confirm-password.component.scss']
})
export class ConfirmPasswordComponent implements OnInit {
  public pageName: PAGES = PAGES.PasswordConfirmationPage;

  readonly text = "Parola a fost schimbată cu succes!";

  constructor() { }

  ngOnInit(): void {
  }

}
