import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { GameType } from 'src/core/models/game/private-game-request.model';

@Component({
  selector: 'app-game-type',
  templateUrl: './game-type.component.html',
  styleUrls: ['./game-type.component.scss']
})
export class GameTypeComponent {

  public isClosing$: Observable<boolean>;

  constructor(
    private dialogRef: MatDialogRef<GameTypeComponent>,
  ) {
    this.isClosing$ = this.dialogRef.beforeClosed().pipe(mapTo(true));
  }

  public onPrivateGame(): void {
    this.dialogRef.close(GameType.Private);
  }

  public onPublicGame(): void {
    this.dialogRef.close(GameType.Public);
  }
}
