import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { CardColor, CardColorUtils } from 'src/core/models/game/card.model';

export interface FrisDialogData {
  tromfColors: CardColor[];
  playerName: string;
}

@Component({
  selector: 'app-fris-dialog',
  templateUrl: './fris-dialog.component.html',
  styleUrls: ['./fris-dialog.component.scss']
})
export class FrisDialogComponent {

  public isClosing$: Observable<boolean>;

  constructor(public dialogRef: MatDialogRef<FrisDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: FrisDialogData) {
    this.isClosing$ = this.dialogRef.beforeClosed().pipe(mapTo(true));
    this.dialogRef.disableClose = true;
  }

  public getCardColorUrl(color: CardColor): string {
    return CardColorUtils.getCardColorUrl(color);
  }

  public getCardColorName(color: CardColor): string {
    return CardColor[color];
  }

  public getDialogDescription(): string {
    if (this.data.tromfColors.length > 1) {
      return 'Poti juca fris cu cartile primite cu una din urmatoarele culori de tromf: <br/>Apasa pe simbol pentru a alege culoarea de tromf!';
    } else {
      return 'Poti juca fris cu cartile primite cu urmatoarea culoare de tromf: <br/>Apasa pe simbol pentru a alege culoarea de tromf!';
    }
  }

  public playsFris(tromfColor: CardColor): void {
    this.dialogRef.close({ playsFris: true, tromfColor });
  }
}
