import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { LogService } from './log.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OnlineService extends BaseService {

  constructor(http: HttpClient, logService: LogService) {
    super(http, logService);
  }

  public updateOnline(): Observable<void> {
    return this.http.post<void>(
      this.apiUrl + 'api/online/updateOnline',
      { headers: this.getCommonHeaders() }
    );
  }

  public getOnline(): Observable<string[]> {
    return this.http.get<string[]>(
      this.apiUrl + 'api/online/getOnline',
      { headers: this.getCommonHeaders() }
    );
  }
}
