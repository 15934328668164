import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/core/guards/auth.guard';
import { PermissionGuard } from 'src/core/guards/permission.guard';
import { AboutPageComponent } from './pages/about-page/about-page.component';
import { AdminPageComponent } from './pages/admin-page/admin-page.component';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { DonationsPageComponent } from './pages/donations-page/donations-page.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { ProfilePageComponent } from './pages/profile-page/profile-page.component';

const routes: Routes = [
  { path: "", redirectTo: "/home", pathMatch: "full" },
  { path: "home", component: LandingPageComponent },
  { path: "despre", component: AboutPageComponent },
  { path: "contact", component: ContactPageComponent },
  { path: "donatii", component: DonationsPageComponent },
  { path: "profile", component: ProfilePageComponent, canActivate: [AuthGuard, PermissionGuard] },
  { path: "admin", component: AdminPageComponent, canActivate: [AuthGuard, PermissionGuard] }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MainRoutingModule { }
