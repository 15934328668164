import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LogService } from './log.service';

@Injectable({
    providedIn: 'root'
})
export class CardImageCacheService {

    private storage: Storage = sessionStorage;
    private cardNames: string[] = [
        'Arseu_Daba.jpg', 'Arseu_Ghinda.jpg', 'Arseu_Rosu.jpg', 'Arseu_Verde.jpg',
        'Cal_Daba.jpg', 'Cal_Ghinda.jpg', 'Cal_Rosu.jpg', 'Cal_Verde.jpg',
        'Craita_Daba.jpg', 'Craita_Ghinda.jpg', 'Craita_Rosu.jpg', 'Craita_Verde.jpg',
        'Tuz_Daba.jpg', 'Tuz_Ghinda.jpg', 'Tuz_Rosu.jpg', 'Tuz_Verde.jpg',
        'Filcau_Daba.jpg', 'Filcau_Ghinda.jpg', 'Filcau_Rosu.jpg', 'Filcau_Verde.jpg',
        'cover.jpg'
    ];

    constructor(private http: HttpClient, private logService: LogService) {
        this.initialize();
    }

    private initialize(): void {
        this.storage.clear();
        this.cardNames.forEach(cardName => {
            if (!this.storage.getItem(cardName)) {
                this.getCard(cardName);
            }
        });
    }

    private getCard(cardName: string): void {
        this.http.get(`assets/game-assets/${cardName}`, { responseType: 'blob' })
            .subscribe({
                next: result => {
                    const reader = new FileReader();
                    reader.onload = (readerEvent) => {
                        this.storage.setItem(cardName, `data:image/jpeg;base64,${btoa(this.getStringFromReader(readerEvent.target.result))}`);
                    };
                    reader.readAsBinaryString(result);
                },
                error: error => {
                    this.logService.error(error);
                    this.storage.removeItem(cardName);
                }
            });
    }

    private getStringFromReader(value: string | ArrayBuffer): string {
        if (value instanceof ArrayBuffer) {
            return this.arrayBufferToString(value);
        }
        return value;
    }

    private arrayBufferToString(buffer: ArrayBuffer): string {
        const arr = new Uint8Array(buffer);
        const str = String.fromCharCode.apply(String, arr);
        if (/[\u0080-\uffff]/.test(str)) {
            this.logService.error('this string seems to contain (still encoded) multibytes');
        }
        return str;
    }


    public getCardImage(cardName: string): string {
        let imageValue: string = this.storage.getItem(cardName);
        if (!imageValue) {
            imageValue = `assets/game-assets/${cardName}`;
        }
        return imageValue;
    }
}
