<div class="card-layout">
  <span class="dialog-container" [@popupDialogAnimation]="{value: dialogState}"
    (@popupDialogAnimation.start)="dialogAnimationStart($event)"
    (@popupDialogAnimation.done)="dialogAnimationDone($event)">
    <div class="dialog-popup">
      {{dialogText}}
    </div>
  </span>
  <span class="username">{{ gameService.player.username }} (Eu)</span>
  <app-player-card [card]=card [countProp]=len [indexProp]=i+1
    *ngFor="let card of this.gameService.player.cards; let i = index; let len = count"></app-player-card>
  <button type="button" class="btn btn-primary tromf-button" (click)="leagaTromf()"
    [style.visibility]="visibilityState">{{buttonText}}</button>
</div>
<app-player-won-cards [position]=3></app-player-won-cards>