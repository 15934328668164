<div class="container p-20">
  <div class="card d-flex align-items-center shadow-sm p-3 mb-5 bg-body rounded ">
    
    <span class="title">Susține filcău.ro</span>

    <span class="sub-title">
      Donații prin transfer bancar
    </span>

    <div class="mt-3">
      <span>
        CONT: 
      </span>
      <span class="iban">
        RO19BTRL01301201942555XX
      </span>
      <span>
        - RON
      </span>
    </div>

    <span class="mt-3 obliq">
      LAZĂR VIOREL
    </span>

  </div>
</div>