<mat-nav-list>
    <a mat-list-item (click)="block($event)">
        <span mat-line> {{ data.locked ? "Deblocheaza" : "Blocheaza" }} </span>
        <span mat-line> {{ data.locked ? "Deblocheaza" : "Blocheaza" }} accesul utilizatorului in aplicatie</span>
    </a>

    <a mat-list-item (click)="confirm($event)" [class.disabled]="data.confirmed">
        <span mat-line>Confirma</span>
        <span mat-line>Confirmarea contului unui utilizator, pentru access in aplicatie</span>
    </a>

    <a mat-list-item (click)="delete($event)">
        <span mat-line>Sterge</span>
        <span mat-line>Sterge contul utilizatorului din aplicatie</span>
    </a>
</mat-nav-list>