import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogService {
  private logLevel: LogLevel;

  constructor() {
    this.logLevel = LogLevel ? LogLevel.Debug : 1;
  }

  public debug(...msg: any[]): void {
    if (this.logLevel <= LogLevel.Debug) {
      console.debug(`[${this.dateString}]: `, msg);
    }
  }

  public log(...msg: any[]): void {
    if (this.logLevel <= LogLevel.Info) {
      console.log(`[${this.dateString}]: `, msg);
    }
  }

  public warn(...msg: any[]) {
    if (this.logLevel <= LogLevel.Warning) {
      console.warn(`[${this.dateString} ]: `, msg);
    }
  }

  public error(...msg: any[]): void {
    if (this.logLevel <= LogLevel.Error) {
      console.error(`[${this.dateString}]: `, msg);
    }
  }

  private get dateString(): string {
    const now: Date = new Date();
    return now.toISOString();
  }
}

export class LogServiceHelper {

  private static logService: LogService = new LogService();

  private constructor() { }

  public static debug(msg: any): void {
    LogServiceHelper.logService.debug(msg);
  }

  public static log(msg: any): void {
    LogServiceHelper.logService.log(msg);
  }

  public static warn(msg: any) {
    LogServiceHelper.logService.warn(msg);
  }

  public static error(msg: any): void {
    LogServiceHelper.logService.error(msg);
  }
}

export enum LogLevel {
  Debug = 1,
  Info = 2,
  Warning = 3,
  Error = 4
}
