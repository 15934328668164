<ng-container *ngIf="!(isClosing$ | async)">
    <div class="modal-body p-0">
        <div class="d-flex flex-column align-items-center p-2">
            <h4 mat-dialog-title class="title">Alege tipul jocului</h4>

            <div class="d-flex justify-content-center align-items-center h-100 w-100">
                <div class="d-flex flex-column align-items-center justify-content-center game-option w-100"
                    (click)="onPrivateGame()">
                    <i class="fas fa-user-friends fa-3x fl-text-dark-gray"></i>
                    <h6 class="sub-title">Joc Privat</h6>
                </div>
                <span class="vertical-line bg-dark-gray"></span>
                <div class="d-flex flex-column align-items-center justify-content-center game-option w-100"
                    (click)="onPublicGame()">
                    <i class="fas fa-user fa-3x fl-text-dark-gray"></i>
                    <h6 class="sub-title">Joc Public</h6>
                </div>
            </div>
        </div>
    </div>
</ng-container>