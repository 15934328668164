import { Injectable } from "@angular/core";
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LoaderService {

    private loaderSubject: BehaviorSubject<boolean>;

    constructor() {
        this.loaderSubject = new BehaviorSubject<boolean>(false);
    }

    public get loaderState(): boolean {
        return this.loaderSubject.value;
    }

    public set loaderState(state: boolean) {
        this.loaderSubject.next(state);
    }

    public get loaderObservable(): BehaviorSubject<boolean> {
        return this.loaderSubject;
    }
}
