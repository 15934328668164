import { Component } from '@angular/core';
import { FilcauLayout } from 'src/core/abstracts/filcau.component';
import { LogService } from 'src/core/services/log.service';

@Component({
  selector: 'app-authentication',
  templateUrl: './authentication-layout.component.html',
  styleUrls: ['./authentication-layout.component.scss']
})
export class AuthenticationLayoutComponent extends FilcauLayout {

  public isTopbarVisible: boolean = true;

  constructor(protected readonly logService: LogService) {
    super(logService);
  }
}
