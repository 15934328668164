import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/core/services/auth.service';
import { RegisterModel } from '../../../../core/models/register.model';
import { MustMatch } from 'src/app/utils/form.validators';
import { ANONYMOUS_USER_ID, PAGES } from 'src/app/common/constants';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss',]
})
export class RegisterComponent implements OnInit {
  public pageName: PAGES = PAGES.RegisterPage;

  registrationModel: RegisterModel;
  showConfirmation: boolean;
  errorDescription: string;
  registerForm: FormGroup;
  isSubmitted: boolean;

  private storage: Storage = localStorage;
  constructor(
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private toastrService: ToastrService
  ) {
  }

  ngOnInit() {
    this.registrationModel = new RegisterModel();

    this.registerForm = this.formBuilder.group({
      firstname: ['', [Validators.required, Validators.maxLength(40)]],
      lastname: ['', [Validators.required, Validators.maxLength(40)]],
      username: ['', [Validators.required, Validators.maxLength(40)]],
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required, Validators.maxLength(40)]],
      confirmPassword: ['', Validators.required],
    }, {
      validator: MustMatch('password', 'confirmPassword')
    });
  }

  onRegister() {
    if (this.registerForm.valid) {

      this.registrationModel.firstname = this.registerForm.controls.firstname.value;
      this.registrationModel.lastname = this.registerForm.controls.lastname.value;
      this.registrationModel.username = this.registerForm.controls.username.value;
      this.registrationModel.email = this.registerForm.controls.email.value;
      this.registrationModel.password = this.registerForm.controls.password.value;
      this.registrationModel.confirmPassword = this.registerForm.controls.confirmPassword.value;
      this.registrationModel.userKey = this.storage.getItem(ANONYMOUS_USER_ID);

      this.authService.register(this.registrationModel).subscribe(
        () => {
          this.isSubmitted = false;
          localStorage.removeItem(ANONYMOUS_USER_ID);
          this.toastrService.success('Contul a fost create cu succes. Verifca-ti mailul pentru activare!');
          this.router.navigate(['/home']);
        },
        err => {
          this.isSubmitted = false;
        }
      );
      this.isSubmitted = true;
    } else {
      this.registerForm.markAllAsTouched();
    }
  }
}
