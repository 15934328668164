<div class="header-container">
    <div class="status-info-left">
        <ng-container *ngIf="isGameRunning">Puncte jucator: {{this.selfScore}}</ng-container>
        <ng-container *ngIf="isGameWaiting">In asteptarea altor jucatori!</ng-container>
    </div>
    <div class="center-section">
        <div *ngIf="isGameRunning" class="center-wrapper">
            <span class="score-container">
                <span class="team-name">Echipa 1</span>
                <span class="score">{{this.firstTeamScore}}</span>
            </span>
            <span class="center-container">
                <span class="tromf-title">Tromf</span>
                <span *ngIf="!tromfAnnounced" class="score-separator">:</span>
                <img *ngIf="tromfAnnounced" src="{{ gameService.tromf | cardColorUrl }}"
                    alt="{{ gameService.tromf | cardName}}" class="tromf-color-icon" />
            </span>
            <span class="score-container">
                <span class="team-name">Echipa 2</span>
                <span class="score">{{this.secondTeamScore}}</span>
            </span>
        </div>
    </div>
    <div *ngIf="isTimeoutEnabled" class="status-info-right">
        <app-game-countdown></app-game-countdown>
    </div>
</div>