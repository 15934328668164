import { Component, OnInit, Input } from '@angular/core';
import { Card } from 'src/core/models/game/card.model';

@Component({
  selector: 'app-opponent-card',
  templateUrl: './opponent-card.component.html',
  styleUrls: ['./opponent-card.component.scss']
})
export class OpponentCardComponent implements OnInit {

  @Input() card: Card;

  public zAngle: number;
  public xAngle: number;
  public yAngle: number;
  public translateY: number;
  public count: number;
  public index: number;

  constructor() { }

  ngOnInit(): void {
  }

  @Input()
  public set countProp(count: number) {
    this.count = count;
    this.setCardPositionValues();
  }

  @Input()
  public set indexProp(index: number) {
    this.index = index;
    this.setCardPositionValues();
  }

  public setCardPositionValues(): void {

  }

  public getCardCssClass(): string {
    return `card-position-index-${this.index}-count-${this.count}`;
  }
}
