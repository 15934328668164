<ng-container *ngIf="!(isClosing$ | async)">
    <form [formGroup]="formGroup" (submit)="sendInvite()">
        <div class="modal-body p-0">
            <div class="d-flex flex-column align-items-center pt-2">
                <span class="title ">Joc privat</span>

                <div class="d-flex justify-content-center align-items-center flex-column border p-3 m-3">
                    <div class="d-flex align-items-start w-100 mb-2">
                        <span class="text-secondary font-weight-bold">
                            Echipa #1
                        </span>
                    </div>

                    <div class="d-flex align-items-start w-100 mb-2">
                        <span class="text-secondary">
                            Tu: {{you}}
                        </span>
                    </div>

                    <input type="text" class="form-control" placeholder="Username sau email" formControlName="player2Username"
                        aria-label="Username sau email" aria-describedby="basic-addon2">
                </div>

                <div class="d-flex justify-content-center align-items-center flex-column border p-3 m-3">
                    <div class="d-flex align-items-start w-100 mb-2">
                        <span class="text-secondary font-weight-bold">
                            Echipa #2
                        </span>
                    </div>

                    <input type="text" class="form-control mb-3" placeholder="Username sau email" formControlName="player3Username"
                        aria-label="Username sau email" aria-describedby="basic-addon2">


                    <input type="text" class="form-control mb-3" placeholder="Username sau email" formControlName="player4Username"
                        aria-label="Username sau email" aria-describedby="basic-addon2">

                </div>

                <button type="submit" class="btn fl-primary-button mb-2" [disabled]="!formGroup.valid">Trimite invitatia</button>
            </div>
        </div>
    </form>
</ng-container>