<div class="container-fluid d-flex flex-column justify-content-between h-100 overflow" [ngClass]="componentBackgroundColor">
    <header class="row">
        <div class="col-12">
            <app-topbar *ngIf="isTopbarVisible" (play)="onPlay()"></app-topbar>
            <app-navbar [backgroundColor]="navBackgroundColor">
            </app-navbar>
        </div>
    </header>

    <main class="row">
        <div class="col-12">
            <router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>
        </div>
    </main>

    <app-footer-layout></app-footer-layout>
</div>