import { Component, OnInit } from "@angular/core";
import { AuthService } from "src/core/services/auth.service";
import { ToastrService } from "ngx-toastr";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { PAGES } from "src/app/common/constants";

@Component({
  selector: "app-forgot-password",
  templateUrl: "./forgot-password.component.html",
  styleUrls: [
    "./forgot-password.component.scss"]
})
export class ForgotPasswordComponent implements OnInit {
  public pageName: PAGES = PAGES.ForgotPasswordPage;

  errorDescription: string;
  forgotPasswordForm: FormGroup
  isSubmitted: boolean;

  constructor(private authService: AuthService, private toastrService: ToastrService, private router: Router, private route: ActivatedRoute) { }

  ngOnInit() {
    this.forgotPasswordForm = new FormGroup({
      email: new FormControl("", Validators.email),
    })
  }

  onRecoverPassword() {
    if (this.forgotPasswordForm.valid) {
      this.isSubmitted = true;

      const email = this.forgotPasswordForm.controls.email.value;

      this.authService.recoverPassword(email).subscribe(
        () => {
          this.isSubmitted = false;
          this.toastrService.success("Verfica-ti emailul pentru ati reseta parola!");
          this.router.navigate(['/login']);
        },
        () => {
          this.isSubmitted = false;
        }
      );
    } else {
      this.forgotPasswordForm.markAllAsTouched();
    }
  }
}
