import { Injectable } from "@angular/core";
import {
  HttpHeaders,
  HttpClient
} from "@angular/common/http";
import { LogService } from './log.service';
import { LocalStorageIndex } from "src/app/common/constants";
import jwtDecode from "jwt-decode";
import { environment } from "src/environments/environment";

@Injectable()
export class BaseService {

  protected apiUrl = environment.baseUrl;

  constructor(protected http: HttpClient, protected logService: LogService) {
    const storedToken = localStorage.getItem(LocalStorageIndex.TOKEN);

    /***
     * The idea was to have an in memory variable(as we usually do) for the token, but it seems there is a weird problem when setting a value to that variable through the setter.
     * Though the localStorage is set, and can be accessed sucessfuly, the variable becomes immediately undefined and it generates unwanted behaviour.
     * Thus, only the localstorge is used.
     */
    // this._token = !isStringNullOrEmpty(storedToken)
    //   ? storedToken
    //   : undefined
  }

  protected get token(): string {
    return localStorage.getItem(LocalStorageIndex.TOKEN);
  }

  //maybe set token into session storage
  protected set token(v: string) {
    this.setLocalStorage(LocalStorageIndex.TOKEN, v);
  }

  protected get decodedToken(): Record<string, any> {
    return this.token ? jwtDecode(this.token) : undefined;
  }

  protected getCommonHeaders(contentType: string = undefined): HttpHeaders {
    let headers: HttpHeaders = new HttpHeaders({
      "Content-Type": contentType ?? "application/json"
    });
    const token: string = this.token;
    if (token) {
      headers = headers.append("Authorization", token);
    }
    return headers;
  }

  protected getCommonUnauthorizedHeaders() {
    return new HttpHeaders({
      "Content-Type": "application/json",
    });
  }

  protected setLocalStorage(key: LocalStorageIndex, value: any): void {
    if (value) {
      localStorage.setItem(key, value);
    } else {
      localStorage.removeItem(key);
    }
  }

  protected clearLocalStorage(): void {
    localStorage.clear()
  }
}
