import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { AuthService } from 'src/core/services/auth.service';
import { Router } from '@angular/router';
import { UserService } from 'src/core/services/user.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { User } from 'src/core/models/user.model';
import { MenuItem } from 'src/app/common/menu-item';
import { PROFILE } from 'src/app/common/constants';
import { PermissionPipe } from 'src/core/pipes/permission.pipe';

@Component({
  selector: 'app-userprofile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit, OnDestroy {

  @Input() textColor: string;

  public user: User;
  public menuItems: MenuItem[];
  private destroy$ = new Subject();

  constructor(private userService: UserService, private authService: AuthService, private router: Router, private permissionPipe: PermissionPipe) { }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    this.menuItems = this.permissionPipe.transform(PROFILE);
    this.userService.currentUser$.pipe(takeUntil(this.destroy$)).subscribe(user => this.user = user);
  }

  onLogout() {
    this.authService.logout();
    this.router.navigate(["/home"])
  }

  onProfile() {
    this.router.navigate(["/profile"])
  }
}
