import { Subject } from 'rxjs';
import { GameService } from 'src/core/services/game.service';

export interface GameState {
    afterChanged(): void;
    beforeChanged(): void;
    handleMethod(methodName: string, ...args: any[]): void;
    handleInvalidMethod(methodName: string, args: any[]): void;
    getStateName(): string;
}

export class BaseGameState implements GameState {
    public invalidMethodCalled: Subject<string> = new Subject<string>();

    protected stateName: string = 'BaseGame';
    protected validErrorHandledMethods: string[] = ['playerLeft'];

    constructor(protected gameService: GameService) {
    }

    public afterChanged(): void {
    }

    public beforeChanged(): void {
    }

    public async handleMethod(methodName: string, args: any[]) {
        this.gameService.resetTimeout();
        switch (methodName) {
            case ('playerLeft'):
                this.gameService.logService.error(`Player disconnected method called`);
                this.gameService.playerLeftHandler(args);
                break;
            case ('gameDiscarded'):
                this.gameService.logService.error(``);
                break;
        }
    }

    public getStateName(): string {
        return this.stateName;
    }

    handleInvalidMethod(methodName: string, args: any[]): void {
        if (this.validErrorHandledMethods.filter(method => method === methodName).length === 0) {
            debugger;
            this.invalidMethodCalled.next(methodName);
            this.gameService.logService.error(`Invalid method '${methodName}' call in this game state '${this.stateName}' with the following arguments: ${JSON.stringify(args)}.`);
        }
    }
}

export class WaitingForPartyState extends BaseGameState {

    constructor(protected gameService: GameService) {
        super(gameService);
        this.stateName = 'WaitingForParty';
    }

    public async handleMethod(methodName: string, args: any[]) {
        super.handleMethod(methodName, args);
        switch (methodName) {
            case ('playerExists'):
                this.gameService.playerExistsHandler(args);
                break;
            case ('playerJoined'):
                this.gameService.playerJoinedHandler(args);
                break;
            case ('waitingForParty'):
                this.gameService.waitingForPartyHandler(args);
                break;
            case ('allPlayersJoined'):
                this.gameService.allPlayersJoinedHandler(args);
                break;
            case ('invalidGame'):
                this.gameService.invalidGameHandler(args);
                break;
            default:
                this.handleInvalidMethod(methodName, args);
                break;
        }
    }
}

export class BateOrTaieState extends BaseGameState {

    constructor(protected gameService: GameService) {
        super(gameService);
        this.stateName = 'BateOrTaie';
    }

    public async handleMethod(methodName: string, args: any[]) {
        super.handleMethod(methodName, args);
        switch (methodName) {
            case ('cardsDealt'):
                await this.gameService.cardsDealtHandler(args);
                break;
            default:
                this.handleInvalidMethod(methodName, args);
        }
    }
}

export class BateState extends BaseGameState {

    constructor(protected gameService: GameService) {
        super(gameService);
        this.stateName = 'Bate';
    }

    public async handleMethod(methodName: string, args: any[]) {
        super.handleMethod(methodName, args);
        switch (methodName) {
            case ('cardsDealt'):
                await this.gameService.cardsDealtHandler(args);
                break;
            default:
                this.handleInvalidMethod(methodName, args);
        }
    }
}

export class CeaIesitMicaState extends BaseGameState {

    constructor(protected gameService: GameService) {
        super(gameService);
        this.stateName = 'CeaIesitMica';
    }

    public async handleMethod(methodName: string, args: any[]): Promise<void> {
        super.handleMethod(methodName, args);
        switch (methodName) {
            case ('ceaIesitMicaWinner'):
                this.gameService.ceaIesitMicaWinnerHandler(args);
                break;
            case ('cardPlayed'):
                await this.gameService.cardPlayedHandler(args);
                break;
            default:
                this.handleInvalidMethod(methodName, args);
                break;
        }
    }
}

export class CeaIesitMicaWinnerState extends BaseGameState {

    constructor(protected gameService: GameService) {
        super(gameService);
        this.stateName = 'CeaIesitMicaWinner';
    }

    public async handleMethod(methodName: string, args: any[]) {
        super.handleMethod(methodName, args);
        switch (methodName) {
            default:
                this.handleInvalidMethod(methodName, args);
        }
    }
}

export class ChoosingDealerState extends BaseGameState {

    constructor(protected gameService: GameService) {
        super(gameService);
        this.stateName = 'ChoosingDealer';
    }

    public async handleMethod(methodName: string, args: any[]) {
        super.handleMethod(methodName, args);
        switch (methodName) {
            case ('choosingFirstDealer'):
                this.gameService.choosingFirstDealerHandler(args);
                break;
            case ('dealerChosen'):
                this.gameService.dealerChosenHandler(args);
                break;
            default:
                this.handleInvalidMethod(methodName, args);
        }
    }
}

export class FrisState extends BaseGameState {

    constructor(protected gameService: GameService) {
        super(gameService);
        this.stateName = 'Fris';
    }

    public async handleMethod(methodName: string, args: any[]) {
        super.handleMethod(methodName, args);
        switch (methodName) {
            case ('cardPlayed'):
                await this.gameService.cardPlayedHandler(args);
                break;
            case ('frisWinner'):
                this.gameService.frisWinnerHandler(args);
                break;
            default:
                this.handleInvalidMethod(methodName, args);
                break;
        }
    }
}

export class FrisWinnerState extends BaseGameState {

    constructor(protected gameService: GameService) {
        super(gameService);
        this.stateName = 'FrisWinner';
    }

    public async handleMethod(methodName: string, args: any[]) {
        super.handleMethod(methodName, args);
        switch (methodName) {
            case ('cardsDealt'):
                await this.gameService.cardsDealtHandler(args);
                break;
            default:
                this.handleInvalidMethod(methodName, args);
                break;
        }
    }
}

export class GameEndedState extends BaseGameState {

    constructor(protected gameService: GameService) {
        super(gameService);
        this.stateName = 'GameEnded';
    }

    public async handleMethod(methodName: string, args: any[]) {
        super.handleMethod(methodName, args);
        switch (methodName) {
            default:
                this.handleInvalidMethod(methodName, args);
                break;
        }
    }
}

export class GameWinnerState extends BaseGameState {

    constructor(protected gameService: GameService) {
        super(gameService);
        this.stateName = 'GameWinner';
    }

    public async handleMethod(methodName: string, args: any[]) {
        super.handleMethod(methodName, args);
        switch (methodName) {
            case ('matchEnded'):
                this.gameService.matchEndedHandler(args);
                break;
            case ('choosingNextDealer'):
                this.gameService.choosingNextDealerHandler(args);
                break;
            default:
                this.handleInvalidMethod(methodName, args);
                break;
        }
    }
}

export class InGameState extends BaseGameState {

    constructor(protected gameService: GameService) {
        super(gameService);
        this.stateName = 'InGame';
    }

    public async handleMethod(methodName: string, args: any[]) {
        super.handleMethod(methodName, args);
        switch (methodName) {
            case ('cardPlayed'):
                await this.gameService.cardPlayedHandler(args);
                break;
            case ('gameWinner'):
                await this.gameService.gameWinnerHandler(args);
                break;
            case ('gameTie'):
                await this.gameService.gameTieHandler(args);
                break;
            case ('matchEnded'):
                await this.gameService.matchEndedHandler(args);
                break;
            case ('wrongCard'):
                this.gameService.wrongCardHandler(args);
                break;
            default:
                this.handleInvalidMethod(methodName, args);
                break;
        }
    }
}

export class LegareaTromfuluiState extends BaseGameState {

    constructor(protected gameService: GameService) {
        super(gameService);
        this.stateName = 'LegareaTromfului';
    }

    public async handleMethod(methodName: string, args: any[]) {
        super.handleMethod(methodName, args);
        switch (methodName) {
            case ('cardsDealt'):
                await this.gameService.cardsDealtHandler(args);
                break;
            case ('tieWinner'):
                this.gameService.tieWinnerHandler(args);
                break;
            case ('tiePlayed'):
                this.gameService.tiePlayedHandler(args);
                break;
            case ('dealerChosen'):
                this.gameService.dealerChosenHandler(args);
                break;
            default:
                this.handleInvalidMethod(methodName, args);
                break;
        }
    }
}

export class TaieState extends BaseGameState {

    constructor(protected gameService: GameService) {
        super(gameService);
        this.stateName = 'Taie';
    }

    public async handleMethod(methodName: string, args: any[]) {
        super.handleMethod(methodName, args);
        switch (methodName) {
            case ('cardsDealt'):
                await this.gameService.cardsDealtHandler(args);
                break;
            case ('isPlayingFris'):
                this.gameService.isPlayingFrisHandler(args);
                break;
            case ('frisAnnounced'):
                this.gameService.frisAnnouncedHandler(args);
                break;
            default:
                this.handleInvalidMethod(methodName, args);
                break;
        }
    }
}
