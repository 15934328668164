<div class="modal fade" id="uploadModal" tabindex="-1" role="dialog" aria-labelledby="uploadModal" aria-hidden="true"
  (click)="onModalOutside($event)">
  <div class="modal-dialog modal-dialog-centered" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="container-fluid d-flex justify-content-center p-0">
          <div class="row">
            <div class="col-12">
              <img-cropper #cropper [image]="data" [settings]="cropperSettings"></img-cropper>
              <div>
                <input id="custom-input" type="file" (change)="fileChangeListener($event)" #fileInput>
              </div>
              <div>
                <button class="btn btn-primary mt-2" data-bs-dismiss="modal" (click)="upload()">Upload</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>