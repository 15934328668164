import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { GameService } from 'src/core/services/game.service';

@Component({
  selector: 'app-game-countdown',
  templateUrl: './game-countdown.component.html',
  styleUrls: ['./game-countdown.component.scss']
})
export class GameCountdownComponent implements OnInit, OnDestroy {

  @Input() defaultValue: number = 120000;

  private subscriptions: Subscription;
  private timer: any;
  private criticalThreshold: number = 10;// seconds remaining when text turns red;
  private timerEnd: number;

  protected minutes: string = '00';
  protected seconds: string = '00';
  protected isBelowThreshold: boolean = false;

  constructor(private readonly gameService: GameService) { }

  ngOnInit(): void {
    this.subscriptions = new Subscription();
    this.subscriptions.add(this.gameService.timeoutReset.subscribe(() => {
      this.resetTimer();
    }));
  }

  private resetTimer(): void {
    if (this.timer) {
      clearTimeout(this.timer);
    }
    this.timerEnd = (new Date()).getTime() + this.defaultValue;
    this.isBelowThreshold = false;
    this.updateTimer();
    this.timer = setInterval(() => {
      this.updateTimer();
    }, 500);
  }

  private updateTimer(): void {
    const now = (new Date()).getTime();
    if (now >= this.timerEnd) {
      this.timeoutExpired();
      return;
    }
    const actualTime = this.timerEnd - now;
    const minutes = Math.floor(actualTime / 60000);
    const seconds = Math.floor((actualTime % 60000) / 1000);
    this.minutes = minutes < 10 ? `0${minutes}` : `${minutes}`;
    this.seconds = seconds < 10 ? `0${seconds}` : `${seconds}`;
    if (minutes === 0 && seconds <= this.criticalThreshold) {
      this.isBelowThreshold = true;
    }
  }

  private timeoutExpired(): void {
    clearInterval(this.timer);
    this.minutes = '00';
    this.seconds = '00';
    this.gameService.timerExpired();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.defaultValue = 120000;
  }

}
