import { Component, OnInit } from '@angular/core';
import { OpponentCardComponent } from '../opponent-card/opponent-card.component';
import { trigger, state, style } from '@angular/animations';
import { CardImagePipe } from 'src/core/pipes/card-image.pipe';

@Component({
  selector: 'app-opponent-card-west',
  templateUrl: './opponent-card-west.component.html',
  styleUrls: ['./opponent-card-west.component.scss'],
  animations: [
    trigger('highlightTrigger', [
      state('normal', style({
        transform: 'rotateX({{xAngle}}deg) rotateZ({{zAngle}}deg) rotateY({{yAngle}}deg) translateY({{translateY}}vmin)'
      }), { params: { xAngle: '-65', zAngle: '0', yAngle: '0', translateY: '0' } })
    ])
  ]
})
export class OpponentCardWestComponent extends OpponentCardComponent implements OnInit {

  constructor(public cardImagePipe: CardImagePipe) {
    super();
  }

  ngOnInit() {
    this.setCardPositionValues();
  }

  public setCardPositionValues(): void {
    let sign = this.index <= (this.count / 2) ? 1 : -1;
    this.xAngle = -65;
    if ((this.index == 1 && this.count == 1) || (this.index == 2 && this.count == 3) || (this.index == 3 && this.count == 5)) {
      this.zAngle = 0;
      this.yAngle = 0;
      this.translateY = -2.5;
      return;
    }
    if ((this.index == 1 && this.count == 2) || (this.index == 2 && this.count == 4) || (this.index == 2 && this.count == 2) || (this.index == 3 && this.count == 4)) {
      this.zAngle = sign * 5;
      this.yAngle = sign * 5;
      this.translateY = -2.5;
      return;
    }
    if ((this.index == 1 && this.count == 3) || (this.index == 2 && this.count == 5) || (this.index == 3 && this.count == 3) || (this.index == 4 && this.count == 5)) {
      this.zAngle = sign * 12;
      this.yAngle = sign * 7.5
      this.translateY = -3;
      return;
    }
    if ((this.index == 1 || this.index == 5) && this.count == 5) {
      this.zAngle = sign * 30;
      this.yAngle = sign * 12.5;
      this.translateY = -5;
      return;
    }
    if ((this.index == 1 || this.index == 4) && this.count == 4) {
      this.zAngle = sign * 20;
      this.yAngle = sign * 10;
      this.translateY = -2.5;
      return;
    }
  }
}
