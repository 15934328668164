<div class="loading" *ngIf="this.waitingDisplay">
  <div class="loader">
  </div>
</div>
<img [src]="gameService.getAllPlayers()[arrayPosition].getAvatarUrl()" alt="Avatar" class="avatar">
<span class="username">{{gameService.getAllPlayers()[arrayPosition].username}}</span>
<span class="team-number">{{gameService.getAllPlayers()[arrayPosition].teamNumberText}}</span>
<div class="card-layout">
  <span class="dialog-container" [@popupDialogAnimation]="{value: dialogState}"
    (@popupDialogAnimation.start)="dialogAnimationStart($event)"
    (@popupDialogAnimation.done)="dialogAnimationDone($event)">
    <div class="dialog-popup">
      {{dialogText}}
    </div>
  </span>
  <app-opponent-card-west [card]=card [countProp]=len [indexProp]=i+1
    *ngFor="let card of gameService.getAllPlayers()[arrayPosition].cards; let i = index; let len = count">
  </app-opponent-card-west>
</div>
<app-player-won-cards [position]=4></app-player-won-cards>