import { Injectable } from '@angular/core';
import { PreloadingStrategy } from '@angular/router';
import { Observable, empty } from 'rxjs';

@Injectable()
export class SelectablePreloadingStrategy implements PreloadingStrategy {

    preload(route: import("@angular/router").Route, load: () => Observable<any>): Observable<any> {
        return route.data && route.data['preload'] ? load() : empty();
    }
}
