import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { GameService } from 'src/core/services/game.service';
import { CardColor } from 'src/core/models/game/card.model';
import { AppPlayer } from 'src/core/models/app-player.model';
import { timer, Subscription, Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { BateTaieDialogComponent } from '../dialogs/bate-taie-dialog/bate-taie-dialog.component';
import { FrisDialogComponent } from '../dialogs/fris-dialog/fris-dialog.component';
import { GeneralDialogComponent } from '../dialogs/general-dialog/general-dialog.component';
import { BateTaie } from 'src/core/models/game/game.model';
import { GameUtilsService } from 'src/core/services/game-utils.service';
import { CanComponentDeactivate } from 'src/core/guards/can-deactivate.guard';
import { GameEndedState } from 'src/core/models/game/game-state.model';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { ActivatedRoute, Router } from '@angular/router';
import { HubConnectionState } from '@microsoft/signalr';
import { GameEndDialogComponent, GameEndDialogData } from '../dialogs/game-end-dialog/game-end-dialog.component';
import { MatchEndDialogData, MatchEndedDialogComponent } from '../dialogs/match-ended-dialog/match-ended-dialog.component';

@Component({
  selector: 'app-main-game',
  templateUrl: './main-game.component.html',
  styleUrls: ['./main-game.component.scss']
})
export class MainGameComponent implements OnInit, OnDestroy, CanComponentDeactivate {

  constructor(public gameService: GameService,
    private router: Router,
    public dialog: MatDialog,
    private activatedRoute: ActivatedRoute
  ) { }

  private waitTimeout: number = 3000; // miliseconds
  private subscriptions: Subscription;

  public isWaiting: boolean = false;
  public gameStarted: boolean = false;
  public timerDisplayed: boolean = false;

  ngOnInit() {
    this.subscriptions = new Subscription();
    this.subscribeToEvents();
    this.initialize(false);
  }

  private initialize(reinitialize: boolean = true): void {
    let gameId = this.activatedRoute.snapshot.queryParams["gameId"];

    if(this.isPrivateGame() && reinitialize){
      gameId = undefined;
      this.router.navigate(['/joc']);
    }

    this.gameStarted = false;
    this.isWaiting = true;
    const timer$ = timer(this.waitTimeout, 0);
    this.subscriptions.add(timer$.subscribe(() => {
      this.isWaiting = false;
    }));
    this.gameService.disconnect(true, gameId);
  }

  private isPrivateGame(): boolean {
    return !!this.activatedRoute.snapshot.queryParams["gameId"];
  }

  public canDeactivate(): boolean | Observable<boolean> | Promise<boolean> {
    if (this.gameService.gameState instanceof GameEndedState) {
      return true;
    }
    if (this.gameService.hubState === HubConnectionState.Disconnected) {
      return true;
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirmare',
        description: 'Esti sigur ca vrei sa iesi din joc?',
        yesString: 'Da',
        noString: 'Nu'
      }
    });
    const promise: Promise<boolean> = new Promise<boolean>((resolve) => {
      dialogRef.afterClosed().subscribe((confirmationValue: boolean) => {
        resolve(confirmationValue);
      });
    });
    return promise;
  }

  private subscribeToEvents(): void {
    this.subscriptions.add(this.gameService.dealerDecided.subscribe(dealerInfo => this.dealerChosen(dealerInfo.dealer, dealerInfo.isFirstDealer)));
    this.subscriptions.add(this.gameService.playerDisconnected.asObservable().subscribe(player => this.playerDisconnectedHandler(player)));
    this.subscriptions.add(this.gameService.cardDealt.subscribe());
    this.subscriptions.add(this.gameService.frisRequested.subscribe(() => this.frisRequested()));
    this.subscriptions.add(this.gameService.lostConnection.subscribe((connectionStart) => this.connectionLostHandler(connectionStart)));
    this.subscriptions.add(this.gameService.playerAlreadyJoined.subscribe((player) => this.playerAlreadyJoinedHandler(player)));
    this.subscriptions.add(this.gameService.gameEnded.subscribe((score: GameEndDialogData) => this.gameEndedHandler(score)));
    this.subscriptions.add(this.gameService.matchEnded.subscribe((score: MatchEndDialogData) => this.matchEndedHandler(score)));
    this.subscriptions.add(this.gameService.invalidGame.subscribe((data) => this.invalidGameHandler(data)));
  }

  @HostListener('window:unload', ['$event'])
  unloadHandler() {
    this.gameService.disconnect(false);
  }

  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHandler(event: BeforeUnloadEvent) {
    if (this.gameService.hubState === HubConnectionState.Connected && !this.gameService.isMatchEnded) {
      event.returnValue = 'Esti sigur ca vrei sa parasesti jocul?';
    }
  }

  private gameEndedHandler(score: GameEndDialogData): void {
    const dialogRef = this.dialog.open(GameEndDialogComponent, {
      data: score
    });
    dialogRef.afterOpened().subscribe(() => {
      this.gameService.setWaitingPlayerState(-1, false);
      const timer$ = timer(10000, 0);
      this.subscriptions.add(timer$.subscribe(t => {
        dialogRef.close();
      }));
    });
    dialogRef.afterClosed().subscribe(() => {
      this.gameService.setWaitingPlayerState(GameUtilsService.getPlayerPositionRightToDealer(this.gameService.dealer.tablePosition), true);
    });
  }

  private matchEndedHandler(score: MatchEndDialogData): void {
    const subscription: Subscription = this.dialog.afterAllClosed.subscribe(() => {
      const dialogRef = this.dialog.open(MatchEndedDialogComponent, {
        data: score
      });
      dialogRef.beforeClosed().subscribe(() => {
        subscription.unsubscribe();
      });
      dialogRef.afterClosed().subscribe((confirmationValue: boolean) => {
        if (confirmationValue) {
          this.initialize();
        } else {
          this.router.navigate(['/']);
        }
      });
    });
  }

  private invalidGameHandler(description: string): void {
    this.isWaiting = false;
    this.gameStarted = true;
    this.dialog.closeAll();
    let dialogData: any;
    dialogData = {
      data: {
        title: 'Joc invalid',
        description: description,
        yesString: 'Ok',
      }
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogData);
    dialogRef.afterClosed().subscribe(() => {
      this.router.navigate(['/']);
    });
  }

  private connectionLostHandler(connectionStart: boolean): void {
    this.isWaiting = false;
    this.gameStarted = true;
    this.dialog.closeAll();
    let dialogData: any;
    if (connectionStart) {
      dialogData = {
        data: {
          title: 'Deconectat',
          description: 'Jocul nu s-a putut conecta la server. Incearca mai tarziu sau contacteaza-ne.',
          noString: 'Ok'
        }
      }
    } else {
      dialogData = {
        data: {
          title: 'Deconectat',
          description: 'Conexiunea la server s-a pierdut. Doresti sa incerci un nou joc?',
          yesString: 'Da',
          noString: 'Nu'
        }
      }
    }
    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogData);
    dialogRef.afterClosed().subscribe((confirmationValue: boolean) => {
      if (confirmationValue) {
        this.initialize();
      } else {
        this.router.navigate(['/']);
      }
    });
  }

  private playerAlreadyJoinedHandler(player: AppPlayer): void {
    this.displayGeneralDialog(`${player.username},`, 'Esti deja intr-un joc, nu poti juca simultan 2 jocuri diferite!', 10000, () => { this.router.navigate(['/']) });
  }

  private displayGeneralDialog(title: string, description: string, timeout: number = 0, callback: CallableFunction = null): void {
    const data: object = {
      title,
      description,
      okString: 'Ok'
    };
    const dialogRef = this.dialog.open(GeneralDialogComponent, { data });
    if (timeout > 0) {
      dialogRef.afterOpened().subscribe(() => {
        setTimeout(() => {
          dialogRef.close();
        }, timeout);
      });
      if (callback) {
        dialogRef.afterClosed().subscribe(() => {
          callback();
        });
      }
    }
  }

  private dealerChosen(dealer: AppPlayer, isFirstDealer: boolean): void {
    this.gameStarted = true;
    if (GameUtilsService.isPlayerRightToDealer(this.gameService.player, dealer.tablePosition)) {
      const subscription: Subscription = this.dialog.afterAllClosed.subscribe(() => {
        const dialogRef = this.dialog.open(BateTaieDialogComponent, {
          data: {
            playerName: this.gameService.getUsername(),
            dealerName: dealer.username
          }
        });
        dialogRef.beforeClosed().subscribe(() => {
          subscription.unsubscribe();
        });
        dialogRef.afterClosed().subscribe((bateState: boolean) => {
          this.gameService.sendBateTaieState(bateState ? BateTaie.Bate : BateTaie.Taie);
        });
      });
    }
  }

  private frisRequested(): void {
    const colors: CardColor[] = this.gameService.getFrisCombinations();

    const dialogRef = this.dialog.open(FrisDialogComponent, {
      data: {
        tromfColors: colors,
        playerName: this.gameService.getUsername()
      }
    });
    dialogRef.afterClosed().subscribe((data: { playsFris: boolean, tromfColor: CardColor }) => {
      if (data) {
        this.gameService.handleFrisState(data.playsFris);
        this.gameService.setTromfColor(data.tromfColor);
      }
    });
  }

  private playerDisconnectedHandler(player: AppPlayer): void {
    if (!this.gameService.isGameStarted) {
      return;
    }
    this.dialog.closeAll();
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Jucator deconectat',
        description: `Jucatorul ${player.username} a parasit jocul. Doresti sa incepi un nou joc?`,
        yesString: 'Da',
        noString: 'Nu'
      }
    });
    dialogRef.afterClosed().subscribe((confirmationValue: boolean) => {
      if (confirmationValue) {
        this.initialize();
      } else {
        this.router.navigate(['/']);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.gameService.disconnect(false);
  }
}
