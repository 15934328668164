export class ContactMessageModel {
    public name;
    public email;
    public subject;
    public message;

    constructor(name?: string, email?: string, subject?: string, message?: string) {
        this.name = name;
        this.email = email;
        this.subject = subject;
        this.message = message;
    }
}