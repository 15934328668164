<ng-container *ngIf="!(isClosing$ | async)">
  <h1 mat-dialog-title>{{this.data.playerName}},</h1>
  <div mat-dialog-content>
    <p>Jucatorul {{this.data.dealerName}} a fost ales dealer si ti-a dat pachetul pentru a il bate sau taia. Ce alegi sa
      faci?</p>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="true">Bat</button>
    <button mat-button [mat-dialog-close]="false" cdkFocusInitial>Tai</button>
  </div>
</ng-container>