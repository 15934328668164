import { BackgroundStyle } from "./background"
import { MenuItem } from "./menu-item"
import { Permission } from "./permission"
import { Roles } from "./permissions.enum"

export const ANONYMOUS_USER_ID = "anonymousUserId"

export const CROP_SIZE = 200

export const enum LocalStorageIndex {
    CURRENT_USER = "currentUser",
    TOKEN = "token"
}

export const SIGNALR_PATH = "/game"

export const enum PAGES {
    LandingPage = "LandingPageComponent",
    ProfilePage = "ProfilePageComponent",
    AboutPage = "AboutPageComponent",
    ContactPage = "ContactPageComponent",
    NotFoundPage = "NotFoundPageComponent",
    LoginPage = "LoginComponent",
    RegisterPage = "RegisterComponent",
    ForgotPasswordPage = "ForgotPasswordComponent",
    ResendConfirmationPage = "ResendConfirmationComponent",
    ResetPasswordPage = "ResetPasswordComponent",
    AccountConfirmationPage = "ConfirmAccountComponent",
    PasswordConfirmationPage = "ConfirmPasswordComponent",
    AccountConfirmingPage = "AccountConfirmingComponent",
    PasswordConfirmingPage = "PasswordConfirmingComponent",
    DonationsPage = "DonationsPageComponent",
    AdminPage = "AdminPageComponent",
}

export const PROFILE = [
    new MenuItem("/profile", "Profil", PAGES.ProfilePage),
]

export const MENU = [
    new MenuItem("", "Acasă", PAGES.LandingPage),
    new MenuItem("/despre", "Despre", PAGES.AboutPage),
    new MenuItem("/contact", "Contact", PAGES.ContactPage),
    new MenuItem("/donatii", "Donații", PAGES.DonationsPage),
    new MenuItem("/admin", "Administrare", PAGES.AdminPage),
]

export const LINKS = []

export const PAGE_BACKGROUNDS = [
    new BackgroundStyle(PAGES.LandingPage, "bg-dark-gray", "nav-bg-dark"),
    new BackgroundStyle(PAGES.ProfilePage, "bg-light-gray", "nav-bg-white"),
    new BackgroundStyle(PAGES.AboutPage, "bg-light-gray", "nav-bg-white"),
    new BackgroundStyle(PAGES.NotFoundPage, "bg-light-gray", "nav-bg-dark"),
    new BackgroundStyle(PAGES.ContactPage, "bg-light-gray", "nav-bg-white"),
    new BackgroundStyle(PAGES.LoginPage, "bg-dark-gray", "nav-bg-dark"),
    new BackgroundStyle(PAGES.RegisterPage, "bg-dark-gray", "nav-bg-dark"),
    new BackgroundStyle(PAGES.ResendConfirmationPage, "bg-dark-gray", "nav-bg-dark"),
    new BackgroundStyle(PAGES.ForgotPasswordPage, "bg-dark-gray", "nav-bg-dark"),
    new BackgroundStyle(PAGES.ResetPasswordPage, "bg-dark-gray", "nav-bg-dark"),
    new BackgroundStyle(PAGES.AccountConfirmationPage, "bg-dark-gray", "nav-bg-dark"),
    new BackgroundStyle(PAGES.PasswordConfirmationPage, "bg-dark-gray", "nav-bg-dark"),
    new BackgroundStyle(PAGES.PasswordConfirmingPage, "bg-dark-gray", "nav-bg-dark"),
    new BackgroundStyle(PAGES.AccountConfirmingPage, "bg-dark-gray", "nav-bg-dark"),
    new BackgroundStyle(PAGES.AdminPage, "bg-light-gray", "nav-bg-white"),
    new BackgroundStyle(PAGES.DonationsPage, "bg-light-gray", "nav-bg-white"),
]

export const PERMISSIONS = [
    new Permission(PAGES.ProfilePage, [Roles.Player, Roles.Admin]),
    new Permission(PAGES.AdminPage, [Roles.Admin])
]