import { Pipe, PipeTransform } from '@angular/core';
import { CardImageCacheService } from '../services/card-image-cache.service';

@Pipe({
  name: 'cardImage',
  pure: true
})
export class CardImagePipe implements PipeTransform {

  constructor(private cardImageService: CardImageCacheService) {

  }

  transform(cardName: string): string {
    return this.cardImageService.getCardImage(cardName);
  }

}
