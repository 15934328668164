import { Injectable } from "@angular/core";
import { HttpClient, HttpResponse } from "@angular/common/http";
import { first, map } from "rxjs/operators";
import { BaseService } from "./base.service";
import { RegisterModel } from "../models/register.model";
import { LoginModel } from "../models/login.model";
import { User } from "../models/user.model";
import { ResetPasswordModel } from "../models/reset-password.model";
import { LogService } from './log.service';
import { UserService } from "./user.service";

@Injectable({
  providedIn: "root"
})
export class AuthService extends BaseService {
  constructor(http: HttpClient, logService: LogService, private userService: UserService) {
    super(http, logService);
  }

  resetPassword(reset: ResetPasswordModel) {
    return this.http.post(
      this.apiUrl + "api/authentication/recover",
      JSON.stringify({
        recoveryToken: reset.rtoken,
        userToken: reset.utoken,
        password: reset.password,
        confirmPassword: reset.confirmPassword
      }),
      { headers: this.getCommonUnauthorizedHeaders() }
    );
  }

  recoverPassword(email: string) {
    return this.http.post(
      this.apiUrl + "api/authentication/sendRecoveryEmail",
      JSON.stringify({
        emailAddress: email
      }),
      { headers: this.getCommonUnauthorizedHeaders() }
    );
  }

  register(user: RegisterModel) {
    return this.http.post<any>(
      this.apiUrl + "api/authentication/register",
      JSON.stringify({
        firstname: user.firstname,
        lastname: user.lastname,
        username: user.username,
        email: user.email,
        password: user.password,
        confirmPassword: user.confirmPassword,
        userKey: user.userKey
      }),
      { headers: this.getCommonUnauthorizedHeaders(), observe: "response" }
    )
      .pipe(
        map(response => this.setCurrentUser(response))
      );
  }

  confirmAccount(utoken: string, ctoken: string) {
    return this.http.post<void>(
      this.apiUrl + 'api/authentication/confirmEmail',
      JSON.stringify({
        userToken: utoken,
        confirmationToken: ctoken
      }),
      { headers: this.getCommonUnauthorizedHeaders(), observe: 'response' }
    )
  }

  resendConfirmationEmail(email: string) {
    return this.http.post<void>(
      this.apiUrl + 'api/authentication/resendConfirmation',
      JSON.stringify(email),
      { headers: this.getCommonUnauthorizedHeaders(), observe: 'response' }
    )
  }

  login(user: LoginModel) {
    return this.http
      .post<any>(
        this.apiUrl + "api/authentication/login",
        JSON.stringify({
          username: user.username,
          password: user.password
        }),
        { headers: this.getCommonUnauthorizedHeaders(), observe: "response" }
      )
      .pipe(
        first(),
        map(response => this.setCurrentUser(response))
      );
  }

  logout() {
    this.userService.currentUser = undefined;
    this.token = undefined;
    this.clearLocalStorage();
  }

  private setCurrentUser(response: HttpResponse<any>): User {
    if (response && response.body && response.headers && response.headers.has("authorization")) {
      const token = response.headers.get("authorization");
      const userMapping = new User(
        response.body.id,
        response.body.username,
        response.body.name,
        response.body.surname,
        response.body.email,
        response.body.avatarUrl,
      );
      this.token = token;
      this.userService.currentUser = userMapping;
      return userMapping;
    }
    return undefined;
  }
}
