import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { CanDeactivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

export interface CanComponentDeactivate {
  canDeactivate: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
  providedIn: 'root'
})
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {

  constructor(
    private readonly location: Location,
    private readonly router: Router
  ) { }

  public canDeactivate(
    component: CanComponentDeactivate,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let deactivateValue: boolean | Promise<boolean> | Observable<boolean> = component.canDeactivate ? component.canDeactivate() : true;
    /*Hopefully this will be fixed at some point*/
    if (deactivateValue instanceof Promise) {
      let deactivatePromise: Promise<boolean> = deactivateValue as Promise<boolean>;
      deactivatePromise.then(value => this.fixNavigationHistory(value, currentRoute));
    } else if (deactivateValue instanceof Observable) {
      let deactivateObservable: Observable<boolean> = deactivateValue as Observable<boolean>;
      let deactivateSub: Subscription = deactivateObservable.subscribe(value => {
        this.fixNavigationHistory(value, currentRoute);
        deactivateSub.unsubscribe();
      });
    } else {
      this.fixNavigationHistory(deactivateValue, currentRoute);
    }
    return deactivateValue;
  }

  private fixNavigationHistory(deactivateValue: boolean, currentRoute: ActivatedRouteSnapshot): void {
    if (!deactivateValue) {
      const currentUrlTree = this.router.createUrlTree([], currentRoute);
      const currentUrl = currentUrlTree.toString();
      this.location.go(currentUrl);
    }
  }
}
