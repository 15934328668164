import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Inject, Injectable, Injector } from "@angular/core";
import { Router } from "@angular/router";
import { ToastrService } from "ngx-toastr";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ErrorInterceptorHttpParams } from "./error-interceptor-http-params";
import { AuthService } from "../services/auth.service";
import { LogService } from "../services/log.service";

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(
    @Inject(Injector) private injector: Injector,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly logService: LogService
  ) { }

  // Need to get ToastrService from injector rather than constructor injection to avoid cyclic dependency error
  private get toastr(): ToastrService {
    return this.injector.get(ToastrService);
  }

  private shouldIgnoreRequest(request: HttpRequest<any>, errorResponse: HttpErrorResponse): boolean {
    if (request.params instanceof ErrorInterceptorHttpParams) {
      const errorParams = request.params as ErrorInterceptorHttpParams;
      if (
        Array.isArray(errorParams.errorConfig.failSilent) &&
        errorParams.errorConfig.failSilent.includes(errorResponse.status)
      ) {
        return true;
      }
    }
    return false;
  }

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {

    return next.handle(request).pipe(
      catchError((errorResponse: HttpErrorResponse) => {

        this.logService.error('Error from server error handler', errorResponse);

        if (this.shouldIgnoreRequest(request, errorResponse)) {
          return throwError(errorResponse);;
        }

        const error = errorResponse.error;

        switch (errorResponse.status) {
          case 400:
            if (error.description) {
              this.toastr.error(error.description);
            }
            else if (error instanceof Array) {
              error.forEach(err => {
                this.toastr.error(err.description);
              });
            }
            else if ((typeof error) === 'string') {
              const error = JSON.parse(errorResponse.error);
              this.toastr.error(error.description);
            }
            else {
              this.toastr.error("Cerere invalidă", `Eroare ${errorResponse.status.toString()}`);
            }

            break;
          case 401:
            this.toastr.error("Nu esti autentificat");
            this.authService.logout();
            this.router.navigate(['/login']);
            break;
          case 403:
            this.toastr.error("Nu ai permisiuni pentru aceasta cerere", `Eroare ${errorResponse.status.toString()}`);
            this.authService.logout();
            break;
          case 404:
            this.toastr.error("Cererea nu a fost gasită", `Eroare ${errorResponse.status.toString()}`);
            break;
          case 500:
            if (error.description) {
              this.toastr.error(error.description);
            } else {
              this.toastr.error("Eroare internă de server", `Eroare ${errorResponse.status.toString()}`);
            }
            break;
          case 503:
            this.toastr.error("Serviciul nu este disponibil", `Eroare ${errorResponse.status.toString()}`);
            break;
          default:
            this.toastr.error("Eroare de server nedefinită", `Eroare ${errorResponse.status?.toString()}`);
            break;
        }

        return throwError(errorResponse);
      })
    ) as Observable<HttpEvent<any>>;
  }
}