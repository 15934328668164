import { Pipe, PipeTransform } from '@angular/core';
import { PERMISSIONS } from 'src/app/common/constants';
import { MenuItem } from 'src/app/common/menu-item';
import { UserService } from '../services/user.service';

@Pipe({
    name: 'permission',
    pure: false
})
export class PermissionPipe implements PipeTransform {

    constructor(private readonly userService: UserService) {

    }

    transform(items: Array<MenuItem>): Array<MenuItem> {
        return items.filter(item => this.canAccess(item));
    }

    private canAccess(item: MenuItem): boolean {
        const permission = PERMISSIONS.find(permission => permission.page === item.page);

        if (permission) {
            return permission.access.some(role => this.userService.userRoles?.indexOf(role) > -1);
        }

        return true;
    }

}