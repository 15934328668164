import { Component, OnInit, Input, OnDestroy, Output } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { trigger, state, transition, animate, style } from '@angular/animations';
import { TimerWrapper } from 'src/app/common/timer-wrapper';

@Component({
  selector: 'app-game-timer',
  templateUrl: './game-timer.component.html',
  styleUrls: ['./game-timer.component.scss'],
  animations: [
    trigger('rotationTrigger', [
      state('start', style({
        transform: 'rotate(0deg)'
      })),
      state('stop', style({
        transform: 'rotate(360deg)'
      })),
      state('reset', style({
        transform: 'rotate(0deg)'
      })),
      transition('start => stop', [
        animate('{{timeout}}ms')
      ], { params: { delay: TimerWrapper.defaultTimeout } }),
      transition('stop => start', [
        animate(0)
      ]),
      transition('reset => *', [
        animate(0)
      ])
    ]),
    trigger('fillerTrigger', [
      state('displayed', style({
        opacity: 1
      })),
      state('hidden', style({
        opacity: 0
      })),
      transition('diplayed => hidden', [
        animate(0)
      ]),
      transition('hidden => displayed', [
        animate(0)
      ])
    ]),
    trigger('maskTrigger', [
      state('displayed', style({
        opacity: 1
      })),
      state('hidden', style({
        opacity: 0
      })),
      transition('diplayed => hidden', [
        animate(0)
      ]),
      transition('hidden => displayed', [
        animate(0)
      ])
    ])
  ]
})
export class GameTimerComponent implements OnInit, OnDestroy {

  @Input() public timer: TimerWrapper;

  private subscriptions: Subscription;
  public rotationTriggerState: string = 'start';
  public fillerTriggerState: string = 'displayed';
  public maskTriggerState: string = 'hidden';

  constructor() { }

  ngOnInit(): void {
    this.subscriptions = new Subscription();
    this.subscriptions.add(this.timer.timerStarted.subscribe(() => this.timerStarted()));
    this.subscriptions.add(this.timer.timerStopped.subscribe(() => this.timerStopped()));
    this.subscriptions.add(this.timer.timedOut.subscribe(() => this.timerTimedOut()));
    this.subscriptions.add(this.timer.timerReset.subscribe(() => this.timerReset()));
  }

  private timerStarted(): void {
    this.rotationTriggerState = 'stop';
  }

  private timerStopped(): void {
    this.rotationTriggerState = 'start';
  }

  private timerTimedOut(): void {

  }

  private timerReset(): void {
    this.rotationTriggerState = 'start';
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
