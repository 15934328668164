import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { BaseService } from "./base.service";
import { map } from "rxjs/operators";
import { User } from "../models/user.model";
import { Observable } from 'rxjs';
import { LogService } from './log.service';
import { UserService } from "./user.service";
import { ErrorInterceptorHttpParams } from "../errors/error-interceptor-http-params";
import { Match } from "../models/match.model";

@Injectable({
  providedIn: "root"
})
export class ProfileService extends BaseService {
  constructor(http: HttpClient, logService: LogService, private userService: UserService) {
    super(http, logService);
  }

  public updateProfileImage(imageData: string): Observable<string> {
    const imageInfo: string[] = imageData.split(',');
    return this.http
      .put(this.apiUrl + "api/userProfile/updateAvatar", imageInfo[1], {
        headers: this.getCommonHeaders(this.extractDataType(imageInfo[0])),
        responseType: "text",
        observe: "body"
      })
  }

  // TODO: Improvement - Return from the server the updated user. Right now the current user is updated based on the information provided in the ui.
  public updateProfile(user: User): Observable<unknown> {
    return this.http.put(
      this.apiUrl + "api/userProfile",
      JSON.stringify({
        name: user.name,
        surname: user.surname,
        email: user.email,
        username: user.username
      }),
      { headers: this.getCommonHeaders() }
    );
  }

  public getPendingGameInvites(): Observable<Match[]> {
    return this.http.get<Match[]>(
      this.apiUrl + "api/userProfile/getPendingGameInvites",
      { headers: this.getCommonHeaders() }
    );
  }

  public me(failSilently: boolean = false): Observable<User> {
    return this.http
      .get<User>(this.apiUrl + "api/userProfile", {
        headers: this.getCommonHeaders(),
        observe: "body",
        params: failSilently ? new ErrorInterceptorHttpParams({ failSilent: [401] }) : {}
      })
      .pipe(
        map(response => {
          if (response) {
            // *FYI: The user id is not returned from the server. If needed modify on BE.
            this.userService.currentUser = { ...response };
            return this.userService.currentUser;
          }
          return undefined;
        })
      );
  }

  private extractDataType(base64Type: string): string {
    return base64Type.split(';')[0].split(':')[1];
  }
}
