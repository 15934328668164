<div class="container-fluid d-flex flex-column justify-content-between h-100 align-items-center overflow"
  [ngClass]="componentBackgroundColor">

  <header class="row">
    <div class="col-12">
      <app-navbar [backgroundColor]="navBackgroundColor" [displayLogin]="false">
      </app-navbar>
    </div>
  </header>

  <main class="row">
    <div class="col-12">
      <router-outlet (activate)="onRouterOutletActivate($event)"></router-outlet>
    </div>
  </main>

  <app-footer-layout></app-footer-layout>
</div>
<ngx-spinner bdColor="rgba(255, 255, 255, 0.05)" size="large" color="#e6e6e6" type="ball-clip-rotate"
  [fullScreen]="true">
  <h4 style="color: white"> Confirmare... </h4>
</ngx-spinner>