export function isStringNullOrEmpty(value: string): boolean {
    return value == undefined || value == null || value == "undefined" || value == "null" || value == "";
}

export function isStringNullOrWhiteSpace(value: string): boolean {
    if (isStringNullOrEmpty(value)) {
        return true;
    }
    return (isStringNullOrEmpty(value.trim()));
}
