import { Component, OnInit, ViewChild, Output, EventEmitter, ElementRef } from "@angular/core";
import { ImageCropperComponent, CropperSettings } from 'ngx-img-cropper';
import { CROP_SIZE } from 'src/app/common/constants';
import { CropperResult } from '../../../../core/models/cropper-result.model';

@Component({
  selector: "app-cropper",
  templateUrl: "./cropper.component.html",
  styleUrls: ["./cropper.component.scss"]
})
export class CropperComponent implements OnInit {

  @ViewChild('cropper', { static: true }) cropper: ImageCropperComponent;
  @ViewChild('fileInput') fileInput: ElementRef;
  public cropperSettings: CropperSettings;
  data: any;
  file: File;

  @Output() onUpload: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
    this.data = {};
    this.cropperSettings = new CropperSettings();
    this.cropperSettings.width = CROP_SIZE;
    this.cropperSettings.height = CROP_SIZE;
    this.cropperSettings.croppedWidth = CROP_SIZE;
    this.cropperSettings.croppedHeight = CROP_SIZE;
    this.cropperSettings.noFileInput = true;
  }

  fileChangeListener($event) {
    var image: any = new Image();
    this.file = $event.target.files[0];
    var myReader: FileReader = new FileReader();
    myReader.onloadend = (loadEvent: any) => {
      image.src = loadEvent.target.result;
      this.cropper.setImage(image);
    };
    myReader.readAsDataURL(this.file);
  }

  upload() {
    if (this.data.image != undefined) {
      this.onUpload.emit(new CropperResult(this.file, this.data.image));
    }
    this.cropper.reset();
    this.fileInput.nativeElement.value = "";
  }

  onModalOutside(args) {
    if (args.target !== args.currentTarget) {
      return;
    }
    this.cropper.reset();
    this.fileInput.nativeElement.value = "";
  }

}
