import { Injectable, Output } from "@angular/core";
import { ANONYMOUS_USER_ID, SIGNALR_PATH } from '../../app/common/constants';
import { Position } from '../../core/models/generic.model';
import { AppPlayer, AppPlayerConverter, AppPlayerDto } from '../models/app-player.model';
import { Subject, BehaviorSubject } from 'rxjs';
import { Card, CardColor, CardName, CardDto, CardConverter } from '../models/game/card.model';
import { TromfCombination, BateTaie, TromfCombinationsUtils } from '../models/game/game.model';
import {
  GameState, WaitingForPartyState, BateState, TaieState, ChoosingDealerState,
  LegareaTromfuluiState, CeaIesitMicaWinnerState, BateOrTaieState, CeaIesitMicaState,
  FrisState, FrisWinnerState, InGameState, GameEndedState, GameWinnerState
} from '../models/game/game-state.model';
import { GameUtilsService } from './game-utils.service';
import { TimerWrapper } from '../../app/common/timer-wrapper';
import { GameScore, MatchScore } from '../models/game/score.model';
import { LogService } from './log.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from "./user.service";
import { MatDialog } from "@angular/material/dialog";
import { HubConnection, HubConnectionBuilder, HubConnectionState } from "@microsoft/signalr";
import { DialogModel } from "../models/game/dialog.model";
import { GameEndDialogData } from "src/app/game/dialogs/game-end-dialog/game-end-dialog.component";
import { MatchEndDialogData } from "src/app/game/dialogs/match-ended-dialog/match-ended-dialog.component";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: 'root'
})
export class GameService {

  private hubConnection: HubConnection;
  public gameId: string;
  public player: AppPlayer;
  public tromf: CardColor;
  public cardsOnTable: { tablePosition: number, card: Card }[];
  public dealer: AppPlayer;
  public gameState: GameState;
  private players: AppPlayer[] = [undefined, undefined, undefined, undefined];
  private voluntaryClosing: boolean = false;
  private roundPosition: Position;
  private roundStartingPosition: Position;
  private roundCount: number; // turn in game
  private gameCount: number; // games in match, starting with 1 as first game
  private roundColor: CardColor = CardColor.Hidden;
  private playerOffset: number;
  private availableTromfCombination: TromfCombination = TromfCombination.Pas;
  private currentTromfCombination: TromfCombination = TromfCombination.Pas;
  private currentTromfCards: Card[] = [];
  private timerWrapper: TimerWrapper = new TimerWrapper();
  private pendingMessages: { method: string, args: any[] }[];
  private timeout: NodeJS.Timeout;
  private storage: Storage = localStorage;
  private joinTimeout = 30000; //5 minutes to join a game
  private gameMethods: string[] = [
    'playerJoined', 'cardsDealt', 'allPlayersJoined',
    'canFris', 'ceaIesitMica', 'ceaIesitMicaValue', 'ceaIesitMicaWinner',
    'dealerChosen', 'isPlayingFris', 'choosingFirstDealer',
    'playerLeft', 'waitingForParty', 'frisWinner',
    'frisAnnounced', 'playerExists', 'gameWinner',
    'matchEnded', 'cardPlayed', 'tieWinner',
    'tiePlayed', 'wrongCard', 'choosingNextDealer',
    'gameTie', 'invalidGame'
  ];

  @Output() playerJoined: Subject<AppPlayer> = new Subject<AppPlayer>();
  @Output() dealerDecided: Subject<{ dealer: AppPlayer, isFirstDealer: boolean }> = new Subject<{ dealer: AppPlayer, isFirstDealer: boolean }>();
  @Output() cardDealt: Subject<Card> = new Subject<Card>();
  @Output() playerDisconnected: Subject<AppPlayer> = new Subject<AppPlayer>();
  @Output() frisRequested: Subject<void> = new Subject<void>();
  @Output() dialogReceived: Subject<DialogModel> = new Subject<DialogModel>();
  @Output() lostConnection: Subject<boolean> = new Subject<boolean>();
  @Output() joiningTimedOut: Subject<void> = new Subject<void>();
  @Output() ceaIesitMicaAnnounced: Subject<void> = new Subject<void>();
  @Output() cardPlayed: Subject<{ card: Card, from: number }> = new Subject<{ card: Card, from: number }>();
  @Output() roundWon: Subject<{ position: Position, timeout: number }> = new Subject<{ position: Position, timeout: number }>();
  @Output() gameEnded: Subject<GameEndDialogData> = new Subject<GameEndDialogData>();
  @Output() playerTurn: Subject<number> = new Subject<number>();
  @Output() gameScoreUpdated: Subject<{ gameScore: GameScore, selfScore: number }> = new Subject<{ gameScore: GameScore, selfScore: number }>();
  @Output() legareaTromfuluiPossible: Subject<{ tromfPossible: boolean, tromfCombinations: TromfCombination }> = new Subject<{ tromfPossible: boolean, tromfCombinations: TromfCombination }>();
  @Output() playerAlreadyJoined: Subject<AppPlayer> = new Subject<AppPlayer>();
  @Output() matchEnded: Subject<MatchEndDialogData> = new Subject<MatchEndDialogData>();
  @Output() invalidGame: Subject<string> = new Subject<string>();
  @Output() waitingPlayerInput: BehaviorSubject<{ tablePosition: number, isWaiting: boolean }> = new BehaviorSubject<{ tablePosition: number, isWaiting: boolean }>({ tablePosition: -1, isWaiting: false });
  @Output() tromfColorAnnounced: Subject<boolean> = new Subject<boolean>();
  @Output() cardsOnTableCleared: Subject<void> = new Subject<void>();
  @Output() timeoutReset: Subject<void> = new Subject<void>();

  constructor(public logService: LogService,
    public dialog: MatDialog,
    private userService: UserService,
    private activatedRoute: ActivatedRoute) {
  }

  public initialize(gameId?: string): void {
    this.pendingMessages = [];
    this.timeout = setTimeout(() => {
      this.processPendingMessages();
    }, 500);

    this.gameId = gameId;

    this.hubConnection = new HubConnectionBuilder().withUrl(`${SIGNALR_PATH}?token=${this.token}`).build();
    this.hubConnection.onclose(err => {
      if (this.isConnectionVoluntarilyClosed) {
        this.logService.warn(`Game connection closed in a voluntary manner or is already in a disconnected state!`);
      } else {
        if (err) {
          this.logService.error(`Game connection closed unexpectedly with the following error: ${err}`);
        } else {
          this.logService.error(`Game connection closed unexpectedly in the connected state without an error message!`);
        }
        this.connectionLost(false);
      }
    });
    this.addListeners();
    this.gameState = new WaitingForPartyState(this);
    this.hubConnection
      .start()
      .then(() => this.gameConnectionSuccessful(), (reason) => this.gameConnectionFailed(reason));
    this.roundCount = 0;
    for (let i = 0; i < this.players.length; i++) {
      if (this.players[i]) {
        this.players[i].wonCards = [];
        this.players[i].isPlayingFris = false;
      }
    }
    this.voluntaryClosing = false;
    this.availableTromfCombination = TromfCombination.Pas;
    this.currentTromfCombination = TromfCombination.Pas;
  }

  private gameConnectionFailed(reason: any): void {
    this.logService.error(`Connection to the backend game hub failed with the following error: ${reason}`);
    this.connectionLost(true);
  }

  private gameConnectionSuccessful(): void {
    this.logService.log('Connection succesfully started!');
    this.sendJoinRequest(this.gameId);
  }

  public get hubState(): HubConnectionState {
    return this.hubConnection.state;
  }

  public get isConnectionVoluntarilyClosed(): boolean {
    return this.voluntaryClosing;
  }

  public get timer(): TimerWrapper {
    return this.timerWrapper;
  }

  public get isGameRunning(): boolean {
    return this.gameState instanceof InGameState || this.gameState instanceof GameEndedState || this.gameState instanceof GameWinnerState;
  }

  public get isGameStarted(): boolean {
    return !(this.gameState instanceof WaitingForPartyState) && !(this.gameState instanceof GameEndedState);
  }

  public get isMatchEnded(): boolean {
    return this.gameState instanceof GameEndedState;
  }

  private connectionLost(connectionStart: boolean): void {
    this.waitingPlayerInput.next({ tablePosition: -1, isWaiting: false });
    this.lostConnection.next(connectionStart);
  }

  public getCurrentPlayer(): AppPlayer {
    return this.player;
  }

  setCurrentPlayer(player: AppPlayer): void {
    this.player = player;
  }

  public setWaitingPlayerState(tablePosition: number, isWaiting: boolean): void {
    this.waitingPlayerInput.next({ tablePosition, isWaiting });
  }

  public getFrisCombinations(): CardColor[] {
    const cardsInHand: Card[] = this.player.cards;
    const colors: CardColor[] = [];
    if (cardsInHand.length !== 3) {
      return undefined;
    }
    const filcauCount: number = cardsInHand.filter(card => card.name === CardName.Filcau).length;
    switch (filcauCount) {
      case 1:
        const otherCards: Card[] = cardsInHand.filter(card => card.name !== CardName.Filcau);
        if (otherCards.length !== 2) {
          return undefined;
        }
        if (otherCards[0].color === otherCards[1].color) {
          colors.push(otherCards[0].color);
        }
        break;
      case 2:
        colors.push(cardsInHand.filter(card => card.name !== CardName.Filcau)[0].color);
        break;
      case 3:
        colors.push(cardsInHand[0].color);
        colors.push(cardsInHand[1].color);
        colors.push(cardsInHand[2].color);
        break;
    }
    return colors;
  }

  public getAllPlayers(): AppPlayer[] {
    return this.players;
  }

  public resetTimeout(): void {
    this.timeoutReset.next();
  }

  public getServerPositionFromLocalPosition(localPosition: Position): Position {
    const localPos: number = localPosition - 1;
    return ((localPos + this.playerOffset) % 4) + 1;
  }

  public getLocalPositionFromServerPosition(serverPosition: Position): Position {
    let serverPos: number = serverPosition - 1;
    serverPos -= this.playerOffset;
    if (serverPos < 0) {
      serverPos += 4;
    }
    return (serverPos % 4) + 1;
  }

  public getArrayPositionFromLocalPosition(localPosition: Position): number {
    const localPos: number = localPosition - 1;
    let serverPos: number = 0;

    serverPos = ((localPos + this.playerOffset) % 4) + 1;
    for (let i = 0; i < 4; i++) {
      if (this.players[i].tablePosition === serverPos) {
        return i;
      }
    }
    this.logService.error('Unable to find the player in the array of players! Returning position -1!');
    return -1;
  }

  private getArrayPositionFromTablePosition(tablePosition: Position): number {
    for (let i = 0; i < 4; i++) {
      if (this.players[i].tablePosition === tablePosition) {
        return i;
      }
    }
    this.logService.error('Unable to find the player in the array of players! Returning position -1!');
    return -1;
  }

  private handleSendFailure(methodName: string, error: any): void {
    this.logService.error(methodName + ' request failed, reason: ' + error);
  }

  public getUsername() {
    if (this.player != null || this.player !== undefined) {
      return this.player.username;
    }
    return this.userService.currentUser != null ? this.userService.currentUser.username : null;
  }

  /*Send methods*/

  public sendBateTaieState(bateTaieState: BateTaie): void {
    this.changeToState(bateTaieState === BateTaie.Bate ? new BateState(this) : new TaieState(this));
    this.hubConnection
      .invoke('BateTaie', BateTaie[bateTaieState])
      .catch(err => this.handleSendFailure('BateTaie', err));
  }

  public sendFrisState(frisState: boolean): void {
    this.hubConnection
      .invoke('CanPlayFris', frisState)
      .catch(err => this.handleSendFailure('CanPlayFris', err));
  }

  public sendJoinRequest(gameId: string): void {
    let anonymousId = this.storage.getItem(ANONYMOUS_USER_ID);
    anonymousId = anonymousId === 'undefined' || anonymousId === 'null' ? undefined : anonymousId;
    this.hubConnection
      .invoke('Join', gameId, anonymousId)
      .catch(err => this.handleSendFailure('Join', err));
  }

  public handleFrisState(playsFris: boolean) {
    this.dialogReceived.next({ message: playsFris ? "Fris" : "Pas", position: this.player.tablePosition, withTimeout: true });
    this.resetTimeout();
    this.sendFrisState(playsFris);
    this.player.isPlayingFris = playsFris;
  }

  disconnect(withReinitialize: boolean, gameId?: string) {
    if (this.hubConnection && this.hubConnection.state === HubConnectionState.Connected) {
      this.dialog.closeAll();
      if (this.isMatchEnded) {
        this.voluntaryClosing = true;
      }
      this.hubConnection
        .invoke('Disconnect')
        .catch(err => this.logService.log(`Disconnect failed! Reason: ${err}`))
        .finally(() => {
          this.cleanGameState();
          if (withReinitialize) {
            this.hubConnection.onclose(() => { this.initialize(gameId) });
          }
        });
    } else {
      this.cleanGameState();
      if (withReinitialize) {
        this.initialize(gameId);
      }
    }
  }

  public changeToState(newState: GameState): void {
    this.gameState = newState;
  }

  public playingFris(): void {
    const smallestCard: Card = GameUtilsService.getSmallestCardByAbsoluteValue(this.player.cards);
    this.player.cards.forEach(card => {
      if (this.player.isPlayingFris && card.equals(smallestCard)) {
        card.setValid(true);
      } else {
        card.setValid(false);
      }
    });
    this.players.forEach(player => {
      if (player.isPlayingFris) {
        this.waitingPlayerInput.next({ tablePosition: player.tablePosition, isWaiting: true });
      }
    });
  }

  public legareaTromfuluiChosen(): void {
    const highestCombo = GameUtilsService.getHighestTromfCombination(this.currentTromfCombination);
    this.resetTimeout();
    this.hubConnection
      .invoke('Tie', highestCombo, GameUtilsService.getTromfCombinationColor(this.currentTromfCards))
      .catch(err => { this.logService.error(err); });
    this.player.tromfCombination = highestCombo;
    const pos: number = GameUtilsService.advanceRoundPosition(this.player.tablePosition);
    this.waitingPlayerInput.next({ tablePosition: -1, isWaiting: false });
    this.dialogReceived.next({ message: GameUtilsService.legareaTromfuluiNameDictionary.get(highestCombo), position: this.player.tablePosition, withTimeout: false });
    if (pos !== this.dealer.tablePosition) {
      this.waitingPlayerInput.next({ tablePosition: pos, isWaiting: true });
    }
    this.player.cards.forEach(card => {
      card.setValid(false);
    });
  }

  public playCard(card: Card, tromfColor?: CardColor): void {
    this.hubConnection
      .invoke('PlayCard', CardConverter.toDto(card), tromfColor)
      .catch(err => this.handleSendFailure('PlayCard', err));
  }

  private dealerChosen(dealerTablePosition: number): void {
    for (let i = 0; i < this.players.length; i++) {
      if (this.players[i].tablePosition === dealerTablePosition) {
        this.dealer = this.players[i];
      }
    }
    this.dealerDecided.next({ dealer: this.dealer, isFirstDealer: this.gameCount === 1 });
  }

  public timerExpired(): void {
    if (environment.timeoutEnabled) {
      this.disconnect(false);
      this.lostConnection.next(false);
    }
  }

  public setTromfColor(tromfColor: CardColor): void {
    this.tromf = tromfColor;
    this.tromfColorAnnounced.next(tromfColor !== CardColor.Hidden);
  }

  public canFris(): void {
    this.frisRequested.next();
  }

  private async cardClicked(card: Card): Promise<void> {
    switch (this.gameState.getStateName()) {
      case ('CeaIesitMica'):
        this.playCard(card, this.tromf);
        card.setValid(false);
        break;
      case ('Fris'):
        if (this.cardsOnTable.length === 0) {
          this.roundColor = card.color;
        }
        this.playCard(card, this.tromf);
        this.playCardOnTable(card, this.player.tablePosition, false);
        for (let i = 0; i < this.player.cards.length; i++) {
          if (this.player.cards[i].equals(card)) {
            this.player.cards.splice(i, 1);
            break;
          }
        }
        this.waitingPlayerInput.next({ tablePosition: -1, isWaiting: false });
        if (this.cardsOnTable.length === 4) {
          this.player.cards.forEach(card => card.setValid(false));
        }
        const end = await this.advanceFrisPosition();
        if (!end) {
          this.waitingPlayerInput.next({ tablePosition: this.roundPosition, isWaiting: true });
        }
        break;
      case ('LegareaTromfului'):
        const highlightCards: Card[] = this.player.cards.filter(playerCard => playerCard.isHighlighted());
        const highlightedCards: Card[] = [];
        highlightedCards.push(...highlightCards);
        if (highlightedCards.length >= 3) {
          while (highlightedCards.length !== 5) {
            highlightedCards.push(new Card(CardColor.Hidden, CardName.Hidden));
          }
          let { tromfCombination, cardsInTromf } = GameUtilsService.getAllTromfCombination(highlightedCards);
          if (this.availableTromfCombination & tromfCombination) {
            if (GameUtilsService.isSingleTromfCombination(tromfCombination) && GameUtilsService.isTromfCombinationOfMinimumCount(tromfCombination, cardsInTromf)) {
              this.legareaTromfuluiPossible.next({ tromfPossible: true, tromfCombinations: tromfCombination });
            } else {
              const highestCombo = GameUtilsService.getHighestTromfCombination(tromfCombination);
              this.legareaTromfuluiPossible.next({ tromfPossible: highlightCards.length === GameUtilsService.tromfCombinationMinimumCards(highestCombo), tromfCombinations: highestCombo });
            }
            this.currentTromfCombination = tromfCombination;
            this.currentTromfCards = cardsInTromf;
          } else {
            this.legareaTromfuluiPossible.next({ tromfPossible: false, tromfCombinations: this.availableTromfCombination });
            this.currentTromfCombination = TromfCombination.Pas;
            this.currentTromfCards = [];
          }
        } else {
          if (highlightedCards.length === 0 && GameUtilsService.isBunaCombinationPossible(this.player.tablePosition, this.players) && !TromfCombinationsUtils.isEmpty(this.availableTromfCombination)) {
            this.legareaTromfuluiPossible.next({ tromfPossible: true, tromfCombinations: TromfCombination.Buna });
            this.currentTromfCombination = TromfCombination.Buna;
          } else {
            this.legareaTromfuluiPossible.next({ tromfPossible: false, tromfCombinations: this.availableTromfCombination });
            this.currentTromfCombination = TromfCombination.Pas;
          }
          this.currentTromfCards = [];
        }
        break;
      case ('InGame'):
        if (this.cardsOnTable.length === 0) {
          this.roundColor = card.color;
        }
        this.playCard(card, this.tromf);
        this.playCardOnTable(card, this.player.tablePosition, false);
        for (let i = 0; i < this.player.cards.length; i++) {
          if (this.player.cards[i].equals(card)) {
            this.player.cards.splice(i, 1);
            break;
          }
        }
        this.resetTimeout();
        for (let i = 0; i < this.player.cards.length; i++) {
          this.player.cards[i].setValid(false);
        }
        this.waitingPlayerInput.next({ tablePosition: -1, isWaiting: false });
        await this.advanceGamePosition();
        this.waitingPlayerInput.next({ tablePosition: this.roundPosition, isWaiting: true });
        break;
      default:
        break;
    }
  }

  private playCardOnTable(card: Card, tablePosition: Position, removeCard: boolean): void {
    this.cardPlayed.next({ card, from: this.player.tablePosition });
    this.cardsOnTable.push({ card, tablePosition });
    if (removeCard) {// only for non-self player
      const arrayPos: number = this.getArrayPositionFromLocalPosition(this.getLocalPositionFromServerPosition(tablePosition));
      const randPos: number = Math.round((Math.random() * (this.players[arrayPos].cards.length - 1)));
      if ((arrayPos > 3 || arrayPos < 0) || (randPos < 0 || randPos >= this.players[arrayPos].cards.length)) {
        this.logService.error(`Invalid values either for the player array position: ${arrayPos} or the random card postion: ${randPos}`);
        debugger;
      }
      this.players[arrayPos].cards.splice(randPos, 1);
    }
  }

  private async handleRoundEnd(): Promise<void> {
    const winningPos: number = GameUtilsService.getRoundWinner(this.cardsOnTable, this.tromf);
    this.roundStartingPosition = winningPos;
    this.roundPosition = winningPos;
    const arrayPos: number = this.getArrayPositionFromTablePosition(winningPos);
    this.roundWon.next({ position: this.getLocalPositionFromServerPosition(winningPos), timeout: 3000 });
    await this.delay(3000);
    for (let i = 0; i < this.cardsOnTable.length; i++) {
      this.players[arrayPos].wonCards.push(this.cardsOnTable[i].card);
    }
    this.cardsOnTable = [];
    this.cardsOnTableCleared.next();
    this.gameScoreUpdated.next(GameUtilsService.getGameScore(this.player, this.players, this.dealer.tablePosition % 2 == this.player.tablePosition % 2));
    if (!this.isGameEnded()) {
      this.roundCount++;
    }
  }

  private delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  // this method should be called only after the game is advanced to the correct position
  private handleCurrentGamePosition(): void {
    this.player.cards.forEach(card => {
      card.setValid(false);
    });
    if (this.roundPosition === this.player.tablePosition && !this.isGameEnded()) {
      const allowedCards: Card[] = GameUtilsService.getRoundAllowedCards(this.player.cards,
        this.roundCount,
        this.player.tablePosition === this.roundStartingPosition,
        this.tromf,
        this.roundColor,
        this.cardsOnTable.length > 0 ? this.cardsOnTable[0].card : undefined
      );
      if (allowedCards.length === 0 && this.player.cards.length !== 0) {
        this.logService.error(`No allowed cards to be played in this round, player has ${this.player.cards.length} in hand!`);
        debugger;
      }
      allowedCards.forEach(card => {
        card.setValid(true);
      });
    }
  }

  private handleFrisCurrentGamePosition(): void {
    this.player.cards.forEach(card => {
      card.setValid(false);
    });
    if (this.roundPosition === this.player.tablePosition) {
      const allowedCards: Card[] = GameUtilsService.getFrisAllowedCards(this.player.cards, this.player.tablePosition == this.roundStartingPosition);
      if (allowedCards.length === 0 && this.player.cards.length != 0) {
        this.logService.error(`No allowed cards to be played in this round, player has ${this.player.cards.length} in hand!`);
        debugger;
      }
      allowedCards.forEach(card => {
        card.setValid(true);
      });
    }
  }

  // we only take into consideration the case when a round ends after all 4 players dealt cards on the table (no continuation of existing round)
  private async advanceGamePosition(): Promise<void> {
    this.roundPosition = GameUtilsService.advanceRoundPosition(this.roundPosition);
    if (this.roundPosition === this.roundStartingPosition) {
      await this.handleRoundEnd();
    }
    this.handleCurrentGamePosition();
  }

  private async advanceFrisPosition(): Promise<boolean> {
    this.roundPosition = GameUtilsService.advanceRoundPosition(this.roundPosition);
    if (this.roundPosition === this.roundStartingPosition) {
      await this.handleRoundEnd();
      return true;
    } else {
      this.handleFrisCurrentGamePosition();
      return false;
    }
  }

  private isGameEnded(): boolean {
    return this.players[0].cards.length === -0 &&
      this.players[1].cards.length === 0 &&
      this.players[2].cards.length === 0 &&
      this.players[3].cards.length === 0;
  }

  private startNewGame(frisPlayed: boolean = false): void {
    this.roundCount = frisPlayed ? 2 : 1;
    this.cardsOnTable = [];
    this.roundStartingPosition = this.dealer.tablePosition;
    this.roundPosition = this.roundStartingPosition;
    this.handleCurrentGamePosition();
  }

  private startFrisGame(): void {
    this.roundCount = 1;
    this.cardsOnTable = [];
    this.roundStartingPosition = this.dealer.tablePosition;
    this.roundPosition = this.roundStartingPosition;
    this.handleFrisCurrentGamePosition();
  }

  public cardsDealt(cards: CardDto[]): void {
    this.player.cards = CardConverter.toModelArray(cards);
    this.player.cards.forEach(card => {
      card.setValid(false);
      card.cardClicked.subscribe(async cardData => await this.cardClicked(cardData));
    });
    for (let i = 1; i < this.players.length; i++) {
      if (this.players[i] === undefined) {
        this.players[i] = new AppPlayer();
      }
      this.players[i].cards = GameUtilsService.getHiddenCardArray(cards.length);
    }
  }

  /*
  Handlers for method calls from server
  */
  public playerJoinedHandler(args: any[]): void {
    if (args.length !== 1) {
      this.logService.error(`playerJoined method called with an invalid number of arguments: ${args.length}`);
      return;
    }
    const player: AppPlayer = AppPlayerConverter.toModel(args[0] as AppPlayerDto);
    const userKey: string = this.storage.getItem(ANONYMOUS_USER_ID);
    if ((userKey === null || userKey === undefined) && player.userKey) {
      this.storage.setItem(ANONYMOUS_USER_ID, player.userKey);
    }
    this.setCurrentPlayer(player);
    this.playerOffset = GameUtilsService.computeServerPositionOffset(this.player);
  }

  public waitingForPartyHandler(args: any[]): void {
    this.logService.log('Waiting for other players to join the game!');
  }

  public async matchEndedHandler(args: any[]): Promise<void> {
    if (args.length !== 3) {
      this.logService.error(`matchEndedHandler method called with an invalid number of arguments: ${args.length}`);
      return;
    }
    this.logService.log('The match ended!');
    this.changeToState(new GameEndedState(this));
    await this.waitForClearTable();
    this.waitingPlayerInput.next({ tablePosition: -1, isWaiting: false });
    const team1Score: number = Number(args[1]);
    const team2Score: number = Number(args[2]);
    const winnerTeamId: string = args[0];
    const matchScore: MatchScore = new MatchScore();
    if (this.player.teamId === winnerTeamId) {
      matchScore.selfWonGames = Math.max(team1Score, team2Score);
      matchScore.opponentWonGames = Math.min(team1Score, team2Score);
    } else {
      matchScore.selfWonGames = Math.min(team1Score, team2Score);
      matchScore.opponentWonGames = Math.max(team1Score, team2Score);
    }
    const teamIndex = GameUtilsService.getPlayerTeamIndex(this.player);
    this.matchEnded.next({
      player: this.player,
      score: matchScore,
      team: GameUtilsService.getTeam(teamIndex, this.players),
      teamIndex: teamIndex
    });
    this.setTromfColor(CardColor.Hidden);
  }

  public choosingNextDealerHandler(args: any[]): void {
    if (args.length !== 1) {
      this.logService.error(`choosingNextDealer method called with an invalid number of arguments: ${args.length}`);
      return;
    }
    this.changeToState(new ChoosingDealerState(this));
    this.gameCount++;
    const tablePos: number = Number(args[0]);
    const nextDealer: number = GameUtilsService.advanceRoundPosition(this.dealer.tablePosition);
    if (this.player.tablePosition === tablePos) {
      this.hubConnection.invoke('NextDealer', nextDealer)
        .catch(error => {
          this.logService.error(`Calling 'NextDealer' method failed with the following error: ${error}`);
        });
    }
  }

  public tieWinnerHandler(args: any[]): void {
    if (args.length !== 2) {
      this.logService.error('Invalid number of arguments sent to the "tieWinner" method');
    }
    const tablePos: number = Number(args[0]);
    this.dealer = this.players.filter(player => player.tablePosition === tablePos)[0];
    this.setTromfColor(args[1] as CardColor);
    this.changeToState(new InGameState(this));
    this.startNewGame(false);
    this.players.filter(player => player.tablePosition !== tablePos).forEach((player) => {
      this.dialogReceived.next({ message: undefined, position: -1 * player.tablePosition, withTimeout: true });
    });
    this.waitingPlayerInput.next({ tablePosition: this.dealer.tablePosition, isWaiting: true });
  }

  public tiePlayedHandler(args: any[]): void {
    if (args.length !== 2) {
      this.logService.error('Invalid number of arguments sent to the "tiePlayed" method');
    }
    let tablePos: number = Number(args[0]);
    const tromfCombination: TromfCombination = args[1] as TromfCombination;
    const tromfMessage: string = GameUtilsService.legareaTromfuluiNameDictionary.get(tromfCombination);
    this.players.filter(player => player.tablePosition === tablePos)[0].tromfCombination = tromfCombination;
    this.waitingPlayerInput.next({ tablePosition: -1, isWaiting: false });
    this.dialogReceived.next({ message: tromfMessage, position: tablePos, withTimeout: false });
    tablePos = GameUtilsService.advanceRoundPosition(tablePos);
    this.handleLegareaTromfului(tablePos);
    if (tablePos !== this.dealer.tablePosition) {
      this.waitingPlayerInput.next({ tablePosition: tablePos, isWaiting: true });
    }
  }

  public async gameWinnerHandler(args: any[]): Promise<void> {
    if (args.length !== 3) {
      this.logService.error(`gameWinner method called with an invalid number of arguments: ${args.length}`);
      return;
    }
    this.players.forEach(player => player.tromfCombination = undefined);
    this.waitingPlayerInput.next({ tablePosition: -1, isWaiting: false });
    this.dialogReceived.next({ message: undefined, position: NaN, withTimeout: false });
    await this.waitForClearTable();
    this.handleGameEnded();
  }

  public async gameTieHandler(args: any[]): Promise<void> {
    if (args.length !== 0) {
      this.logService.error(`gameTie method called with an invalid number of arguments: ${args.length}`);
      return;
    }
    this.players.forEach(player => player.tromfCombination = undefined);
    this.waitingPlayerInput.next({ tablePosition: -1, isWaiting: false });
    await this.waitForClearTable();
    this.handleGameEnded();
  }

  public wrongCardHandler(args: any[]): void {
    if (args.length !== 1) {
      this.logService.error(`wrongCard method was called with an invalid number of arguments: ${args.length}`);
      return;
    }
    this.logService.error(`Wrong card played with the following error: ${args[0] as string}!`);
  }

  public allPlayersJoinedHandler(args: any[]): void {
    if (args.length !== 1) {
      this.logService.error(`allPlayersJoined method called with an invalid number of arguments: ${args.length}`);
      return;
    }
    let index: number = 1;
    const restOfPlayers: AppPlayer[] = AppPlayerConverter.toModelArray(args[0] as AppPlayerDto[]);
    restOfPlayers.forEach(player => {
      this.players[index] = player;
      index++;
    });
    this.players[0] = this.player;
    this.changeToState(new ChoosingDealerState(this));
  }

  public playerExistsHandler(args: any[]): void {
    this.logService.error('Player already exists in a game!');
    this.changeToState(new GameEndedState(this));
    this.playerAlreadyJoined.next(AppPlayerConverter.toModel(args[0] as AppPlayerDto));
  }

  public invalidGameHandler(args: any[]) {
    this.logService.error('Invalid game!', args);
    this.voluntaryClosing = true;
    this.hubConnection.stop();
    if (args.length !== 1) {
      this.logService.error(`invalidGame method called with an invalid number of arguments: ${args.length}`);
      return;
    }
    this.invalidGame.next(args[0]);
  }

  public ceaIesitMicaWinnerHandler(args: any[]): void {
    if (args.length !== 1) {
      this.logService.error(`ceaIesitMica method called with an invalid number of arguments: ${args.length}`);
      return;
    }
    this.changeToState(new CeaIesitMicaWinnerState(this));
    this.changeToState(new FrisState(this));
    this.checkCeaIesitMicaWinner(Number(args[0]));
  }

  public async cardPlayedHandler(args: any[]): Promise<void> {
    if (args.length !== 2) {
      this.logService.error(`cardPlayed method called with an invalid number of arguments: ${args.length}`);
      return;
    }
    const card: Card = CardConverter.toModel(args[0] as CardDto);
    switch (this.gameState.getStateName()) {
      case ('Fris'):
        if (this.cardsOnTable.length === 0) {
          this.roundColor = card.color;
        } else {
          await this.waitForClearTable();
        }
        this.playCardOnTable(card, this.roundPosition, true);
        this.cardPlayed.next({ card, from: this.roundPosition });
        this.waitingPlayerInput.next({ tablePosition: -1, isWaiting: false });
        await this.advanceFrisPosition();
        if (this.cardsOnTable.length !== 4) {
          this.waitingPlayerInput.next({ tablePosition: this.roundPosition, isWaiting: true });
        }
        break;
      case ('InGame'):
        if (this.cardsOnTable.length === 0) {
          this.roundColor = card.color;
        } else {
          await this.waitForClearTable();
        }
        this.playCardOnTable(card, this.roundPosition, true);
        this.cardPlayed.next({ card, from: this.roundPosition });
        this.waitingPlayerInput.next({ tablePosition: -1, isWaiting: false });
        await this.advanceGamePosition();
        this.waitingPlayerInput.next({ tablePosition: this.roundPosition, isWaiting: true });
        break;
      case ('CeaIesitMica'):
        const tablePosition: number = Number(args[1]);
        this.dialogReceived.next({ message: CardName[card.name], position: tablePosition, withTimeout: true });
        this.waitingPlayerInput.next({ tablePosition, isWaiting: false });
        break;
    }
  }

  public frisWinnerHandler(args: any[]): void {
    if (args.length !== 2) {
      this.logService.error(`frisWinner method called with an invalid number of arguments: ${args.length}`);
      return;
    }
    this.changeToState(new FrisWinnerState(this));
    const tablePos: number = Number(args[0]);
    this.dealer = this.players.filter(player => player.tablePosition === tablePos)[0];
    this.setTromfColor(args[1] as CardColor);
  }

  public choosingFirstDealerHandler(args: any[]): void {
    if (args.length !== 1) {
      this.logService.error(`choosingFirstDealer method called with an invalid number of arguments: ${args.length}`);
      return;
    }
    this.gameCount = 1;
    this.dealerChosenHandler(args);
  }

  public dealerChosenHandler(args: any[]): void {
    if (args.length !== 1) {
      this.logService.error(`dealerChosen method called with an invalid number of arguments: ${args.length}`);
      return;
    }
    this.dialogReceived.next({ message: undefined, position: NaN, withTimeout: true });
    this.changeToState(new BateOrTaieState(this));
    this.handleChooseDealer(args);
  }

  public async cardsDealtHandler(args: any[]): Promise<void> {
    this.dialog.closeAll();
    this.waitingPlayerInput.next({ tablePosition: -1, isWaiting: false });
    switch (this.gameState.getStateName()) {
      case ('BateOrTaie'):
        this.cardsDealtBateOrTaieHandler(args);
        break;
      case ('Bate'):
        this.cardsDealtBateHandler(args);
        break;
      case ('LegareaTromfului'):
        this.cardsDealtLegareaTromfuluiHandler(args);
        break;
      case ('Taie'):
        this.cardsDealtTaieHandler(args);
        break;
      case ('FrisWinner'):
        await this.cardsDealtFrisWinnerHandler(args);
        break;
      default:
        break;
    }
  }

  public playerLeftHandler(args: any[]): void {
    if (args.length !== 1) {
      this.logService.error(`playerLeft method called with an invalid number of arguments: ${args.length}`);
    }
    const player: AppPlayer = AppPlayerConverter.toModel(args[0] as AppPlayerDto);
    if (this.isGameStarted || this.isMatchEnded) {
      this.voluntaryClosing = true;
      this.hubConnection.stop();
    }
    this.waitingPlayerInput.next({ tablePosition: -1, isWaiting: false });
    this.playerDisconnected.next(player);
    this.setTromfColor(CardColor.Hidden);
  }

  public isPlayingFrisHandler(args: any[]): void {
    if (args.length !== 2) {
      this.logService.error(`isPlayingFris method called with an invalid number of arguments: ${args.length}`);
    }
    const tablePostion: number = Number(args[0]);
    const playsFris: boolean = Boolean(args[1]);
    this.players.filter(player => player.tablePosition === tablePostion)[0].isPlayingFris = playsFris;
    this.waitingPlayerInput.next({ tablePosition: tablePostion, isWaiting: false });
    this.dialogReceived.next({ message: playsFris ? 'Fris' : 'Pas', position: tablePostion, withTimeout: true });
  }

  public frisAnnouncedHandler(args: any[]): void {
    const fris: boolean = Boolean(args[0]);
    if (fris) {
      this.changeToState(new CeaIesitMicaState(this));
      this.playingFris();
    } else {
      this.changeToState(new LegareaTromfuluiState(this));
    }
  }

  /*
  Private subhandler methods
  */
  private cardsDealtBateOrTaieHandler(args: any[]): void {
    const cardArray: CardDto[] = args[0] as CardDto[];
    switch (cardArray.length) {
      case (5):
        this.cardsDealt(cardArray);
        this.changeToState(new BateState(this));
        this.changeToState(new LegareaTromfuluiState(this));
        this.handleLegareaTromfului(this.dealer.tablePosition);
        this.waitingPlayerInput.next({ tablePosition: this.dealer.tablePosition, isWaiting: true });
        break;
      case (3):
        this.cardsDealt(cardArray);
        this.changeToState(new TaieState(this));
        this.canFris();
        this.waitingPlayerInput.next({ tablePosition: -1, isWaiting: true });
        break;
    }
  }

  private cardsDealtBateHandler(args: any[]): void {
    this.cardsDealt(args[0] as CardDto[]);
    this.waitingPlayerInput.next({ tablePosition: this.dealer.tablePosition, isWaiting: true });
    this.changeToState(new LegareaTromfuluiState(this));
    this.handleLegareaTromfului(-1);
  }

  private cardsDealtLegareaTromfuluiHandler(args: any[]): void {
    this.cardsDealt(args[0] as CardDto[]);
    this.waitingPlayerInput.next({ tablePosition: -1, isWaiting: false });
    this.waitingPlayerInput.next({ tablePosition: this.dealer.tablePosition, isWaiting: true });
    this.handleLegareaTromfului(this.dealer.tablePosition);
  }

  private cardsDealtTaieHandler(args: any[]): void {
    this.cardsDealt(args[0] as CardDto[]);
    this.waitingPlayerInput.next({ tablePosition: -1, isWaiting: true });
    this.canFris();
  }

  private async cardsDealtFrisWinnerHandler(args: any[]): Promise<void> {
    await this.waitForClearTable();
    this.cardsDealt(args[0] as CardDto[]);
    this.changeToState(new InGameState(this));
    this.waitingPlayerInput.next({ tablePosition: this.dealer.tablePosition, isWaiting: true });
    this.startNewGame(true);
  }

  private handleGameEnded(): void {
    this.changeToState(new GameWinnerState(this));
    let score: GameScore;
    let selfScore: number;
    ({ gameScore: score, selfScore } =
      GameUtilsService.getGameScore(this.player, this.players, this.dealer.tablePosition % 2 === this.player.tablePosition % 2));
    for (let i = 0; i < this.players.length; i++) {
      this.players[i].wonCards = [];
    }
    this.gameEnded.next({
      player: this.player,
      gameScore: score,
      team1: GameUtilsService.getTeam(1, this.players),
      team2: GameUtilsService.getTeam(2, this.players)
    });
    this.setTromfColor(CardColor.Hidden);
  }

  private handleLegareaTromfului(tablePos: number): void {
    if (this.player.tablePosition !== tablePos || this.player.tromfCombination) {
      return;
    }
    const { tromfCombination, cardsInTromf }: { tromfCombination: TromfCombination, cardsInTromf: Card[] } = GameUtilsService.getAllTromfCombination(this.player.cards);
    this.availableTromfCombination = tromfCombination;
    let cardFound: boolean = false;
    if (!TromfCombinationsUtils.isEmpty(this.availableTromfCombination)) {
      for (let card of this.player.cards) {
        cardFound = false;
        for (let tromfCard of cardsInTromf) {
          if (tromfCard.name === card.name && tromfCard.color === card.color && !TromfCombinationsUtils.isEmpty(tromfCombination)) {
            card.setValid(true);
            card.setHoverState(false);
            cardFound = true;
            break;
          }
        }
        if (!cardFound) {
          card.setValid(false);
        }
      }
    } else {
      this.currentTromfCards = cardsInTromf;
      this.currentTromfCombination = GameUtilsService.getHighestTromfCombination(this.availableTromfCombination);
    }
    if (GameUtilsService.isBunaCombinationPossible(this.player.tablePosition, this.players) && !TromfCombinationsUtils.isEmpty(this.availableTromfCombination)) {
      this.legareaTromfuluiPossible.next({ tromfPossible: true, tromfCombinations: TromfCombination.Buna });
      this.currentTromfCombination = TromfCombination.Buna;
    } else {
      this.legareaTromfuluiPossible.next({ tromfPossible: TromfCombinationsUtils.isEmpty(this.availableTromfCombination), tromfCombinations: this.availableTromfCombination });
    }
  }

  private handleChooseDealer(args: any[]): void {
    this.cardsOnTable = [];
    this.players.forEach(tablePlayer => {
      tablePlayer.cards = [];
    });

    const dealerPos: number = Number(args[0]);
    this.dealer = this.players.filter(player => player.tablePosition === dealerPos)[0];
    this.roundPosition = dealerPos;
    this.waitingPlayerInput.next({ tablePosition: GameUtilsService.getPlayerPositionRightToDealer(dealerPos), isWaiting: true });
    this.dealerChosen(dealerPos);
  }

  public checkCeaIesitMicaWinner(tablePosition: number): void {
    this.dealer = this.players.filter(player => player.tablePosition === tablePosition)[0];
    if (tablePosition !== this.player.tablePosition) {
      this.waitingPlayerInput.next({ tablePosition, isWaiting: true });
    }
    this.startFrisGame();
  }

  private async waitForClearTable(): Promise<void> {
    while (this.cardsOnTable.length === 4) {
      await this.delay(500);
    }
  }

  private async handleMethod(methodName: string, args: any[]) {
    await this.gameState.handleMethod(methodName, args);
  }

  private cleanGameState(): void {
    this.voluntaryClosing = true;
    if (this.hubConnection != null || this.hubConnection !== undefined) {
      this.gameMethods.forEach(gameMethod => {
        this.hubConnection.off(gameMethod);
      });
      this.hubConnection.stop().catch(reason => {
        this.logService.error(`Closing the SignalR connection failed for the following reason: ${reason}`);
      });
    }
    this.cardsOnTable = [];
    this.gameState = undefined;
    this.players = [undefined, undefined, undefined, undefined];
  }

  private addListeners(): void {
    this.gameMethods.forEach(gameMethod => {
      this.hubConnection.on(gameMethod, async (...data: any[]) => {
        this.pendingMessages.push({ method: gameMethod, args: data });
      });
    });
  }

  private get token(): string {
    const token = localStorage['token'];
    if (token) {
      const parts = token.split(' ');
      if (parts.length > 1) {
        return parts[1];
      }
      return '';
    }
    return '';
  }

  private async processPendingMessages(): Promise<void> {
    if (this.pendingMessages.length > 0) {
      const message = this.pendingMessages.shift();
      await this.handleMethod(message.method, message.args);
    }
    clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      this.processPendingMessages();
    }, 50);
  }
}
