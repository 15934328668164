<form class="bg-white auth-form" [formGroup]="resetPasswordForm" (submit)="onResetPassword()" novalidate>
  <h1 class="login-title">Resetare parolă</h1>

  <div class="form-group login-controls">
      <input class="form-control login-input" type="password" placeholder="Noua parolă"  formControlName="password" required pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$"/>
      <div *ngIf="resetPasswordForm.get('password').invalid && (resetPasswordForm.get('password').dirty || resetPasswordForm.get('password').touched)" >
        <div *ngIf="resetPasswordForm.get('password').errors.required" class="error">Parola este obligatorie</div>
        <div *ngIf="resetPasswordForm.get('password').errors.pattern" class="error">Parola trebuie sa conțină o literă mare, una mică, o cifră și un caracter special</div>
      </div>
  </div>
                
  <div class="form-group login-controls">
    <input class="form-control login-input" type="password" placeholder="Confirmarea parolei" formControlName="confirmPassword" required/>
    <div *ngIf="resetPasswordForm.get('confirmPassword').invalid && (resetPasswordForm.get('confirmPassword').dirty || resetPasswordForm.get('confirmPassword').touched)">
      <div *ngIf="resetPasswordForm.get('confirmPassword').errors.required" class="error">Confirmarea parolei este obligatroie</div>
      <div *ngIf="resetPasswordForm.get('confirmPassword').errors.mustMatch" class="error">Parolele nu se potrivesc</div>
    </div>
  </div>
  
  <button class="login-button" type="submit" [disabled]="isSubmitted || disabled">
    <span *ngIf="!isSubmitted">
      Schimbă parola
    </span>
    <span *ngIf="isSubmitted">
      <i class="fas fa-spinner fa-pulse"></i>
    </span>
  </button>
  
  <app-login-anchor [disabled]='isSubmitted'></app-login-anchor>
</form>