import { Component, OnInit, OnDestroy } from '@angular/core';
import { GameService } from 'src/core/services/game.service';
import { Subscription } from 'rxjs';
import { GameScore } from 'src/core/models/game/score.model';
import { CardColorUrlPipe } from 'src/core/pipes/card-color-url.pipe';
import { CardNamePipe } from 'src/core/pipes/card-name.pipe';
import { HubConnectionState } from '@microsoft/signalr';

@Component({
  selector: 'app-status-header',
  templateUrl: './status-header.component.html',
  styleUrls: ['./status-header.component.scss']
})
export class StatusHeaderComponent implements OnInit, OnDestroy {

  public selfScore: number = 0;
  public firstTeamScore: number = 0;
  public secondTeamScore: number = 0;
  public tromfAnnounced: boolean = false;

  private subscriptions: Subscription;

  constructor(public gameService: GameService, public cardColorUrlPipe: CardColorUrlPipe, public cardNamePipe: CardNamePipe) {

  }

  ngOnInit(): void {
    this.subscriptions = new Subscription();
    this.subscriptions.add(this.gameService.gameScoreUpdated.subscribe(data => this.gameScoreUpdatedHandler(data.selfScore, data.gameScore)));
    this.subscriptions.add(this.gameService.tromfColorAnnounced.subscribe(data => this.tromfColorAnnouncedHandler(data)));
    this.subscriptions.add(this.gameService.gameEnded.subscribe(() => this.gameEndedHandler()));
  }

  private gameScoreUpdatedHandler(selfScore: number, gameScore: GameScore): void {
    this.selfScore = selfScore;
    this.firstTeamScore = gameScore.team1Score;
    this.secondTeamScore = gameScore.team2Score;
  }

  private tromfColorAnnouncedHandler(announced: boolean): void {
    this.tromfAnnounced = announced;
  }

  private gameEndedHandler(): void {
    this.selfScore = 0;
    this.firstTeamScore = 0;
    this.secondTeamScore = 0;
  }

  public get isGameRunning(): boolean {
    return this.gameService.isGameRunning;
  }

  public get isGameWaiting(): boolean {
    const stateName = this.gameService.gameState?.getStateName();
    return stateName === 'WaitingForParty' && this.gameService.hubState !== HubConnectionState.Disconnected;
  }

  protected get isTimeoutEnabled(): boolean {
    const stateName = this.gameService.gameState?.getStateName();
    return stateName !== 'WaitingForParty' && stateName !== 'GameEnded' && this.gameService.hubState !== HubConnectionState.Disconnected;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
