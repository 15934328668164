<div class="container p-20">
  <div class="card shadow-sm p-3 mb-5 bg-body rounded">

    <h5 class="card-title">Profil</h5>

    <div class="card-body">

      <div class="d-flex flex-wrap flex-column justify-content-center align-items-center">
        <div id="profile-image">
          <img [src]="user && user.avatarUrl ? user.avatarUrl : 'assets/images/img_avatar.png'" alt="Avatar"
            class="profile-picture" width="200" height="200">
          <div class="overlay">
            <a href="#" class="upload-icon" title="User Profile" data-bs-toggle="modal" data-bs-target="#uploadModal">
              <i class="fas fa-upload"></i>
            </a>
          </div>
        </div>
      
      <!-- Move this after </form> to align everything centrally  -->
      </div>


      <form id="user-profile" class="m-5" [formGroup]="userProfile" (ngSubmit)="onSubmited()">

        <div class="row d-md-flex justify-content-md-center">
          <div class="col-sm-12 col-md-6 form-group">
            <label for="lastname" class="form-label fl-lable-text">Nume</label>
            <input id="lastname" class="form-control " formControlName="lastname" />
            <div *ngIf="lastname.invalid && (lastname.dirty || lastname.touched)" class="fl-primary-error">
              <div *ngIf="lastname.errors.maxlength">Prenumele trebuie sa aibă maxim 50 caractere</div>
              <div *ngIf="lastname.errors.minlength">Prenumele trebuie sa aibă minim 2 caractere </div>
              <div *ngIf="lastname.errors.required">Prenumele este obligatoriu</div>
            </div>
          </div>
        </div>

        <div class="row d-md-flex justify-content-md-center">
          <div class="col-sm-12 col-md-6 form-group">
            <label for="firstname" class="form-label fl-lable-text">Prenume</label>
            <input id="firstname" class="form-control " formControlName="firstname" />
            <div *ngIf="firstname.invalid && (firstname.dirty || firstname.touched)" class="fl-primary-error">
              <div *ngIf="firstname.errors.maxlength">Numele trebuie sa aibă maxim 50 caractere</div>
              <div *ngIf="firstname.errors.minlength">Numele trebuie sa aibă minim 2 caractere</div>
              <div *ngIf="firstname.errors.required">Numele este obligatoriu</div>
            </div>
          </div>
        </div>

        <div class="row d-md-flex justify-content-md-center">
          <div class="col-sm-12 col-md-6 form-group">
            <label for="email" class="form-label fl-lable-text">Email</label>
            <input id="email" class="form-control" formControlName="email" type="email" />
            <div *ngIf="email.invalid  && (email.dirty || email.touched)" class="fl-primary-error">
              <div *ngIf="email.errors.required">Emailul este obligatoriu</div>
              <div *ngIf="email.errors.email">Emailul nu este valid</div>
            </div>
          </div>
        </div>

        <div class="row d-md-flex justify-content-md-center">
          <div class="col-sm-12 col-md-6 form-group">
            <label for="username" class="form-label fl-lable-text">Nume utilizator</label>
            <input id="username" class="form-control " formControlName="username" />
            <div *ngIf="username.invalid  && (username.dirty || username.touched)" class="fl-primary-error">
              <div *ngIf="username.errors.required">Username-ul este obigatoriu</div>
            </div>
          </div>
        </div>

      </form>

    </div>

    <div class="card-footer fl-card-footer d-flex justify-content-end">
      <input type="submit" class="btn fl-primary-button" value="Salvează schimbările" form="user-profile" />
    </div>
  </div>
</div>

<app-cropper (onUpload)="uploadImage($event)"></app-cropper>`