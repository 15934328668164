import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PAGES } from 'src/app/common/constants';

@Component({
  selector: 'app-donations-page',
  templateUrl: './donations-page.component.html',
  styleUrls: ['./donations-page.component.scss']
})
export class DonationsPageComponent {
  public pageName: PAGES = PAGES.DonationsPage;

  constructor(
    private activatedRoute: ActivatedRoute) {
  }
}
