import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { Card } from 'src/core/models/game/card.model';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Subscription } from 'rxjs';
import { CardImagePipe } from 'src/core/pipes/card-image.pipe';

@Component({
  selector: 'app-player-card',
  templateUrl: './player-card.component.html',
  styleUrls: ['./player-card.component.scss'],
  animations: [
    trigger('highlightTrigger', [
      state('normal', style({
        transform: 'rotateX({{xAngle}}deg) rotateZ({{zAngle}}deg) scale(1) translateY({{defaultY}}vmin) translate(0vmin, 0vmin)'
      }), { params: { xAngle: '20', zAngle: '0', defaultY: '0' } }),
      state('highlight', style({
        transform: 'rotateX({{xAngle}}deg) rotateZ({{zAngle}}deg) scale({{scaleHighlight}}) translateY({{defaultY}}vmin) translate({{highlightX}}vmin, {{highlightY}}vmin)'
      }), { params: { xAngle: '20', zAngle: '0', defaultY: '0', scaleHighlight: '1.06', highlightX: '0', highlightY: '0' } }),
      transition('normal => highlight', [
        animate('0.2s')
      ]),
      transition('highlight => normal', [
        animate('0.2s')
      ]),
    ]),
    trigger('validityTrigger', [
      state('valid', style({
        filter: 'grayscale(0%)'
      })),
      state('invalid', style({
        filter: 'grayscale(100%)'
      }))
    ])
  ]
})
export class PlayerCardComponent implements OnInit, OnDestroy {

  @Input() public card: Card;

  private normalState: string = 'normal';
  private highlightState: string = 'highlight';
  private highlightValue: number = 3.5;
  private tapTimeoutMs: number = 180;
  private longPressTimeoutMs: number = 500;
  private touchStartTime: Date;
  private validState: string = 'valid';
  private invalidState: string = 'invalid';
  private subscriptions: Subscription;
  private count: number;
  private hoverMode: boolean = false;

  public defaultYtranslation: number;
  public validityState: string = this.validState;
  public highlightAnimState: string = this.normalState;
  public zAngle: number;
  public highlightX: number = 0;
  public highlightY: number = 0;
  public index: number;

  constructor(public cardImagePipe: CardImagePipe) {
  }

  ngOnInit(): void {
    this.subscriptions = new Subscription();
    this.subscriptions.add(this.card.validityChanged.subscribe((valid) => this.toggleCardValidity(valid)));
    this.subscriptions.add(this.card.hoverModeChanged.subscribe((hoverMode) => this.hoverModeToggled(hoverMode)));
    this.toggleCardValidity(this.card.isValid());
    this.setCardStyle();
  }

  @Input()
  public set countProp(count: number) {
    this.count = count;
    this.setCardStyle();
  }

  @Input()
  public set indexProp(index: number) {
    this.index = index;
    this.setCardStyle();
  }

  private setCardStyle(): void {
    this.defaultYtranslation = this.getDefaultYTranslation();
    this.zAngle = this.getCardAngle();
    this.setHighlightValues();
  }

  public getCardCssClass(): string {
    return `card-position-index-${this.index}-count-${this.count}`;
  }

  public toggleHighlightCard(): void {
    this.highlightAnimState = (this.highlightAnimState === this.normalState && !this.hoverMode) ? this.highlightState : this.normalState;
    this.card.setHighlighted(this.highlightAnimState === this.highlightState);
  }

  public hoverModeToggled(hoverMode: boolean): void {
    this.hoverMode = hoverMode;
    if (this.highlightAnimState === this.highlightState) {
      this.highlightAnimState = this.normalState;
    }
  }

  private convertToRadians(degrees: number): number {
    return (degrees * Math.PI) / 180;
  }

  public toggleCardValidity(valid: boolean): void {
    if (this.highlightAnimState === this.highlightState) {
      this.highlightAnimState = this.normalState;
    }
    this.validityState = valid ? this.validState : this.invalidState;
  }

  private getDefaultYTranslation(): number {
    if ((this.index === 1 && this.count === 1) || (this.index === 2 && this.count === 3) || (this.index === 3 && this.count === 5)) {
      return -2.5;
    }
    if ((this.index === 1 && this.count === 2) || (this.index === 2 && this.count === 4) || (this.index === 2 && this.count === 2) || (this.index === 3 && this.count === 4)) {
      return -2;
    }
    if ((this.index === 1 && this.count === 3) || (this.index === 2 && this.count === 5) || (this.index === 3 && this.count === 3) || (this.index === 4 && this.count === 5)) {
      return -2;
    }
    return 0;
  }

  public cardHovered(mouseOver: boolean): void {
    if (this.hoverMode) {
      this.highlightAnimState = mouseOver ? this.highlightState : this.normalState;
      this.card.setHighlighted(mouseOver);
    }
  }

  public cardClicked(): void {
    if (this.card.isValid()) {
      this.toggleHighlightCard();
      this.card.onCardClicked();
    }
  }

  public setHighlightValues(): void {
    if (!this.card.isValid()) {
      return;
    }
    let angle = 90 - this.zAngle;
    angle = this.convertToRadians(angle);
    this.highlightY = -1 * this.highlightValue * Math.sin(angle);
    this.highlightX = this.highlightValue * Math.cos(angle);
  }

  public startTouchEvent(): void {
    this.touchStartTime = new Date();
  }

  public endTouchEvent(cancelled: boolean): void {
    if (cancelled) {
      this.highlightAnimState = this.normalState;
      return;
    }
    const endTime: Date = new Date();
    const touchLength: number = endTime.getMilliseconds() - this.touchStartTime.getMilliseconds();
    if (touchLength <= this.tapTimeoutMs) {
      if (!this.hoverMode) {
        this.card.onCardClicked();
        this.cardHovered(this.highlightAnimState === this.highlightState);
      }
    } else {
      this.card.onCardClicked();
    }
  }

  private getCardAngle(): number {
    const sign: number = this.index <= (this.count / 2) ? -1 : 1;
    if ((this.index === 1 && this.count === 1) || (this.index === 2 && this.count === 3) || (this.index === 3 && this.count === 5)) {
      return 0;
    }
    if ((this.index === 1 && this.count === 2) || (this.index === 2 && this.count === 4) || (this.index === 2 && this.count === 2) || (this.index === 3 && this.count === 4)) {
      return sign * 2.5;
    }
    if ((this.index === 1 && this.count === 3) || (this.index === 2 && this.count === 5) || (this.index === 3 && this.count === 3) || (this.index === 4 && this.count === 5)) {
      return sign * 5;
    }
    if ((this.index === 1 || this.index === 5) && this.count === 5) {
      return sign * 10;
    }
    if ((this.index === 1 || this.index === 4) && this.count === 4) {
      return sign * 7.5;
    }
    return 0;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
