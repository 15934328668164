import { Component, OnInit } from '@angular/core';
import { PAGES } from 'src/app/common/constants';

@Component({
  selector: 'not-found',
  templateUrl: './not-found-page.component.html',
  styleUrls: ['./not-found-page.component.scss']
})
export class NotFoundPageComponent implements OnInit {

  public pageName: PAGES = PAGES.NotFoundPage;

  constructor() { }

  ngOnInit() {
  }

}
