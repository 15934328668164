import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavbarComponent } from "./components/navbar/navbar.component";
import { CropperComponent } from './components/cropper/cropper.component';
import { ImageCropperModule } from 'ngx-img-cropper';
import { LoaderComponent } from './components/loader/loader.component';
import { HttpClientModule } from '@angular/common/http';
import { PlayButtonComponent } from './components/play-button/play-button.component';
import { ToastrModule } from 'ngx-toastr';
import { MustMatchDirective } from '../../core/directives/mustmatch.directive'
import { NullDefaultValueDirective } from '../../core/directives/nulldefaultvalue.directive';
import { LogoComponent } from './components/logo/logo.component';
import { TopbarComponent } from './components/topbar/topbar.component'
import { ProfileComponent } from './components/profile/profile.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { PermissionPipe } from 'src/core/pipes/permission.pipe';
import { FooterLayoutComponent } from './components/footer-layout/footer-layout.component';
import { UserStatusPipe } from 'src/core/pipes/user-status.pipe';

@NgModule({
  declarations: [
    NavbarComponent,
    CropperComponent,
    LoaderComponent,
    PlayButtonComponent,
    LogoComponent,
    TopbarComponent,
    ProfileComponent,
    FooterLayoutComponent,
    NullDefaultValueDirective,
    MustMatchDirective,
    PermissionPipe,
    UserStatusPipe,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ImageCropperModule,
    RouterModule,
    NgxSpinnerModule,
    ToastrModule.forRoot()
  ],
  exports: [
    NavbarComponent,
    CropperComponent,
    LoaderComponent,
    TopbarComponent,
    ProfileComponent,
    FooterLayoutComponent,
    MustMatchDirective,
    NullDefaultValueDirective,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ToastrModule,
    NgxSpinnerModule,
    UserStatusPipe
  ], 
  providers:[
    PermissionPipe,
  ]
})
export class SharedModule { }
