import { Component, OnDestroy, OnInit } from '@angular/core';
import { LogService } from '../../../../core/services/log.service';
import { ContactService } from '../../../../core/services/contact.service';
import { ContactMessageModel } from '../../../../core/models/contact-message.model';
import { LoaderService } from '../../../../core/services/loader.service';
import { Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PAGES } from 'src/app/common/constants';

@Component({
  selector: 'app-contact-page',
  templateUrl: './contact-page.component.html',
  styleUrls: ['./contact-page.component.scss']
})
export class ContactPageComponent implements OnInit, OnDestroy {
  public pageName: PAGES = PAGES.ContactPage;
  public contactMessage: ContactMessageModel;

  private subscriptions: Subscription;

  public contactForm: FormGroup;

  constructor(
    private logService: LogService,
    private contactService: ContactService,
    private loaderService: LoaderService,
    private toastr: ToastrService) {

  }

  ngOnInit(): void {
    this.contactMessage = new ContactMessageModel();
    this.subscriptions = new Subscription();

    this.contactForm = new FormGroup({
      name: new FormControl(this.contactMessage.name, [Validators.required]),
      email: new FormControl(this.contactMessage.email, [Validators.required, Validators.email]),
      subject: new FormControl(this.contactMessage.subject, [Validators.required]),
      message: new FormControl(this.contactMessage.message, [Validators.required])
    })
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

  public sendMessage(): void {
    if (this.contactForm.valid) {
      this.loaderService.loaderState = true;

      this.contactMessage.name = this.name.value;
      this.contactMessage.email = this.email.value;
      this.contactMessage.subject = this.subject.value;
      this.contactMessage.message = this.message.value;

      this.contactService.sendMessage(this.contactMessage).subscribe(
        res => {
          this.loaderService.loaderState = false;
          // form.form.reset();
          // form.submitted = false;
          this.toastr.success("Mesajul a fost trimis cu succes!");
        },
        error => {
          this.logService.error('Sending a contact message failed with the following error: ', error);
          this.loaderService.loaderState = false;
          this.toastr.error("Mesajul nu a putut fi trimis, te rugam sa incerci mai tarziu.");
        });
    }
  }

  public get name() {
    return this.contactForm.get("name");
  }
  public get email() {
    return this.contactForm.get("email");
  }
  public get subject() {
    return this.contactForm.get("subject");
  }
  public get message() {
    return this.contactForm.get("message");
  }
}
