<form *ngIf="!showConfirmation" class="bg-white auth-form" [formGroup]="registerForm" (submit)="onRegister()"
  novalidate>
  <h1 class="login-title">Înregistrare</h1>

  <div class="form-group login-controls">
    <input class="form-control login-input" type="text" placeholder="Nume" formControlName="firstname" required />
    <div
      *ngIf="registerForm.get('firstname').invalid && (registerForm.get('firstname').dirty || registerForm.get('firstname').touched)">
      <div *ngIf="registerForm.get('firstname').errors.required" class="error">Numele este obligatoriu</div>
      <div *ngIf="registerForm.get('firstname').errors.maxLength" class="error">Numele poate avea maxim 40 de caractere</div>
    </div>
  </div>

  <div class="form-group login-controls">
    <input class="form-control login-input" type="text" placeholder="Prenume" formControlName="lastname" required />
    <div
      *ngIf="registerForm.get('lastname').invalid && (registerForm.get('lastname').dirty || registerForm.get('lastname').touched)">
      <div *ngIf="registerForm.get('lastname').errors.required" class="error">Prenumele este obligatoriu</div>
      <div *ngIf="registerForm.get('lastname').errors.maxLength" class="error">Prenumele poate avea maxim 40 de caractere</div>
    </div>
  </div>

  <div class="form-group login-controls">
    <input class="form-control login-input" type="email" placeholder="Email" formControlName="email" required />
    <div
      *ngIf="registerForm.get('email').invalid && (registerForm.get('email').dirty || registerForm.get('email').touched)">
      <div *ngIf="registerForm.get('email').errors.required" class="error">Email-ul este obligatoriu</div>
      <div *ngIf="registerForm.get('email').errors.email" class="error">Email-ul nu este valid</div>
    </div>
  </div>

  <div class="form-group login-controls">
    <input class="form-control login-input" type="text" placeholder="Nume de utilizator" formControlName="username"
      required />
    <div
      *ngIf="registerForm.get('username').invalid && (registerForm.get('username').dirty || registerForm.get('username').touched)">
      <div *ngIf="registerForm.get('username').errors.required" class="error">Username-ul este obigatoriu</div>
      <div *ngIf="registerForm.get('username').errors.maxLength" class="error">Username-ul poate avea maxim 40 de caractere</div>
    </div>
  </div>

  <div class="form-group login-controls">
    <input class="form-control login-input" type="password" placeholder="Parola" formControlName="password" required
      pattern="^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$" />
    <div
      *ngIf="registerForm.get('password').invalid && (registerForm.get('password').dirty || registerForm.get('password').touched)">
      <div *ngIf="registerForm.get('password').errors.required" class="error">Parola este obligatorie</div>
      <div *ngIf="registerForm.get('password').errors.pattern" class="error">Parola trebuie sa conțină o literă mare,
        una mică, o cifră și un caracter special</div>
      <div *ngIf="registerForm.get('password').errors.maxLength" class="error">Parola poate avea maxim 40 de caractere</div>
    </div>
  </div>

  <div class="form-group login-controls">
    <input class="form-control login-input" type="password" placeholder="Confirmare parolei"
      formControlName="confirmPassword" required />
    <div
      *ngIf="registerForm.get('confirmPassword').invalid && (registerForm.get('confirmPassword').dirty || registerForm.get('confirmPassword').touched)">
      <div *ngIf="registerForm.get('confirmPassword').errors.required" class="error">Confirmarea parolei este
        obligatroie</div>
      <div *ngIf="registerForm.get('confirmPassword').errors.mustMatch" class="error">Parolele nu se potrivesc</div>
    </div>
  </div>

  <div class="login-controls" *ngIf="errorDescription">
    <p class="server-error">{{ errorDescription }}</p>
  </div>

  <button class="login-button" type="submit" [disabled]="isSubmitted">
    <span *ngIf="!isSubmitted">
      Creează contul
    </span>
    <span *ngIf="isSubmitted">
      <i class="fas fa-spinner fa-pulse"></i>
    </span>
  </button>
  
  <app-login-anchor [disabled]='isSubmitted'></app-login-anchor>
</form>