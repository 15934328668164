export class RegisterModel {
    constructor(public firstname?: string,
        public lastname?: string,
        public username?: string,
        public email?: string,
        public password?: string,
        public confirmPassword?: string,
        public userKey?: string) {

    }
}