import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { RouteExtService } from '../services/route-ext.service';

@Injectable({
  providedIn: 'root'
})
export class ConfirmationGuard implements CanActivate {
  constructor(
    private readonly router: Router,
    private readonly routeService: RouteExtService,
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    const prevRoute: string = this.routeService.getPreviousUrl();
    const routeGuardData = route.data;
    if (this.stripLeadingSlash(prevRoute) === this.stripLeadingSlash(routeGuardData.prevUrl)) {
      return true;
    }
    this.router.navigate(["/home"]);
  }

  private stripLeadingSlash(route: string): string {
    return route.replace(/^\//g, '');
  }
}
