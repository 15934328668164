import { Component, OnInit, OnDestroy } from '@angular/core';
import { GameService } from 'src/core/services/game.service';
import { Position } from '../../../core/models/generic.model';
import { Subscription } from 'rxjs';
import { animate, state, style, transition, trigger } from '@angular/animations';
import { CardImagePipe } from 'src/core/pipes/card-image.pipe';

@Component({
  selector: 'app-card-table',
  templateUrl: './card-table.component.html',
  styleUrls: ['./card-table.component.scss'],
  animations: [
    trigger('opacityTrigger', [
      state('normal', style({
        opacity: 1,
      })),
      state('hidden', style({
        opacity: 0,
      })),
      transition('normal => hidden', [
        animate('0.75s')
      ]),
      transition('hidden => normal', [
        animate('0s')
      ])
    ]),
    trigger('cardsWonTrigger', [
      state('normal', style({
        transform: 'scale(1)',
        left: 0,
        top: 0
      })),
      state('westWon', style({
        transform: 'scale(0.5)',
        left: '-20vmin',
        top: 0
      })),
      state('southWon', style({
        transform: 'scale(0.5)',
        left: 0,
        top: '20vmin'
      })),
      state('eastWon', style({
        transform: 'scale(0.5)',
        left: '20vmin',
        top: 0
      })),
      state('northWon', style({
        transform: 'scale(0.5)',
        left: 0,
        top: '-20vmin'
      })),

      transition('normal => southWon', [
        animate('0.6s')
      ]),
      transition('southWon => normal', [
        animate('0s')
      ]),
      transition('normal => northWon', [
        animate('0.6s')
      ]),
      transition('northWon => normal', [
        animate('0s')
      ]),
      transition('normal => eastWon', [
        animate('0.6s')
      ]),
      transition('eastWon => normal', [
        animate('0s')
      ]),
      transition('normal => westWon', [
        animate('0.6s')
      ]),
      transition('westWon => normal', [
        animate('0s')
      ])
    ])
  ]
})
export class CardTableComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription;
  private readonly normalState: string = 'normal';
  private readonly hiddenState: string = 'hidden';
  private readonly eastWinState: string = 'eastWon';
  private readonly westWinState: string = 'westWon';
  private readonly northWinState: string = 'northWon';
  private readonly southWinState: string = 'southWon';

  public cardsOpacity: string = this.normalState;
  public cardsWonState: string = this.normalState;

  constructor(public gameService: GameService, public cardImagePipe: CardImagePipe) {
  }

  ngOnInit() {
    this.subscriptions = new Subscription();
    this.subscriptions.add(this.gameService.roundWon.subscribe(data => this.roundWonHandler(data.position, data.timeout)));
    this.subscriptions.add(this.gameService.cardsOnTableCleared.subscribe(() => this.clearTable()));
  }

  private roundWonHandler(playerPos: Position, timeout: number): void {
    setTimeout(() => {
      this.cardsOpacity = this.hiddenState;
      switch (playerPos) {
        case Position.East:
          this.cardsWonState = this.eastWinState;
          break;
        case Position.West:
          this.cardsWonState = this.westWinState;
          break;
        case Position.North:
          this.cardsWonState = this.northWinState;
          break;
        case Position.South:
          this.cardsWonState = this.southWinState;
          break;
      }
    }, timeout - 600);
  }

  private clearTable(): void {
    this.cardsWonState = this.normalState;
    this.cardsOpacity = this.normalState;
  }

  public getCardCssClass(tablePosition: Position, index: number): string {
    return `card-position-${this.gameService.getLocalPositionFromServerPosition(tablePosition)}-round-${Math.floor(index / 4) + 1}`;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
