import { HttpParams, HttpParamsOptions } from '@angular/common/http';

export interface ErrorHttpOptions {
    failSilent: number[];
}

export class ErrorInterceptorHttpParams extends HttpParams {
    constructor(
        public errorConfig: ErrorHttpOptions,
        params?: { [param: string]: string | string[] }
    ) {
        super({ fromObject: params } as HttpParamsOptions);
    }
}