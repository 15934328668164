import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { Router } from '@angular/router';
import { User } from '../../../../core/models/user.model';
import { MenuItem } from "src/app/common/menu-item";
import { LINKS, LocalStorageIndex, MENU } from "src/app/common/constants";
import { UserService } from "src/core/services/user.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit, OnDestroy {

  public user: User;
  public menuItems: MenuItem[];
  public linkItems: MenuItem[];

  @Input() backgroundColor: string;
  @Input() displayLogin: boolean = true;

  private destroy$ = new Subject();

  constructor(private router: Router, private userService: UserService) {
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    this.menuItems = [...MENU];
    this.linkItems = [...LINKS];

    this.userService.currentUser$
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => this.user = user);
  }
}
