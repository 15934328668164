import { Card, CardColor } from './game/card.model';
import { TromfCombination } from './game/game.model';
import { TeamNumber } from './game/score.model';
import { Position } from './generic.model';

export class AppPlayerDto {
    public id: string;
    public username: string;
    public connectionId: string;
    public teamId: string;
    public isDealer: boolean;
    public isPlaying: boolean;
    public tablePosition: Position;
    public tromf: CardColor;
    public cards: Card[];
    public wonCards: Card[];
    public userKey: string;
    public avatarUrl: string;

    public constructor() {
    }
}

export class AppPlayerConverter {

    public static toModel(playerDto: AppPlayerDto): AppPlayer {
        const player: AppPlayer = new AppPlayer();
        player.id = playerDto.id;
        player.username = playerDto.username;
        player.connectionId = playerDto.connectionId;
        player.teamId = playerDto.teamId;
        player.isDealer = playerDto.isDealer;
        player.isPlaying = playerDto.isPlaying;
        player.tablePosition = playerDto.tablePosition;
        player.tromf = playerDto.tromf;
        player.userKey = playerDto.userKey;
        player.avatarUrl = playerDto.avatarUrl;
        return player;
    }

    public static toModelArray(playerDtos: AppPlayerDto[]): AppPlayer[] {
        const playerArray: AppPlayer[] = [];
        for (let i: number = 0; i < playerDtos.length; i++) {
            playerArray.push(AppPlayerConverter.toModel(playerDtos[i]));
        }
        return playerArray;
    }

    public static toDto(player: AppPlayer): AppPlayerDto {
        return undefined;
    }

    public static toDtoArray(players: AppPlayer[]): AppPlayerDto[] {
        return undefined;
    }
}

export class AppPlayer {
    public teamId: string;
    public isDealer: boolean;
    public isPlaying: boolean;
    public isPlayingFris: boolean = false;
    public tablePosition: Position;
    public cards: Card[] = [];
    public wonCards: Card[] = [];
    public id: string;
    public tromf: CardColor;
    public connectionId: string;
    public username: string;
    public avatarUrl: string;
    public userKey: string;
    public tromfCombination: TromfCombination;

    public constructor() {

    }

    public getAvatarUrl(): string {
        return (!this.avatarUrl || this.avatarUrl === '') ? 'assets/images/img_avatar.png' : this.avatarUrl;
    }

    public get teamNumber(): TeamNumber {
        return this.tablePosition % 2 === 1 ? TeamNumber.Team1 : TeamNumber.Team2;
    }

    public get teamNumberText(): string {
        return this.tablePosition % 2 === 1 ? 'Echipa 1' : 'Echipa 2';
    }
}
