import { NgModule } from '@angular/core';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { AuthenticationLayoutComponent } from './layout/authentication-layout/authentication-layout.component';
import { RegisterComponent } from "./pages/register/register.component";
import { ForgotPasswordComponent } from "./pages/forgot-password/forgot-password.component";
import { ResetPasswordComponent } from "./pages/reset-password/reset-password.component";
import { SharedModule } from '../shared/shared.module';
import { ConfirmAccountComponent } from './pages/confirm-account/confirm-account.component';
import { ConfirmPasswordComponent } from './pages/confirm-password/confirm-password.component';
import { AuthConfirmationComponent } from './components/auth-confirmation/auth-confirmation.component';
import { AccountConfirmingComponent } from './pages/account-confirming/account-confirming.component';
import { LoginAnchorComponent } from './components/login-anchor/login-anchor.component';
import { LoginComponent } from './pages/login/login.component';
import { ResendConfirmationComponent } from './pages/resend-confirmation/resend-confirmation.component';

@NgModule({
  declarations: [
    AuthenticationLayoutComponent,
    LoginComponent,
    RegisterComponent,
    ForgotPasswordComponent,
    ResetPasswordComponent,
    ConfirmAccountComponent,
    ConfirmPasswordComponent,
    AuthConfirmationComponent,
    AccountConfirmingComponent,
    LoginAnchorComponent,
    ResendConfirmationComponent,
  ],
  imports: [
    AuthenticationRoutingModule,
    SharedModule
  ],
})
export class AuthenticationModule { }
