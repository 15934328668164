<ng-container *ngIf="!(isClosing$ | async)">
  <h1 mat-dialog-title>{{this.data.playerName}},</h1>
  <div mat-dialog-content>
    <p *ngIf="this.data.tromfColors.length==0; else frisColors">
      Nu ai nici o combinatie cu care sa poti juca fris.
    </p>
    <ng-template #frisColors>
      <p [innerHTML]="getDialogDescription()"></p>
      <div class="container-img">
        <div *ngFor="let color of this.data.tromfColors" class="image-wrapper" (click)="playsFris(color)">
          <span class="image-wrapper-helper"></span>
          <img src="{{getCardColorUrl(color)}}" alt="{{getCardColorName(color)}}" class="card-color-icon" />
          <div class="focus-overlay-helper"></div>
          <div class="focus-overlay"></div>
        </div>
      </div>
    </ng-template>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="{playsFris: false, tromfColor: undefined}" cdkFocusInitial>Pas</button>
  </div>
</ng-container>