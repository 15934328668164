import { CommonModule } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { ToastrModule } from "ngx-toastr";
import { HttpErrorInterceptor } from "./http-error.interceptor";

@NgModule({
  declarations: [
  ],
  imports: [
    CommonModule,
    ToastrModule.forRoot()
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,  
      multi: true, // multiple interceptors are possible
    }
  ],
})
export class ErrorHandlerModule { }