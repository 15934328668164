import { Component, OnInit } from '@angular/core';
import packageInfo from '../../../../../package.json';

declare var bootstrap: any;

@Component({
  selector: 'app-footer-layout',
  templateUrl: './footer-layout.component.html',
  styleUrls: ['./footer-layout.component.scss']
})
export class FooterLayoutComponent implements OnInit {

  protected version: string = packageInfo.version;
  protected currentYear: string = (new Date()).getFullYear().toString();

  constructor() { }

  ngOnInit(): void {
    var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'))
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new bootstrap.Tooltip(tooltipTriggerEl)
    });
  }

}
