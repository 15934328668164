import { Component, OnInit, OnDestroy, Inject } from "@angular/core";
import { ProfileService } from "../core/services/profile.service";
import { HttpErrorResponse } from '@angular/common/http';
import { Subscription } from 'rxjs';
import { LogService } from '../core/services/log.service';
import { AuthService } from "../core/services/auth.service";
import { UserService } from "src/core/services/user.service";
import { DOCUMENT } from "@angular/common";
import { MatDialog } from "@angular/material/dialog";
import { NavigationEnd, Router } from "@angular/router";
import { RouteExtService } from "src/core/services/route-ext.service";
import { OnlineService } from "src/core/services/online.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent implements OnInit, OnDestroy {

  private subscriptions: Subscription;
  private readonly protectedPaths: string[] = ["admin", "profile"];

  title = "filcau-web-app";
  favicons: string[] = [
    'Verde.ico',
    'Rosu.ico',
    'Daba.ico',
    'Ghinda.ico'
  ];

  constructor(
    private profileService: ProfileService,
    private userService: UserService,
    private logService: LogService,
    private authService: AuthService,
    private dialog: MatDialog,
    private router: Router,
    private onlineService: OnlineService,
    private readonly routeService: RouteExtService,
    @Inject(DOCUMENT) private document: Document
  ) { }

  ngOnInit() {
    this.randomFavicon();
    this.subscriptions = new Subscription();
    //we want to have a first call to the profile page to deal with the case when user remains in local storage but the token expires
    if (this.userService.currentUser && !this.userService.isUserInitialized) {
      this.subscriptions.add(this.profileService.me(!this.isProtectedPath()).subscribe(() => {
        this.userService.markUserInitialized();
      }, error => {
        if (error instanceof HttpErrorResponse) {
          let httpError: HttpErrorResponse = error as HttpErrorResponse;
          if ((httpError.status == 401 || httpError.status == 403) && this.userService.currentUser) {
            this.logService.log("The token for the current user probably expired, logging out!");
            this.authService.logout();
          } else {
            this.logService.error(httpError);
          }
        } else {
          this.logService.error(error);
        }
      }));
    }

    this.subscriptions.add(
      this.router.events.subscribe( (event) =>{
        if(event instanceof NavigationEnd){
          if(this.routeService.getPreviousUrl().includes('/joc')){
            this.dialog.closeAll();
          }
          this.onlineService.updateOnline().toPromise();
        }
      })
    );
  }

  private isProtectedPath(): boolean {
    return this.protectedPaths.filter(value =>
      window.location.href.includes(value)).length > 0;
  }

  randomFavicon(): void {
    this.document.getElementById('appFavicon').setAttribute('href', `assets/favicons/${this.favicons[Math.floor(Math.random() * 4)]}`);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
