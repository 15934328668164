import { Injectable } from '@angular/core';
import { BaseService } from './base.service';
import { HttpClient } from '@angular/common/http';
import { ContactMessageModel } from '../../core/models/contact-message.model';
import { LogService } from './log.service';

@Injectable({
  providedIn: 'root'
})
export class ContactService extends BaseService {

  constructor(http: HttpClient, logService: LogService) {
    super(http, logService);
  }

  public sendMessage(contactMessage: ContactMessageModel) {
    return this.http.post(
      this.apiUrl + 'api/contact/sendEmail',
      JSON.stringify(contactMessage),
      { headers: this.getCommonUnauthorizedHeaders() }
    );
  }
}