<form class="bg-white auth-form" [formGroup]="forgotPasswordForm" (submit)="onRecoverPassword()" novalidate>
  <h1 class="login-title">Recuperare parolă</h1>

  <div class="form-group login-controls">
    <input class="form-control login-input" type="email" placeholder="Email"  formControlName="email" required/>
    <div *ngIf="forgotPasswordForm.get('email').invalid && (forgotPasswordForm.get('email').dirty || forgotPasswordForm.get('email').touched)" >
      <div *ngIf="forgotPasswordForm.get('email').errors.required" class="error">Email-ul este obligatoriu</div>
      <div *ngIf="forgotPasswordForm.get('email').errors.email" class="error">Email-ul nu este valid</div>
    </div>
  </div>
  
  <div class="login-controls" *ngIf="errorDescription" >
    <p class="server-error" >{{ errorDescription }}</p>
  </div >

  <button class="login-button" type="submit" [disabled]="isSubmitted">
    <span *ngIf="!isSubmitted">
      Recuperează-ți parola
    </span>
    <span *ngIf="isSubmitted">
      <i class="fas fa-spinner fa-pulse"></i>
    </span>
  </button>
  
  <app-login-anchor [disabled]='isSubmitted'></app-login-anchor>
</form>