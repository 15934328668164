import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';

export interface BateTaieDialogData {
  playerName: string;
  dealerName: string;
}

@Component({
  selector: 'app-bate-taie-dialog',
  templateUrl: './bate-taie-dialog.component.html',
  styleUrls: ['./bate-taie-dialog.component.scss']
})
export class BateTaieDialogComponent implements OnInit {

  public isClosing$: Observable<boolean>;

  constructor(
    public dialogRef: MatDialogRef<BateTaieDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: BateTaieDialogData
  ) {
    this.isClosing$ = this.dialogRef.beforeClosed().pipe(mapTo(true));
    this.dialogRef.disableClose = true;
  }

  ngOnInit() {
  }

}