import { Injectable } from "@angular/core";
import {
    HttpClient,
} from "@angular/common/http";
import { LogService } from './log.service';
import { BaseService } from "./base.service";
import { UserDetails } from "../models/user-details.model";

@Injectable({
    providedIn: 'root'
})
export class AdminService extends BaseService {

    constructor(http: HttpClient, logService: LogService) {
        super(http, logService);
    }

    /**
     * Get all players that are online 
     */
    public getOnlineUsers() {
        return this.http.get<Array<string>>(
            this.apiUrl + `api/online/getOnline`,
            { headers: this.getCommonHeaders(), observe:"response" }
        )
    }

    /**
     * Get all players that are registerd within the app 
     * @param skip start index
     * @param take number of items to be returned
     */
    public getUsers(skip: number = 0, take: number = 10) {
        return this.http.get<Array<UserDetails>>(
            this.apiUrl + `api/admin/getUsers?skip=${skip}&take=${take}`,
            { headers: this.getCommonHeaders(), observe:"response" }
        )
    }


    /**
     * Block a user form the system. Basically a SOFT delete 
     * @param userId The id of the user to be blocked
     * @returns Standard 200 status code
     */
    public blockUser(userId: string) {
        return this.http.post(
            this.apiUrl + "api/admin/blockUser",
            JSON.stringify({
                userId
            }),
            { headers: this.getCommonHeaders() }
        )
    }

    /**
     * Reactivate a user after a block operation.
     * @param userId The id of the user to be unblocked
     * @returns Standard 200 status code
     */
    public unblockUser(userId: string) {
        return this.http.post(
            this.apiUrl + "api/admin/unblockUser",
            JSON.stringify({
                userId
            }),
            { headers: this.getCommonHeaders() }
        )
    }

    /**
     * HARD delete a user form the system.
     * For a SOFT delete use blockUser method
     * @param userId The id of the user to be deleted
     * @returns Standard 200 status code
     */
    public deleteUser(userId: string) {
        return this.http.delete(
            this.apiUrl + `api/admin/deleteUser/${userId}`,
            { headers: this.getCommonHeaders() }
        )
    }

    /**
     * Activate a user account by setting email confirmed to TRUE
     * @param userId The id of the user to be confirmed
     * @returns Standard 200 status code
     */
    public confirmUser(userId: string) {
        return this.http.post(
            this.apiUrl + "api/admin/confirmUser",
            JSON.stringify({
                userId
            }),
            { headers: this.getCommonHeaders() }
        )
    }
}
