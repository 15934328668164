import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { PERMISSIONS } from 'src/app/common/constants';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionGuard implements CanActivate {

  constructor(private userService: UserService, private router: Router) { }

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const permission = PERMISSIONS.find(permission => permission.page === route.routeConfig.component.name)

    if (permission) {
      const hasAccess = permission.access.some(role => this.userService.userRoles?.indexOf(role) > -1);

      if (!hasAccess) {
        this.router.navigate(["/"], { queryParams: { returnUrl: state.url } });
      }
    }

    return true
  }
}
