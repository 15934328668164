import { AfterViewChecked, ChangeDetectorRef, Component, ViewEncapsulation } from '@angular/core';
import { PAGES } from 'src/app/common/constants';
import { FilcauLayout } from 'src/core/abstracts/filcau.component';
import { GameTypeComponent } from '../../components/game-type/game-type.component';
import { MatDialog } from '@angular/material/dialog';
import { GamePlayersComponent } from '../../components/game-players/game-players.component';
import { Router } from '@angular/router';
import { UserService } from 'src/core/services/user.service';
import { GameType } from 'src/core/models/game/private-game-request.model';
import { LogService } from 'src/core/services/log.service';

@Component({
  selector: 'app-main-layout',
  templateUrl: './main-layout.component.html',
  styleUrls: ['./main-layout.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MainLayoutComponent extends FilcauLayout implements AfterViewChecked {

  public isTopbarVisible: boolean;

  constructor(
    private readonly cdf: ChangeDetectorRef,
    private readonly matDialog: MatDialog,
    private readonly router: Router,
    private readonly userService: UserService,
    protected readonly logService: LogService
  ) {
    super(logService);
  }

  ngAfterViewChecked(): void {
    this.isTopbarVisible = this.activePage != PAGES.LandingPage;
    this.cdf.detectChanges();
  }

  public onPlay(): void {
    if (!this.userService.currentUser) {
      this.toPublic();
      return;
    }
    const dialogRef = this.matDialog.open(
      GameTypeComponent,
      {
        width: '400px'
      }
    );
    dialogRef.afterClosed().subscribe((res: GameType) => {
      if (res === GameType.Public) {
        this.toPublic();
      } else if (res === GameType.Private) {
        this.matDialog.open(
          GamePlayersComponent,
          {
            width: '400px'
          }
        );
      }
    });
  }

  toPublic(): void {
    this.router.navigate(['joc']);
  }
}
