<div class="container p-20">
  <div class="card shadow-sm p-3 mb-5 bg-body rounded">

    <h5 class="card-title">Despre filcau</h5>
    <h6 class="card-subtitle mb-2 text-muted">Ai sugestii sau probleme legate de site? Nu ezita sa ne contactezi!</h6>

    <div class="card-body">

      <form id="contact-form" class="m-5" [formGroup]="contactForm" (ngSubmit)="sendMessage()">

        <div class="row d-md-flex justify-content-md-center">
          <div class="col-sm-12 col-md-6 form-group">
            <label for="email" class="form-label fl-lable-text">Email</label>
            <input id="email" class="form-control profile-input" formControlName="email" type="email" />
            <div *ngIf="email.invalid  && (email.dirty || email.touched)" class="fl-primary-error">
              <div *ngIf="email.errors.required">Emailul este obligatoriu</div>
              <div *ngIf="email.errors.email">Emailul nu este valid</div>
            </div>
          </div>
        </div>

        <div class="row d-md-flex justify-content-md-center">
          <div class="col-sm-12 col-md-6 form-group">
            <label for="name" class="form-label fl-lable-text">Nume</label>
            <input id="name" class="form-control profile-input" formControlName="name" />
            <div *ngIf="name.invalid && (name.dirty || name.touched)" class="fl-primary-error">
              <div *ngIf="name.errors.required">Numele este obligatoriu</div>
            </div>
          </div>
        </div>


        <div class="row d-md-flex justify-content-md-center">
          <div class="col-sm-12 col-md-6 form-group">
            <label for="subject" class="form-label fl-lable-text">Subiect</label>
            <input id="subject" class="form-control profile-input" formControlName="subject" />
            <div *ngIf="subject.invalid && (subject.dirty || subject.touched)" class="fl-primary-error">
              <div *ngIf="subject.errors.required">Subiectul este obligatoriu</div>
            </div>
          </div>
        </div>

        <div class="row d-md-flex justify-content-md-center">
          <div class="col-sm-12 col-md-6 form-group">
            <label for="message" class="form-label fl-lable-text">Mesaj</label>
            <textarea id="message" class="form-control profile-input" formControlName="message" rows="10"></textarea>
            <div *ngIf="message.invalid  && (message.dirty || message.touched)" class="fl-primary-error">
              <div *ngIf="message.errors.required">Mesajul este obigatoriu</div>
            </div>
          </div>
        </div>

        <div class="d-flex flex-row justify-content-center">
          <div class="text-center">
            <ul class="list-unstyled mb-0">
              <li><i class="fas fa-envelope mt-4 fa-2x" style="color: dimgray;"></i>
                <p><a href="mailto:contact@filcau.ro">contact@filcau.ro</a></p>
              </li>
            </ul>
          </div>
        </div>

      </form>

    </div>

    <div class="card-footer fl-card-footer d-flex justify-content-end">
      <input type="submit" class="btn fl-primary-button" value="Trimite" form="contact-form" />
    </div>
  </div>
</div>