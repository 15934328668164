export class PrivateGameRequest {
    public player2: string;
    public player3: string;
    public player4: string;

    constructor(player2?: string,
        player3?: string,
        player4?: string) {
        this.player2 = player2;
        this.player3 = player3;
        this.player4 = player4;
    }

    toJSON(): any {
        const obj = {};
        obj['PlayerList']={};
        obj['PlayerList'][PlayerGamePosition.Team1Player2] = this.player2;
        obj['PlayerList'][PlayerGamePosition.Team2Player1] = this.player3;
        obj['PlayerList'][PlayerGamePosition.Team2Player2] = this.player4;
        return obj;
    }
}

export enum GameType {
    Private,
    Public
}

export enum PlayerGamePosition {
    Team1Player1,
    Team1Player2,
    Team2Player1,
    Team2Player2
}