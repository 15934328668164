<ng-container *ngIf="!(isClosing$ | async)">
  <h1 mat-dialog-title>{{title}} {{data.player.username}},</h1>
  <div mat-dialog-content>
    <p>
      Scorul jocului este:<br />
      Echipa 1 ({{data.team1.player1.username}} + {{data.team1.player2.username}}): {{data.gameScore.team1Score}} - {{data.gameScore.team2Score}} :Echipa 2 ({{data.team2.player1.username}} + {{data.team2.player2.username}})<br />
      {{description}}
    </p>
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="this.dialogRef.close()" cdkFocusInitial>Continua</button>
  </div>
</ng-container>