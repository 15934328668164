<ng-container *ngIf="!(isClosing$ | async)">
  <h1 mat-dialog-title>{{data.title}}</h1>
  <div mat-dialog-content>
    <p>
      {{data.description}}
    </p>
  </div>
  <div mat-dialog-actions align="end">
    <button mat-button [mat-dialog-close]="true" *ngIf="data.yesString">{{data.yesString}}</button>
    <button mat-button [mat-dialog-close]="false" *ngIf="data.noString" cdkFocusInitial>{{data.noString}}</button>
  </div>
</ng-container>