import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-auth-confirmation',
  templateUrl: './auth-confirmation.component.html',
  styleUrls: ['./auth-confirmation.component.scss',
  ]
})
export class AuthConfirmationComponent implements OnInit {

  @Input() text: string;
  @Input() goToLabel: string = "Login";
  @Input() goToRouterLink: string = "/login";
  @Input() success: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}
