import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { ImageCropperComponent, CropperSettings } from "ngx-img-cropper";
import { CropperResult } from "../../../../core/models/cropper-result.model";
import { ProfileService } from '../../../../core/services/profile.service';
import { User } from '../../../../core/models/user.model';
import { LogService } from '../../../../core/services/log.service';
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { UserService } from "src/core/services/user.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { PAGES } from "src/app/common/constants";

@Component({
  selector: "profile-page",
  templateUrl: "./profile-page.component.html",
  styleUrls: ["./profile-page.component.scss"]
})
export class ProfilePageComponent implements OnInit, OnDestroy {
  public pageName: PAGES = PAGES.ProfilePage;
  public user: User;
  public userProfile: FormGroup;

  private destroy$ = new Subject();

  constructor(private userService: UserService,
    private profileService: ProfileService,
    private logService: LogService,
    private route: ActivatedRoute) { }


  ngOnInit() {
    this.userService.currentUser$
      .pipe(takeUntil(this.destroy$))
      .subscribe(user => {
        this.user = user;
        
        if(user){
          this.setUserForm();
        }
      })
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private setUserForm() {
    this.userProfile = new FormGroup({
      firstname: new FormControl(this.user.name, [Validators.required, Validators.minLength(2), Validators.maxLength(50)]),
      lastname: new FormControl(this.user.surname, [Validators.required, Validators.minLength(2), Validators.maxLength(50)]),
      username: new FormControl(this.user.username, [Validators.required, Validators.maxLength(50)]),
      email: new FormControl(this.user.email, [Validators.required, Validators.email])
    });
  }

  public uploadImage(args: CropperResult): void {
    this.profileService.updateProfileImage(args.base64).subscribe(
      (newImage) => {
        this.user.avatarUrl = newImage;
      },
      error => {
        this.logService.error(error);
      }
    );
  }

  public onSubmited() {
    if (this.userProfile.valid) {

      this.user.name = this.firstname.value;
      this.user.surname = this.lastname.value;
      this.user.email = this.email.value;
      this.user.username = this.username.value;

      this.profileService.updateProfile(this.user).subscribe(
        (res) => {
          this.userService.currentUser = this.user;
          this.logService.log(res);
        },
        (error) => {
          this.logService.error(error);
        }
      )
    }
  }


  public get firstname() {
    return this.userProfile.get("firstname");
  }

  public get lastname() {
    return this.userProfile.get("lastname");
  }

  public get username() {
    return this.userProfile.get("username");
  }

  public get email() {
    return this.userProfile.get("email");
  }
}