import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";

export function MustMatch(controlName: string, matchingControlName: string) {
  return (formGroup: FormGroup) => {
    const control = formGroup.controls[controlName];
    const matchingControl = formGroup.controls[matchingControlName];

    if (!control || !matchingControl) {
      return null;
    }

    if (matchingControl.errors && !matchingControl.errors.mustMatch) {
      return null;
    }

    if (control.value !== matchingControl.value) {
      matchingControl.setErrors({ mustMatch: true });
    } else {
      matchingControl.setErrors(null);
    }
  };
}

export function NotMatchControls(...controlNames: string[]): ValidatorFn {
  return (formGroup: FormGroup): ValidationErrors | null => {
    const controls = controlNames
      .map(name => formGroup.get(name));
    for (let i = 0; i < controls.length; i++) {
      RemoveMatchError(controls[i]);
    }
    let errorFound: boolean = false;
    for (let i = 0; i < controls.length - 1; i++) {
      for (let j = i + 1; j < controls.length; j++) {
        if (controls[i].value === controls[j].value) {
          controls[i].setErrors({ matchControls: true, ...controls[i].errors });
          controls[j].setErrors({ matchControls: true, ...controls[j].errors });
          errorFound = true;
        }
      }
    }
    return errorFound ? { matchControls: true } : null;
  };
}

function RemoveMatchError(control: AbstractControl) {
  if (!control.errors) {
    return;
  }
  const errorKeys = Object.keys(control.errors);
  if (errorKeys.length === 0) {
    return;
  }
  for (let i = 0; i < errorKeys.length; i++) {
    if (errorKeys[i] === 'matchControls') {
      delete control.errors[errorKeys[i]];
      if(Object.keys(control.errors).length === 0){
        control.setErrors(null);
      }
    }
  }
}
