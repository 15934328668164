import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { GameService } from '../../core/services/game.service';
import { PlayerSeatComponent } from './player-seat/player-seat.component';
import { SeatComponent } from './seat/seat.component';
import { PlayerCardComponent } from './player-card/player-card.component';
import { OpponentCardComponent } from './opponent-card/opponent-card.component';
import { OpponentSeatNorthComponent } from './opponent-seat-north/opponent-seat-north.component';
import { OpponentSeatWestComponent } from './opponent-seat-west/opponent-seat-west.component';
import { OpponentSeatEastComponent } from './opponent-seat-east/opponent-seat-east.component';
import { OpponentCardNorthComponent } from './opponent-card-north/opponent-card-north.component';
import { OpponentCardEastComponent } from './opponent-card-east/opponent-card-east.component';
import { OpponentCardWestComponent } from './opponent-card-west/opponent-card-west.component';
import { BateTaieDialogComponent } from './dialogs/bate-taie-dialog/bate-taie-dialog.component';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDialogModule } from '@angular/material/dialog';
import { FrisDialogComponent } from './dialogs/fris-dialog/fris-dialog.component';
import { GeneralDialogComponent } from './dialogs/general-dialog/general-dialog.component';
import { CardTableComponent } from './card-table/card-table.component';
import { ConfirmDialogComponent } from './dialogs/confirm-dialog/confirm-dialog.component';
import { GameTimerComponent } from './game-timer/game-timer.component';
import { RouterModule, Routes } from '@angular/router';
import { MainGameComponent } from './main-game/main-game.component';
import { CanDeactivateGuard } from '../../core/guards/can-deactivate.guard';
import { GameEndDialogComponent } from './dialogs/game-end-dialog/game-end-dialog.component';
import { MatchEndedDialogComponent } from './dialogs/match-ended-dialog/match-ended-dialog.component';
import { StatusHeaderComponent } from './status-header/status-header.component';
import { CardImagePipe } from '../../core/pipes/card-image.pipe';
import { PlayerWonCardsComponent } from './player-won-cards/player-won-cards.component';
import { CardColorUrlPipe } from '../../core/pipes/card-color-url.pipe';
import { CardNamePipe } from '../../core/pipes/card-name.pipe';
import { DraggableLinkComponent } from './draggable-link/draggable-link.component';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { GameCountdownComponent } from './game-countdown/game-countdown.component';

const routes: Routes = [
  { path: "", component: MainGameComponent, canDeactivate: [CanDeactivateGuard] }
];

@NgModule({
  declarations: [
    PlayerSeatComponent,
    SeatComponent,
    PlayerCardComponent,
    OpponentCardComponent,
    OpponentSeatNorthComponent,
    OpponentSeatWestComponent,
    OpponentSeatEastComponent,
    OpponentCardNorthComponent,
    OpponentCardEastComponent,
    OpponentCardWestComponent,
    BateTaieDialogComponent,
    FrisDialogComponent,
    GeneralDialogComponent,
    CardTableComponent,
    ConfirmDialogComponent,
    GameTimerComponent,
    GameEndDialogComponent,
    MatchEndedDialogComponent,
    StatusHeaderComponent,
    CardImagePipe,
    CardColorUrlPipe,
    CardNamePipe,
    PlayerWonCardsComponent,
    DraggableLinkComponent,
    GameCountdownComponent
  ],
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    MatNativeDateModule,
    MatButtonModule,
    MatCheckboxModule,
    MatDialogModule,
    DragDropModule
  ],
  providers: [
    GameService,
    DecimalPipe,
    CardImagePipe,
    CardColorUrlPipe,
    CardNamePipe
  ],
  exports: [
    PlayerSeatComponent,
    SeatComponent,
    PlayerCardComponent,
    OpponentCardComponent,
    OpponentSeatNorthComponent,
    OpponentSeatWestComponent,
    OpponentSeatEastComponent,
    BateTaieDialogComponent,
    CardTableComponent,
    GameTimerComponent,
    StatusHeaderComponent,
    CardImagePipe,
    CardColorUrlPipe,
    CardNamePipe,
    DraggableLinkComponent
  ]
})
export class GameModule { }
