import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MainRoutingModule } from './main-routing.module';
import { MainLayoutComponent } from './layout/main-layout/main-layout.component';
import { ContactPageComponent } from './pages/contact-page/contact-page.component';
import { AboutPageComponent } from './pages/about-page/about-page.component';
import { NotFoundPageComponent } from './pages/not-found-page/not-found-page.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { ProfilePageComponent } from './pages/profile-page/profile-page.component';
import { SharedModule } from '../shared/shared.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatRippleModule } from '@angular/material/core';
import { LightboxModule } from 'ngx-lightbox';
import { GameTypeComponent } from './components/game-type/game-type.component';
import { GamePlayersComponent } from './components/game-players/game-players.component';
import { AdminPageComponent } from './pages/admin-page/admin-page.component';
import { MatTableModule } from '@angular/material/table'
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatButtonModule } from '@angular/material/button';
import { AdminActionsComponent } from './components/admin-actions/admin-actions.component';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatListModule } from '@angular/material/list';

const FilcauPages = [
  MainLayoutComponent,
  ContactPageComponent,
  AboutPageComponent,
  NotFoundPageComponent,
  LandingPageComponent,
  ProfilePageComponent,
  GameTypeComponent,
  GamePlayersComponent,
  AdminPageComponent,
  AdminActionsComponent
]

@NgModule({
  declarations: [...FilcauPages],
  imports: [
    CommonModule,
    MainRoutingModule,
    FlexLayoutModule,
    MatRippleModule,
    LightboxModule,
    MatTableModule,
    MatPaginatorModule,
    MatButtonModule,
    MatBottomSheetModule,
    MatListModule,
    SharedModule
  ]
})
export class MainModule { }
