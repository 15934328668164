import { Pipe, PipeTransform } from '@angular/core';
import { CardColor, CardColorUtils } from '../models/game/card.model';

@Pipe({
  name: 'cardColorUrl',
  pure: true
})
export class CardColorUrlPipe implements PipeTransform {

  transform(colorName: CardColor): string {
    return CardColorUtils.getCardColorUrl(colorName);
  }

}
