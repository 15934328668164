<form class="bg-white auth-form" [formGroup]="resendConfirmationMail" (submit)="onResendConfirmation()" novalidate>
    <h1 class="login-title">Retrimitere email confirmare</h1>
  
    <div class="form-group login-controls">
      <input class="form-control login-input" type="email" placeholder="Email"  formControlName="email" required/>
      <div *ngIf="resendConfirmationMail.get('email').invalid && (resendConfirmationMail.get('email').dirty || resendConfirmationMail.get('email').touched)" >
        <div *ngIf="resendConfirmationMail.get('email').errors.required" class="error">Email-ul este obligatoriu</div>
        <div *ngIf="resendConfirmationMail.get('email').errors.email" class="error">Email-ul nu este valid</div>
      </div>
    </div>
    
    <div class="login-controls" *ngIf="errorDescription" >
      <p class="server-error" >{{ errorDescription }}</p>
    </div >
  
    <button class="login-button" type="submit" [disabled]="isSubmitted">
      <span *ngIf="!isSubmitted">
        Retrimite email de confirmare
      </span>
      <span *ngIf="isSubmitted">
        <i class="fas fa-spinner fa-pulse"></i>
      </span>
    </button>
    
    <app-login-anchor [disabled]='isSubmitted'></app-login-anchor>
  </form>