import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';

export interface GeneralDialogData {
  title: string;
  description: string;
  okString: string;
}

@Component({
  selector: 'app-general-dialog',
  templateUrl: './general-dialog.component.html',
  styleUrls: ['./general-dialog.component.scss']
})
export class GeneralDialogComponent {

  public isClosing$: Observable<boolean>;  

  constructor(public dialogRef: MatDialogRef<GeneralDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: GeneralDialogData) {
    this.isClosing$ = this.dialogRef.beforeClosed().pipe(mapTo(true));
    this.dialogRef.disableClose = true;
  }
}
