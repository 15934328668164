import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PAGES } from 'src/app/common/constants';
import { GameType } from 'src/core/models/game/private-game-request.model';
import { UserService } from 'src/core/services/user.service';
import { AuthService } from '../../../../core/services/auth.service';
import { GamePlayersComponent } from '../../components/game-players/game-players.component';
import { GameTypeComponent } from '../../components/game-type/game-type.component';

@Component({
  selector: 'landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {
  public pageName: PAGES = PAGES.LandingPage;

  constructor(private authService: AuthService,
    private readonly matDialog: MatDialog,
    private readonly router: Router,
    private readonly userService: UserService
  ) {
  }

  ngOnInit() {
  }

  onLogout() {
    this.authService.logout();
  }

  public onPlay(): void {
    if (!this.userService.currentUser) {
      this.toPublic();
      return;
    }
    const dialogRef = this.matDialog.open(
      GameTypeComponent,
      {
        width: '400px'
      }
    );
    dialogRef.afterClosed().subscribe((res: GameType) => {
      if (res === GameType.Public) {
        this.toPublic();
      } else if (res === GameType.Private) {
        this.matDialog.open(
          GamePlayersComponent,
          {
            width: '400px'
          }
        );
      }
    });
  }

  private toPublic(): void {
    this.router.navigate(['joc']);
  }
}
