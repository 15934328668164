import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GameScore, ScoreType } from 'src/core/models/game/score.model';
import { AppPlayer } from 'src/core/models/app-player.model';
import { GameUtilsService } from 'src/core/services/game-utils.service';
import { Observable } from 'rxjs';
import { mapTo } from 'rxjs/operators';
import { Team } from 'src/core/models/team.model';

export interface GameEndDialogData {
  player: AppPlayer;
  gameScore: GameScore;
  team1: Team;
  team2: Team;
}

@Component({
  selector: 'app-game-end-dialog',
  templateUrl: './game-end-dialog.component.html',
  styleUrls: ['./game-end-dialog.component.scss']
})
export class GameEndDialogComponent {

  public isClosing$: Observable<boolean>;

  constructor(public dialogRef: MatDialogRef<GameEndDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: GameEndDialogData) {
    this.isClosing$ = this.dialogRef.beforeClosed().pipe(mapTo(true));
    this.dialogRef.disableClose = true;
    this.dialogRef.updateSize('60vmin', '280px');
  }

  public get title(): string {
    if (this.data.gameScore.selfScore === this.data.gameScore.opponentScore) {
      return 'Echipa ta a facut remiza';
    }
    if (this.data.gameScore.selfScore < 4) {
      return 'Echipa ta a pierdut jocul';
    }
    return 'Echipa ta a castigat jocul';
  }

  public get description(): string {
    let description: string = '';
    if ((this.data.gameScore.selfScoreType & ScoreType.PaRanduSatului) !== ScoreType.Invalid) {
      description = `Jocul s-a terminat ${GameUtilsService.scoreTypeNameDictionary.get(ScoreType.PaRanduSatului).toLowerCase()}`;
    }
    if ((this.data.gameScore.selfScoreType & ScoreType.HudaLegata) !== ScoreType.Invalid) {
      description += `Echipa ta a terminat jocul cu ${GameUtilsService.scoreTypeNameDictionary.get(ScoreType.HudaLegata).toLowerCase()}`;
    }
    if ((!description || description === '') && (this.data.gameScore.selfScoreType & ScoreType.Huda) !== ScoreType.Invalid) {
      description += `Echipa ta a terminat jocul cu ${GameUtilsService.scoreTypeNameDictionary.get(ScoreType.Huda).toLowerCase()}`;
    }
    if ((this.data.gameScore.selfScoreType & ScoreType.Sluga) !== ScoreType.Invalid) {
      description += description && description !== '' ? `, respectiv ${GameUtilsService.scoreTypeNameDictionary.get(ScoreType.Sluga).toLowerCase()}` : `Echipa ta a terminat jocul ca ${GameUtilsService.scoreTypeNameDictionary.get(ScoreType.Sluga).toLowerCase()}`;
    }
    return description;
  }
}
