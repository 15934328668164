import { Pipe, PipeTransform } from '@angular/core';
import { CardColor } from '../models/game/card.model';

@Pipe({
  name: 'cardName',
  pure: true
})
export class CardNamePipe implements PipeTransform {

  transform(color: CardColor): string {
    return CardColor[color];
  }

}
