import { Subject } from 'rxjs';

export class CardDto {
    public color: number;
    public name: number;

    public constructor(card: Card) {
        this.color = card.color;
        this.name = card.name;
    }
}

export class CardConverter {

    public static toModel(cardDto: CardDto): Card {
        return new Card(cardDto.color, cardDto.name);
    }

    public static toModelArray(cardDtos: CardDto[]): Card[] {
        const cardArray: Card[] = [];
        for (let i: number = 0; i < cardDtos.length; i++) {
            cardArray.push(CardConverter.toModel(cardDtos[i]));
        }
        return cardArray;
    }

    public static toDto(card: Card): CardDto {
        return new CardDto(card);
    }

    public static toDtoArray(cards: Card[]): CardDto[] {
        const cardDtoArray: CardDto[] = [];
        for (let i: number = 0; i < cards.length; i++) {
            cardDtoArray.push(CardConverter.toDto(cards[i]));
        }
        return cardDtoArray;
    }
}

export class CardSet {

    private cards: Card[] = [];

    public add(...cards: Card[]): void {
        cards.forEach(card => {
            if (card) {
                this.addCard(card);
            }
        });
    }

    private addCard(card: Card): void {
        let cardFound: boolean = false;
        for (let i = 0; i < this.cards.length; i++) {
            if (this.cards[i].equals(card)) {
                cardFound = true;
                break;
            }
        }
        if (!cardFound) {
            this.cards.push(card);
        }
    }

    public toArray(): Card[] {
        return this.cards.slice();
    }
}

export class Card {
    private static cover: string = 'cover';

    public color: CardColor;
    public name: CardName;
    public get value(): number {
        return this.name;
    }
    public imageUrl: string;
    public points: number;
    public absoluteValue(tromf: CardColor = CardColor.Hidden): number {
        if (tromf !== CardColor.Hidden && this.color === tromf && this.name !== CardName.Filcau) {
            if (this.name === CardName.Arseu) {
                return 6;
            }
            if (this.name === CardName.Cal) {
                return 7;
            }
            if (this.name === CardName.Craita) {
                return 8;
            }
            if (this.name === CardName.Tuz) {
                return 9;
            }
        } else {
            switch (this.name) {
                case (CardName.Arseu): {
                    return 1;
                }
                case (CardName.Cal): {
                    return 2;
                }
                case (CardName.Craita): {
                    return 3;
                }
                case (CardName.Tuz): {
                    return 4;
                }
                case (CardName.Filcau):
                    {
                        switch (this.color) {
                            case (CardColor.Daba): {
                                return 10;
                            }
                            case (CardColor.Rosu): {
                                return 11;
                            }
                            case (CardColor.Verde): {
                                return 12;
                            }
                            case (CardColor.Ghinda): {
                                return 13;
                            }
                            default: {
                                return -1;
                            }
                        }
                    }
                default: {
                    return -1;
                }
            };
        }
    }
    public isHidden: boolean;

    private hoverMode: boolean;
    private highlightState: boolean = false;
    private valid: boolean = true;
    private extension: string;

    public validityChanged: Subject<boolean> = new Subject<boolean>();
    public cardClicked: Subject<Card> = new Subject<Card>();
    public hoverModeChanged: Subject<boolean> = new Subject<boolean>();

    public static toString(cards: Card[]): string {
        let result: string = '';
        cards.forEach(card => {
            result += card.toString() + ' \n';
        });
        return result;
    }

    constructor(color: CardColor, name: CardName, isHidden: boolean = false) {
        if (isHidden) {
            this.color = CardColor.Hidden;
            this.name = CardName.Hidden;
        } else {
            this.color = color;
            this.name = name;
        }
        this.extension = '.jpg';

        this.isHidden = this.color === CardColor.Hidden && this.name === CardName.Hidden;
        this.imageUrl = (this.isHidden ? Card.cover : CardName[this.name] + '_' + CardColor[this.color]) + this.extension;
        this.points = this.calculatePoints();
    }

    public onCardClicked(): void {
        this.cardClicked.next(this);
    }

    public get isPoint(): boolean {
        return this.name === CardName.Tuz || this.name === CardName.Craita;
    }

    public setHoverState(state: boolean): void {
        this.hoverMode = state;
        this.hoverModeChanged.next(state);
    }

    public getHooverState(): boolean {
        return this.hoverMode;
    }

    public setValid(valid: boolean): void {
        if (this.valid !== valid) {
            this.setHighlighted(false);
        }
        this.valid = valid;
        this.setHoverState(valid);
        this.validityChanged.next(valid);
    }

    public isValid(): boolean {
        return this.valid;
    }

    public isHighlighted(): boolean {
        return this.highlightState;
    }

    public setHighlighted(highlightState: boolean): void {
        this.highlightState = highlightState;
    }

    private calculatePoints(): number {
        if (this.name === CardName.Tuz || this.name === CardName.Craita) {
            return 1;
        }
        return 0;
    }

    public isTromf(tromf: CardColor): boolean {
        return this.name === CardName.Filcau || this.color === tromf;
    }

    public isColor(color: CardColor): boolean {
        return this.color === color;
    }

    public equals(card: Card): boolean {
        if (!card) {
            return false;
        }
        return this.color === card.color && this.name === card.name;
    }

    public toString(): string {
        return CardName[this.name] + ' - ' + CardColor[this.color];
    }

}

export enum CardColor {
    Rosu,
    Verde,
    Daba,
    Ghinda,
    Hidden
}

export enum CardName {
    Filcau = 3,
    Tuz = 11,
    Craita = 10,
    Cal = 4,
    Arseu = 2,
    Hidden = 0
}

export class CardColorUtils {
    public static getCardColorUrl(color: CardColor): string {
        return `assets/card-symbols/${CardColor[color]}.svg`;
    }
}
