<div class="card-container">
  <img [@highlightTrigger]="{value: highlightAnimState, params: {zAngle: zAngle, defaultY: defaultYtranslation, highlightX: highlightX, highlightY: highlightY}}"
   [@validityTrigger]="{value: validityState}"
   (click)="cardClicked()"
   (mouseenter)="cardHovered(true)"
   (mouseleave)="cardHovered(false)"
   (touchstart)="startTouchEvent()"
   (touchend)="endTouchEvent(false)"
   (touchcancel)="endTouchEvent(true)"
   draggable="false" [style.z-index]=this.index [ngClass]="['card-common', getCardCssClass()]" src="{{ card.imageUrl | cardImage }}" />
</div>

