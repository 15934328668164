import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Card, CardColor, CardName } from 'src/core/models/game/card.model';
import { Position } from '../../../core/models/generic.model';
import { GameService } from 'src/core/services/game.service';

@Component({
  selector: 'app-player-won-cards',
  templateUrl: './player-won-cards.component.html',
  styleUrls: ['./player-won-cards.component.scss']
})
export class PlayerWonCardsComponent implements OnInit, OnDestroy {

  public cardStyles: string[] = [];
  public cardsWon: Card[] = [];
  public positionClass: string = '';

  private subscriptions: Subscription;
  private pos: Position;
  private timeout: NodeJS.Timeout;

  constructor(private gameService: GameService) { }

  ngOnInit(): void {
    this.subscriptions = new Subscription();

    this.subscriptions.add(this.gameService.roundWon.subscribe(data => this.roundWonHandler(data.position)));
    this.subscriptions.add(this.gameService.gameEnded.subscribe(data => this.gameEndedHandler()));
  }

  private roundWonHandler(winningPosition: Position): void {
    if (winningPosition === this.pos) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(function () {
        this.addSetOfCards();
      }.bind(this), 2900);
    }
  }

  private gameEndedHandler(): void {
    this.cardsWon = [];
    this.cardStyles = [];
  }

  private addSetOfCards(): void {
    for (let i = 0; i < 4; i++) {
      this.addCard(this.cardsWon.length + 1);
    }
  }

  private addCard(index: number): void {
    this.cardsWon.push(new Card(CardColor.Hidden, CardName.Hidden, true));
    this.cardStyles.push(this.getRandomCardStyle(index));
  }

  private getRandomCardStyle(index: number): string {
    return `left: 1.5vmin;
    z-index: ${index};
    transform: rotate(${(Math.floor(Math.random() * 31) - 15) + this.getCardAngleOffset()}deg) translate(${(Math.random() * 3) - 1.5}vmin, ${(Math.random() * 3) - 1.5}vmin);`;
  }

  private getCardAngleOffset(): number {
    let value: number = 0;
    if (this.pos === Position.North) {
      value = 90;
    }
    return value;
  }

  @Input()
  public set position(position: Position) {
    this.pos = position;
    switch (position) {
      case Position.East:
        this.positionClass = 'container-east';
        break;
      case Position.West:
        this.positionClass = 'container-west';
        break;
      case Position.North:
        this.positionClass = 'container-north';
        break;
      case Position.South:
        this.positionClass = 'container-south';
        break;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }

}
