import { Component, OnInit } from '@angular/core';
import { IAlbum, Lightbox, LightboxConfig } from 'ngx-lightbox';
import { PAGES } from 'src/app/common/constants';

@Component({
  selector: 'app-about-page',
  templateUrl: './about-page.component.html',
  styleUrls: ['./about-page.component.scss']
})
export class AboutPageComponent implements OnInit {
  public pageName: PAGES = PAGES.AboutPage;
  cardNames: IAlbum[] = [
    {
      src: '/assets/game-assets/Filcau_Verde.jpg',
      thumb: '/assets/game-assets/Filcau_Verde.jpg',
      caption: 'Filcău',
      downloadUrl: ''
    },
    {
      src: '/assets/game-assets/Tuz_Verde.jpg',
      thumb: '/assets/game-assets/Tuz_Verde.jpg',
      caption: 'Tuz',
      downloadUrl: ''
    },
    {
      src: '/assets/game-assets/Craita_Verde.jpg',
      thumb: '/assets/game-assets/Craita_Verde.jpg',
      caption: 'Crăiță',
      downloadUrl: ''
    },
    {
      src: '/assets/game-assets/Cal_Verde.jpg',
      thumb: '/assets/game-assets/Cal_Verde.jpg',
      caption: 'Cal',
      downloadUrl: ''
    },
    {
      src: '/assets/game-assets/Arseu_Verde.jpg',
      thumb: '/assets/game-assets/Arseu_Verde.jpg',
      caption: 'Arșeu',
      downloadUrl: ''
    }
  ];

  cardPositions: IAlbum[] = [
    {
      src: '/assets/game-assets/Arseu_Ghinda.jpg',
      thumb: '/assets/game-assets/Arseu_Ghinda.jpg',
      caption: 'Arșeu',
      downloadUrl: ''
    },
    {
      src: '/assets/game-assets/Cal_Ghinda.jpg',
      thumb: '/assets/game-assets/Cal_Ghinda.jpg',
      caption: 'Cal',
      downloadUrl: ''
    },
    {
      src: '/assets/game-assets/Filcau_Ghinda.jpg',
      thumb: '/assets/game-assets/Filcau_Ghinda.jpg',
      caption: 'Filcău',
      downloadUrl: ''
    },
    {
      src: '/assets/game-assets/Craita_Ghinda.jpg',
      thumb: '/assets/game-assets/Craita_Ghinda.jpg',
      caption: 'Crăiță',
      downloadUrl: ''
    },
    {
      src: '/assets/game-assets/Tuz_Ghinda.jpg',
      thumb: '/assets/game-assets/Tuz_Ghinda.jpg',
      caption: 'Tuz',
      downloadUrl: ''
    }
  ];

  cardSymbols: IAlbum[] = [
    {
      src: '/assets/card-symbols/Ghinda.svg',
      thumb: '/assets/card-symbols/Ghinda.svg',
      caption: 'Ghindă',
      downloadUrl: ''
    },
    {
      src: '/assets/card-symbols/Verde.svg',
      thumb: '/assets/card-symbols/Verde.svg',
      caption: 'Verde',
      downloadUrl: ''
    },
    {
      src: '/assets/card-symbols/Rosu.svg',
      thumb: '/assets/card-symbols/Rosu.svg',
      caption: 'Roșu',
      downloadUrl: ''
    },
    {
      src: '/assets/card-symbols/Daba.svg',
      thumb: '/assets/card-symbols/Daba.svg',
      caption: 'Dâbă',
      downloadUrl: ''
    }
  ];

  openedTabs: boolean[] = [
    true,
    true,
    false,
    false,
    false,
    false,
    false,
    false
  ];

  constructor(
    private lightboxConfig: LightboxConfig,
    private lightbox: Lightbox
  ) { }

  ngOnInit(): void {
    this.lightboxConfig.centerVertically = true;
  }

  openSingleImage(path: string, caption?: string): void {
    this.lightbox.open([{
      src: path,
      caption: caption,
      thumb: path,
      downloadUrl: ''
    }], 0, this.lightboxConfig);
  }

  openAlbumImage(album: IAlbum[], index: number): void {
    this.lightbox.open(album, index, this.lightboxConfig);
    if (album[index].src.endsWith('.svg')) {
      document.querySelector('body').classList.add('svg');
    } else {
      document.querySelector('body').classList.remove('svg');
    }
  }

  toggleTab(index: number): void {
    this.openedTabs[index] = !this.openedTabs[index];
  }
}
