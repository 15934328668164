import { Component } from '@angular/core';
import { SeatComponent, popupDialogAnimation } from '../seat/seat.component';
import { GameService } from 'src/core/services/game.service';
import { Position } from '../../../core/models/generic.model';
import { AnimationEvent } from "@angular/animations";

@Component({
  selector: 'app-opponent-seat-east',
  templateUrl: './opponent-seat-east.component.html',
  styleUrls: ['./opponent-seat-east.component.scss'],
  animations: [popupDialogAnimation()]
})
export class OpponentSeatEastComponent extends SeatComponent {

  constructor(public gameService: GameService) {
    super(gameService);
    this.position = Position.East;
  }

  ngOnInit() {
    super.ngOnInit();
  }

  public dialogAnimationStart(event: AnimationEvent) {
    super.dialogAnimationStart(event);
  }

  public dialogAnimationDone(event: AnimationEvent) {
    super.dialogAnimationDone(event);
  }

  public get waitingDisplay(): boolean {
    return super.waitingDisplay;
  }
}
